import axios from 'axios'
import C from "../../constants";

const getBetfair = (params) => {
  const request = axios.get(`${C.BASE_URL}/betfairOdds`, {params})
  return request.then(response => response.data)
}

const getPinnacle = (params) => {
  const request = axios.get(`${C.BASE_URL}/pinnacleOdds`, {params})
  return request.then(response => response.data)
}

const getVeikkaus = (params) => {
  const request = axios.get(`${C.BASE_URL}/veikkausOdds`, {params})
  return request.then(response => response.data)
}

export default { getVeikkaus, getPinnacle, getBetfair }