import {XYPlot, XAxis, YAxis, VerticalGridLines, HorizontalGridLines, RadialChart, LineSeries} from 'react-vis';
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import axios from "axios";
import * as Datetime from "react-datetime";
import C from "../../constants";
import '../../../node_modules/react-datetime/css/react-datetime.css';
import moment from 'moment';
import {Link} from "react-router-dom";

const ViewResults = props => {
    let start = new moment(new Date().setDate(new Date().getDate()-1));
    start = start.set({
        'hour' : 0,
        'minute'  : 0,
        'second' : 0
    });
    let end = moment();
    end = end.set({
        'hour' : 0,
        'minute'  : 0,
        'second' : 0
    });
    const [results, setResults] = useState([{ 'x': 0, 'y': 0}]);
    const [diagramResults, setDiagramResults] = useState([{angle: 0}]);
    const [resultBySport, setResultBySport] = useState([{}]);
    const [roi, setROI] = useState({ betSum: 0, winSum: 0, roi: 100});
    const [startDate, setStartDate] = useState(start);
    const [startDateChecked, setStartDateChecked] = useState(false);
    const [endDate, setEndDate] = useState(end);
    const [endDateChecked, setEndDateChecked] = useState(false);
    const [team, setTeam] = useState('');
    const [teamChecked, setTeamChecked] = useState(false);
    const [sport, setSport] = useState('');
    const [sportChecked, setSportChecked] = useState(false);
    const [series, setSeries] = useState('');
    const [seriesChecked, setSeriesChecked] = useState(false);
    const [gameFormat, setGameFormat] = useState('');
    const [gameFormatChecked, setGameFormatChecked] = useState(false);
    const [booker, setBooker] = useState('');
    const [bookerChecked, setBookerChecked] = useState(false);

    const resultBySportList = () => {
        if (resultBySport && resultBySport.length > 1)
        {
            return resultBySport.map(function(resultsBySport, i){
                return <Sport resultBySport={resultsBySport} key={i} />;
            })
        }
        return null;
    };

    const Sport = props => (
        <tr>
            <td>{props.resultBySport.sport}</td>
            <td>{props.resultBySport.count}</td>
            <td>{props.resultBySport.betSum.round(2)}</td>
            <td>{props.resultBySport.winSum.round(2)}</td>
            <td>{props.resultBySport.roi.round(2)} %</td>
        </tr>
    );

    useEffect(() => {
        let params = {
            startDate: '',
            endDate: '',
            team: '',
            sport: '',
            series: '',
            gameFormat: '',
            booker : '',
        };
        getResults(params);
    }, []);

    const handleGetResults = () => {
        let params = {
            startDate: '',
            endDate: '',
            team: '',
            sport: '',
            series: '',
            gameFormat: '',
            booker : '',
        };

        if (startDateChecked)
        {
            params.startDate = moment(startDate, C.DATE_FORMAT + C.TIME_FORMAT).toISOString();
        }

        if (endDateChecked)
        {
            params.endDate = moment(endDate, C.DATE_FORMAT + C.TIME_FORMAT).toISOString();
        }

        if (teamChecked)
        {
            params.team = team.label;
        }

        if (sportChecked)
        {
            params.sport = sport.label;
        }

        if (seriesChecked)
        {
            params.series = series.label;
        }

        if (gameFormatChecked)
        {
            params.gameFormat = gameFormat.label;
        }

        if (bookerChecked)
        {
            params.booker = booker.label;
        }

        getResults(params);
    };

    const getResults = (params) => {
        axios.get(C.BASE_URL + '/results', {params, 'headers': { 'x-access-token': props.token }})
            .then(response => {
                console.log('response.data.resultBySport', response.data.resultBySport);
                setResults(response.data.resultDetails);
                setDiagramResults(response.data.resultDiagramData);
                setResultBySport(response.data.resultBySport);
                setROI({ betSum: response.data.betSum, winSum: response.data.winSum, roi: response.data.roi });
            })
            .catch(function (error){
                console.log(error);
            })
    };

    const handleStartDateChange = (moment) => {
        setStartDate(moment);
    };

    const handleEndDateChange = (moment) => {
        setEndDate(moment);
    };

    Number.prototype.round = function(places) {
        return +(Math.round(this + "e+" + places)  + "e-" + places);
    };

    return (
        <React.Fragment>
            <button className="btn btn-primary" onClick={handleGetResults}>Hae</button>
            <br/>
            <input
                type="checkbox"
                onChange={(event) => setStartDateChecked(event.currentTarget.checked)}
                checked={startDateChecked}
            />
            <label>Start date</label>
            <Datetime
                dateFormat = {C.DATE_FORMAT}
                timeFormat = {C.TIME_FORMAT}
                closeOnSelect = {true}
                value={startDate}
                onChange={handleStartDateChange}
            />
            <input
                type="checkbox"
                onChange={(event) => setEndDateChecked(event.currentTarget.checked)}
                checked={endDateChecked}
            />
            <label>End date</label>
            <Datetime
                dateFormat = {C.DATE_FORMAT}
                timeFormat = {C.TIME_FORMAT}
                closeOnSelect = {true}
                value={endDate}
                onChange={handleEndDateChange}
            />
            <input
                type="checkbox"
                onChange={(event) => setTeamChecked(event.currentTarget.checked)}
                checked={teamChecked}
            />
            <label>Team</label>
            <Select
                isSearchable
                value={team}
                options={props.names.teams}
                onChange={(selectedOption) => setTeam(selectedOption)}
            />
            <input
                type="checkbox"
                onChange={(event) => setSportChecked(event.currentTarget.checked)}
                checked={sportChecked}
            />
            <label>Sport</label>
            <Select
                isSearchable
                value={sport}
                options={props.names.sports}
                onChange={(selectedOption) => setSport(selectedOption)}
            />
            <input
                type="checkbox"
                onChange={(event) => setSeriesChecked(event.currentTarget.checked)}
                checked={seriesChecked}
            />
            <label>Series</label>
            <Select
                isSearchable
                value={series}
                options={props.names.series}
                onChange={(selectedOption) => setSeries(selectedOption)}
            />
            <input
                type="checkbox"
                onChange={(event) => setGameFormatChecked(event.currentTarget.checked)}
                checked={gameFormatChecked}
            />
            <label>Game format</label>
            <Select
                isSearchable
                value={gameFormat}
                options={props.names.gameFormats}
                onChange={(selectedOption) => setGameFormat(selectedOption)}
            />
            <input
                type="checkbox"
                onChange={(event) => setBookerChecked(event.currentTarget.checked)}
                checked={bookerChecked}
            />
            <label>Booker</label>
            <Select
                isSearchable
                value={booker}
                options={props.names.bookers}
                onChange={(selectedOption) => setBooker(selectedOption)}
            />
            <hr/>
            <label>ROI: {parseFloat(Math.round(roi.roi * 100) / 100).toFixed(2)} %, Vetosumma: {parseFloat(Math.round(roi.betSum * 100) / 100).toFixed(2)} euroa, Voittosumma: {parseFloat(Math.round(roi.winSum * 100) / 100).toFixed(2)} euroa, Netto: {parseFloat(Math.round(results[results.length - 1].y * 100) / 100).toFixed(2)} euroa</label>
            <hr/>
            <XYPlot height={650} width={650}>
                <VerticalGridLines />
                <HorizontalGridLines />
                <XAxis title="Number of Bets"/>
                <YAxis title="Result" />
                <LineSeries data={results} />
            </XYPlot>
            <br/>
            <table className="table table-striped" style={{ marginTop: 20 }} >
                <thead>
                <tr>
                    <th>Laji</th>
                    <th>Kohteita</th>
                    <th>Vetosumma</th>
                    <th>Voittosumma</th>
                    <th>ROI</th>
                </tr>
                </thead>
                <tbody>
                { resultBySportList() }
                </tbody>
            </table>
            <br/>
            <RadialChart
                data={diagramResults}
                showLabels = {true}
                width={700}
                height={700} />

        </React.Fragment>
    );
};

export default ViewResults;