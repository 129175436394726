import axios from 'axios'
import C from "../../constants";

const getUserSettings = (token) => {
  const headers = {
    'x-access-token': token
  }

  const request = axios.get(`${C.BASE_URL}/usersettings`, {headers: headers})
  return request.then(response => response.data)
}

const setUserSettings = (userSettings, token) => {
  const headers = {
    'x-access-token': token
  }

  const request = axios.post(`${C.BASE_URL}/usersettings`, userSettings, {headers: headers})
  return request.then(response => response.data)
}

const setTipsAccounting = (tipsAccounting, token) => {
  const headers = {
    'x-access-token': token
  }

  const request = axios.post(`${C.BASE_URL}/tipsAccounting`, tipsAccounting, {headers: headers})
  return request.then(response => response.data)
}

const getAlikerroinMessage = () => {
  const request = axios.get(`${C.BASE_URL}/alikerroinmessage`)
  return request.then(response => response.data)
}

const getNordicbet = () => {
  const request = axios.get(`${C.BASE_URL}/nordicbet`)
  return request.then(response => response.data)
}

const getTelegram = () => {
  const request = axios.get(`${C.BASE_URL}/telegram`)
  return request.then(response => response.data)
}

const getTwitter = () => {
  const request = axios.get(`${C.BASE_URL}/twitter`)
  return request.then(response => response.data)
}

const getYlikerroin = () => {
  const request = axios.get(`${C.BASE_URL}/ylikerroin`)
  return request.then(response => response.data)
}

/*
const create = async newObject => {
  const config = {
    headers: { Authorization: token },
  }

  const response = await axios.post(baseUrl, newObject, config)
  return response.data
}

const update = (id, newObject) => {
  const config = {
    headers: { Authorization: token },
  }
  const request = axios.put(`${baseUrl}/${id}`, newObject, config)
  return request.then(response => response.data)
}

const remove = (id) => {
  const config = {
    headers: { Authorization: token },
  }
  const request = axios.delete(`${baseUrl}/${id}`, config)
  return request.then(response => response.data)
}
*/

export default { getUserSettings, setUserSettings, getNordicbet, setTipsAccounting, getAlikerroinMessage, getTelegram, getTwitter, getYlikerroin } //, create, update, remove, setToken }