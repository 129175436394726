import React, { useState } from 'react'
import YlikerroinBet from './YlikerroinBet'

const Ylikerroin = (props) => {
  const betStyle = {
    paddingTop: 10,
    paddingLeft: 5,
    'border-radius': '10px',
    border: 'solid',
    borderWidth: 2,
    marginBottom: 10,
    'background-color': '#e7ebf0'
  }

  let removeText = 'Poista'
  let showRemoveButton = true
  let wagerText = 'Pelattu'
  let shoWagerButton = true

  const [render, setRender] = useState(false)
  const [showMiniComments, setShowMiniComments] = useState(false)

  const date = new Date(props.ylikerroin.messageDate)

  if (new Date().getTime() - date.getTime() > 12 * 24 * 60 * 60 * 1000){
    return null
  }

  if (!props.showRemoved && (props.removedYlikerroinMessages && props.removedYlikerroinMessages.includes(props.ylikerroin.url))){
    return null
  }
  else if (props.showRemoved && (props.removedYlikerroinMessages && props.removedYlikerroinMessages.includes(props.ylikerroin.url))){
    betStyle['background-color'] = 'DarkGrey'
    shoWagerButton = false
    removeText = 'Palauta'
  }
  else if (!props.showWagered && (props.wageredYlikerroinMessages && props.wageredYlikerroinMessages.includes(props.ylikerroin.url))){
    return null
  }
  else if (props.showWagered && (props.wageredYlikerroinMessages && props.wageredYlikerroinMessages.includes(props.ylikerroin.url))){
    betStyle['background-color'] = 'Chartreuse'
    showRemoveButton = false
    wagerText = 'Palauta'
  }
  else if (!props.showHiddenUsers && props.hiddenYlikerroinUsers && props.hiddenYlikerroinUsers.length > 0 && props.hiddenYlikerroinUsers.includes(props.ylikerroin.user)){
    return null
  }

  if (props.notificationYlikerroinUsers && props.notificationYlikerroinUsers.length > 0 && props.notificationYlikerroinUsers.includes(props.ylikerroin.user)){
    betStyle['border-style'] = 'double'
    betStyle['border-width'] = '20px'
  }

  const day = date.getDate()
  const month = date.getMonth() + 1
  const year = date.getFullYear()
  const hours = date.getHours()
  let minutes = date.getMinutes()

  if (minutes.toString().length < 2){
    minutes = '0' + minutes.toString();
  }

  let title = ''
  if (props.showTitle){
    title = ' - Ylikerroin'
  }

  const ylikerroinbets = props.ylikerroin.bets.map(bet =>
    <YlikerroinBet
      key={bet._id}
      bet={bet}
      removedYlikerroinBets={props.removedYlikerroinBets}
      wageredYlikerroinBets={props.wageredYlikerroinBets}
      handleYlikerroinBetRemove={props.handleYlikerroinBetRemove}
      handleYlikerroinBetWager={props.handleYlikerroinBetWager}
      showHiddenUsers={props.showHiddenUsers}
      showRemoved={props.showRemoved}
      showWagered={props.showWagered}
    />
  )

  let showBets = false
  for (let i = 0; i < props.ylikerroin.bets.length; i++){
    const betString = getBetString(props.ylikerroin.bets[i])
    if (!props.showRemoved && (props.removedYlikerroinBets && props.removedYlikerroinBets.includes(betString))){
      
    }
    else if (props.showRemoved && (props.removedYlikerroinBets && props.removedYlikerroinBets.includes(betString))){
      showBets = true
    }
    else if (!props.showWagered && (props.wageredYlikerroinBets && props.wageredYlikerroinBets.includes(betString))){
      
    }
    else if (props.showWagered && (props.wageredYlikerroinBets && props.wageredYlikerroinBets.includes(betString))){
      showBets = true
    }
    else{
      showBets = true
    }
  }

  if (!showBets && props.ylikerroin.bets.length > 0){
    return null
  }

  let miniComments = []
  const miniCommentsCheckbox = () => {
    if (props.ylikerroin.miniComments && props.ylikerroin.miniComments.length > 0){
      for (let c = 0; c < props.ylikerroin.miniComments.length; c++){
        miniComments.push(<p>{props.ylikerroin.miniComments[c]}</p>)
      }

      return(
      <div>
        <input
            type="checkbox"
            onChange={(event) => setShowMiniComments(event.currentTarget.checked)}
            checked={showMiniComments}
        />
        <label>Näytä minikommentit</label>
        <a href={props.ylikerroin.url} target="_blank">&nbsp;&nbsp;Alkuperäinen viesti</a>
      </div>)
    }
    else{
      return <a href={props.ylikerroin.url} target="_blank">Alkuperäinen viesti</a>
    }
  }

  /*
  let hasBets = false
  if (ylikerroinbets){
    ylikerroinbets.forEach(bet => {
      if (bet){
        hasBets = true
      }
    })
  }
  */

  return (
    <div style={betStyle} onClick={() => setRender(!render)}>
      <div><h4>{props.ylikerroin.user} {day}.{month}.{year} {hours}:{minutes} {title}</h4></div>
      {ylikerroinbets}
      {props.ylikerroin.content.length > 0 ? <div>{props.ylikerroin.content}</div> : null }
      <p>
        {showRemoveButton ? <button className="btn btn-outline-secondary btn-sm" id={props.ylikerroin.url} onClick={props.handleYlikerroinRemove}>{removeText}</button> : null }
        {shoWagerButton ? <button className="btn btn-outline-success btn-sm" id={props.ylikerroin.url} onClick={props.handleYlikerroinWager}>{wagerText}</button> : null }
      </p>
      {miniCommentsCheckbox()}
      {showMiniComments ? miniComments : null}
    </div>
  )
}

export default Ylikerroin

const getBetString = (bet) => {
  let betString = ""
  if (bet.isSuccessful && bet.hasOwnProperty('home') && bet.hasOwnProperty('away')){
      let veto = ""

      if (bet.hasOwnProperty('over')){
          veto = "over " + bet.over
      }
      else if (bet.hasOwnProperty('under')){
          veto = "under " + bet.under
      }
      else if (bet.hasOwnProperty('homeAway') && bet.hasOwnProperty('spread')){
          let spread = bet.spread
          if (bet.spread >= 0 && !bet.spread.includes('+')){
            spread = '+' + bet.spread.toString()
          }

          veto = bet.homeAway + "  " + spread
      }
      else if (bet.hasOwnProperty('homeAway')){
          veto = bet.homeAway
      }
      else{
          veto = bet.veto
      }

      betString = `${bet.home} – ${bet.away} ${veto} kerroin: ${bet.kerroin.toFixed(3).toString()} panos: ${bet.panos.toString()} ${bet.bookie}`
      
  }
  else{
    betString = `${bet.kohde} ${bet.veto} kerroin: ${bet.kerroin.toFixed(3).toString()} panos: ${bet.panos.toString()} ${bet.bookie}`
  }

  return betString
}