import axios from 'axios';
import C from "../../constants";

import * as actionTypes from './actionTypes';

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    };
};

export const authSuccess = (token, userId, isAdvanced) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        idToken: token,
        userId: userId,
        isAdvanced: isAdvanced
    };
};

export const authFail = (error) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error
    };
};

export const logout = () => {
    localStorage.removeItem('token')
    localStorage.removeItem('userId')
    localStorage.removeItem('isAdvanced')
    return {
        type: actionTypes.AUTH_LOGOUT
    };
};

export const auth = (email, password, isSignup) => {
    return dispatch => {
        dispatch(authStart());
        const authData = {
            email: email,
            password: password
        };
        const headers = {'Access-Control-Allow-Origin': '*' };
        //let url = C.BASE_URL + '/register';
        //if (!isSignup) {
           let url = C.BASE_URL + '/login';
        //}
        axios.post(url, authData, {headers: headers})
            .then(response => {
                //console.log('response', response);
                //console.log('x-auth-token', response.headers["x-auth-token"]);
                localStorage.setItem('token', response.data.token);
                localStorage.setItem('userId', response.data._id);
                localStorage.setItem('isAdvanced', response.data.isAdvanced);
                dispatch(authSuccess(response.data.token, response.data._id, response.data.isAdvanced));
            })
            .catch(err => {
                dispatch(authFail(err.response.data.error));
            });
    };
};

export const setAuthRedirectPath = (path) => {
    return {
        type: actionTypes.SET_AUTH_REDIRECT_PATH,
        path: path
    };
};

export const authCheckState = () => {
    return dispatch => {
        const token = localStorage.getItem('token');
        if (!token) {
            dispatch(logout());
        } else {
            const userId = localStorage.getItem('userId')
            const isAdvanced = localStorage.getItem('isAdvanced')
            dispatch(authSuccess(token, userId, isAdvanced))
        }
    };
};