const pushServerPublicKey =  "BN_V4aeDuQrSf3TPsOIW0exPVe4cW3w3mQvpHiZyc6b4GYnf-EkTULbN1IwxOkj8WrxBtcj1Frpf6MLqAQ48hxw";


/**
 * checks if Push notification and service workers are supported by your browser
 */
function isPushNotificationSupported() {
    return "serviceWorker" in navigator && "PushManager" in window;
}

/**
 * asks user consent to receive push notifications and returns the response of the user, one of granted, default, denied
 */
async function askUserPermission() {
    return await Notification.requestPermission();
}
/**
 * shows a notification
 */
function sendNotification() {
    const img = "jason-leung-HM6TMmevbZQ-unsplash.jpg";
    const text = "Take a look at this brand new t-shirt!";
    const title = "New Product Available";
    const options = {
        body: text,
        icon: "jason-leung-HM6TMmevbZQ-unsplash.jpg",
        vibrate: [200, 100, 200],
        tag: "new-product",
        image: img,
        badge: "https://spyna.it/icons/android-icon-192x192.png",
        actions: [{ action: "Detail", title: "View", icon: "https://via.placeholder.com/128/ff0000" }]
    };
    navigator.serviceWorker.ready.then(function(serviceWorker) {
        serviceWorker.showNotification(title, options);
    });
}

/**
 *
 */
function registerServiceWorker() {
    console.log('registerServiceWorker')
    return navigator.serviceWorker.register("/sw.js");
}

/**
 *
 * using the registered service worker creates a push notification subscription and returns it
 *
 */
async function createNotificationSubscription() {
    //wait for service worker installation to be ready
    console.log('createNotificationSubscription')
    const serviceWorker = await navigator.serviceWorker.ready;
    console.log('serviceWorker.ready')
    // subscribe and return the subscription
    const pushManager = await serviceWorker.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: pushServerPublicKey
    })

    console.log('pushManager')
    return pushManager
}

/**
 * returns the subscription if present or nothing
 */
function getUserSubscription() {
    //wait for service worker installation to be ready, and then
    return navigator.serviceWorker.ready
        .then(function(serviceWorker) {
            return serviceWorker.pushManager.getSubscription();
        })
        .then(function(pushSubscription) {
            return pushSubscription;
        });
}

const sleep = (waitTimeInMs) => new Promise(resolve => setTimeout(resolve, waitTimeInMs));

export {
    isPushNotificationSupported,
    askUserPermission,
    registerServiceWorker,
    sendNotification,
    createNotificationSubscription,
    getUserSubscription
};