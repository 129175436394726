const constants = {

    DATE_FORMAT: "DD.MM.YYYY",
    TIME_FORMAT: "HH.mm",

    BASE_URL: 'https://www.alikerroin.com/api',
    BETFAIR_BASE_URL: 'https://www.alikerroin.com:4001/api',

    PENDING: "Kesken",
    WIN: "Voitto",
    LOSE: "Tappio",
    HALFWIN: "1/2 Voitto",
    HALFLOSE: "1/2 Tappio",
    PUSH: "Push",
    CANCELLED: "Peruttu",

    CURRENT_BETS_WITHOUT_LONG_SIGHT: "Nykyiset vedot ilman pitkäaikaisia",
    CURRENT_BETS: "Nykyiset vedot",
    LAST_DAY: "Viimeisen vuorokauden aikana ratkenneet",
    LAST_WEEK: "Viimeisen viikon aikana ratkenneet",
    LAST_MONTH: "Viimeisen kuukauden aikana ratkenneet",
    LAST_YEAR: "Viimeisen vuoden aikana ratkenneet",

    SIDE: 'Side',
    TOTAL: 'Total',
    OUTRIGHT: 'Voittaja',
    CORRECT_SCORE: 'Tulos',
    H2H: 'H2H',
    CORNERS: 'Kulmapotkut',
    MULTI: 'Multi',

    FOOTBALL: 'Jalkapallo',
    ICEHOCKEY: 'Jääkiekko',
    TENNIS: 'Tennis',
    GOLF: 'Golf',
    BASEBALL: 'Baseball',
    BASKETBALL: 'Koripallo',
    AMERICAN_FOOTBALL: 'Amerikkalainen jalkapallo',
    ESPORTS: 'Esports',
    TROTTING: 'Ravit',
    VOLLEYBALL: 'Lentopallo',
    SNOOKER: 'Snooker',
    FUTSAL: 'Futsal',
    PESAPALLO: 'Pesäpallo',
    CYCLING: 'Pyöräily',

    INIT_STATE: "INIT_STATE",

    SET_LOCALE: "SET_LOCALE",

    LOGIN: "LOGIN",
    REGISTRATION: "REGISTRATION",
    UPDATE_USER: "UPDATE_USER",
    LOGOUT: "LOGOUT",
    LOGOUT_ASYNC: "LOGOUT_ASYNC",
    LOGIN_FORM_CHANGE: "ON_LOGIN_FORM_CHANGE",
    AUTO_LOGIN_ASYNC: "AUTO_LOGIN_ASYNC",
    LOGIN_ASYNC: "LOGIN_ASYNC",
    REGISTRATION_ASYNC: "REGISTRATION_ASYNC",
    UPDATE_USER_ASYNC: "UPDATE_USER_ASYNC",
    SET_MEMBERSHIP_CHECK_RESULT: "SET_MEMBERSHIP_CHECK_RESULT",
    USER_EDIT_OWN_PROFILE: "USER_EDIT_OWN_PROFILE",

    AUTH_FORGOT_PASSWORD_SAGA: "AUTH_FORGOT_PASSWORD_SAGA",
    AUTH_FORGOT_PASSWORD: "AUTH_FORGOT_PASSWORD",

    CHAR_KEY_FOR_ENTER: 13,

    SELECT_CUSTOMER_FOR_UI: "SELECT_CUSTOMER_FOR_UI",
    SESSION_SAVE_LOGGED_USER: "SESSION_SAVE_LOGGED_USER",
    INITIALIZE_SESSION_SAGA: "INITIALIZE_SESSION_SAGA",

    USER_LEVEL__INVALID: 0,
    USER_LEVEL__BASIC_USER: 10,
    USER_LEVEL__COMMUNITY_USER: 20,
    USER_LEVEL__MEMBER: 30,
    USER_LEVEL__ADMIN: 40,
    USER_LEVEL__DEVELOPER: 50,

    DATA_INSERT_LIMITS__BASIC_USER: 300,

    TEXT_MAX_LENGTH_TINY: 10,
    TEXT_MAX_LENGTH_SMALL: 50,
    TEXT_MAX_LENGTH_MEDIUM: 200,
    TEXT_MAX_LENGTH_LARGE: 2000,

    GENDER__UNDEFINED: 0,
    GENDER__IGNORE_IN_SEARCH: 10,
    GENDER__FEMALE: 20,
    GENDER__MALE: 30,
    GENDER__OTHER: 40,


    DATE_PREFIX__UNDEFINED: 0,
    DATE_PREFIX__BEFORE: 10,
    DATE_PREFIX__AFTER: 20,
    DATE_PREFIX__LATEST: 30,
    DATE_PREFIX__ABOUT: 40,

    ADD_RELATION_ASYNC: "ADD_RELATION_ASYNC",
    DELETE_RELATION_ASYNC: "DELETE_RELATION_ASYNC",
    GET_RELATIVES_ASYNC: "GET_RELATIVES_ASYNC",
    GET_CANDIDATE_RELATIVES_RELATIVES_ASYNC: "GET_CANDIDATE_RELATIVES_RELATIVES_ASYNC",
    STORE_RELATIVES: "STORE_RELATIVES",
    STORE_CANDIDATE_RELATIVES_RELATIVES: "STORE_CANDIDATE_RELATIVES_RELATIVES",
    STORE_RELATION_INFO: "STORE_RELATION_INFO",
    CLEAR_RELATION_INFO: "CLEAR_RELATION_INFO",

    ADD_LOCATION_ASYNC: "ADD_LOCATION_ASYNC",
    UPDATE_LOCATION_ASYNC: "UPDATE_LOCATION_ASYNC",
    SEARCH_LOCATION_BY_NAME_ASYNC: "SEARCH_LOCATION_BY_NAME_ASYNC",
    STORE_LOCATIONS: "STORE_LOCATIONS",
    STORE_ALL_ORIGINAL_LOCATIONS: "STORE_ALL_ORIGINAL_LOCATIONS",
    STORE_LOCATION: "STORE_LOCATION",
    EXACT_LOCATION_SEARCH_ASYNC: "EXACT_LOCATION_SEARCH_ASYNC",
    INIT_LOCATION_FROM_STORE: "INIT_LOCATION_FROM_STORE",
    GET_LOCATION_ASYNC: "GET_LOCATION_ASYNC",
    GET_ALL_ORIGINAL_LOCATIONS_ASYNC: "GET_ALL_ORIGINAL_LOCATIONS_ASYNC",

    ADD_PERSON_ASYNC: "ADD_PERSON_ASYNC",
    ADD_PERSON_REFERENCE_ASYNC: "ADD_PERSON_REFERENCE_ASYNC",
    CONFIRM_PERSON_REFERENCE_ASYNC: "CONFIRM_PERSON_REFERENCE_ASYNC",
    DELETE_PERSON_REFERENCE_ASYNC: "DELETE_PERSON_REFERENCE_ASYNC",
    UPDATE_PERSON_ASYNC: "UPDATE_PERSON_ASYNC",
    SEARCH_PERSON_BY_NAME_ASYNC: "SEARCH_PERSON_BY_NAME_ASYNC",
    STORE_PERSONS: "STORE_PERSONS",
    STORE_PERSON: "STORE_PERSON",
    STORE_FETCHED_PERSON: "STORE_FETCHED_PERSON",
    STORE_EDITED_PERSON: "STORE_EDITED_PERSON",
    INIT_PERSON_FROM_STORE: "INIT_PERSON_FROM_STORE",
    EXACT_PERSON_SEARCH_ASYNC: "EXACT_PERSON_SEARCH_ASYNC",
    GET_PERSON_ASYNC: "GET_PERSON_ASYNC",



    ADMIN_USER_EDIT_FORM_REQUESTED: "ADMIN_USER_EDIT_FORM_REQUESTED",
    ADMIN_USER_GET_ALL_ASYNC: "ADMIN_USER_GET_ALL_ASYNC",
    ADMIN_USER_GET_ALL_STORE: "ADMIN_USER_GET_ALL_STORE",
    ADMIN_SAVE_USER_ASYNC: "ADMIN_SAVE_USER_ASYNC",


    LOCATION_IMPORT_EXCEL_ASYNC: "LOCATION_IMPORT_EXCEL_ASYNC",
    LOCATION_IMPORT_EXCEL_DONE: "LOCATION_IMPORT_EXCEL_DONE",

    TYPING_TIMEOUT: 500 /*ms*/,

    DATE_DISPLAY_FORMAT: "dd.MM.yyyy",

    SERVER_URL: "api"
};

export default constants;