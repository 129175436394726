import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'
import  * as Datetime from 'react-datetime'
import moment from 'moment'
import PropTypes from 'prop-types'
import C from "../../constants"
import '../../../node_modules/react-datetime/css/react-datetime.css'
import axios from "axios"
import {Link} from "react-router-dom"

const Vakio = (props) => {
    const dateNow = new Date().getTime()
    const m = moment()
    const roundTimeUp = m.minute() || m.second() || m.millisecond() ? m.add(2, 'hour').startOf('hour') : m.startOf('hour')
    const [dateTime, setDateTime] = useState(roundTimeUp)
    const [vakioEvents, setmVakioEvents] = useState([])

    const getFixtures = async () => {
        if (props.token !== undefined && props.token !== null)
        {
            axios.get(C.BASE_URL + '/vakio', {headers: { 'x-access-token': props.token }})
                .then(response => {

                    setmVakioEvents(response.data)

                })
                .catch(function (error){
                    console.log(error)
                });
        }
    };

    useEffect(() => {
        if (props.token !== undefined && props.token !== null)
        {
            getFixtures();
        }
    }, []);

    const vakioList = () => {
        return vakioEvents.map(function(current, i){
            return <VakioItem vakioItem={current} key={i} />
        })
    };

    const VakioItem = props => (
        <tr>
            <td>
                <Link to={"/vakiorows/"+props.vakioItem.id}>{props.vakioItem.id}</Link>
            </td>
            <td>
                <Link to={"/vakiorows/"+props.vakioItem.id}>{props.vakioItem.name}</Link>
            </td>
            <td>
                <Link to={"/vakiorows/"+props.vakioItem.id}>{props.vakioItem.brandId}</Link>
            </td>
            <td>{props.vakioItem.sport}</td>
            <td>{props.vakioItem.jackpots.length > 0 ? props.vakioItem.jackpots[props.vakioItem.jackpots.length - 1].description : null}</td>
            <td>{moment(props.vakioItem.closeTime).format(C.DATE_FORMAT + ' ' + C.TIME_FORMAT)}</td>

        </tr>
    );

    return (
        <React.Fragment>
            <label>Vakio</label>
            <table className="table table-hover" style={{ marginTop: 20 }} >
                <thead>
                <tr>
                    <th>Id</th>
                    <th>Nimi</th>
                    <th>Kohde</th>
                    <th>Laji</th>
                    <th>Bonus</th>
                    <th>Sulkeutuu</th>
                </tr>
                </thead>
                <tbody>
                { vakioList() }
                </tbody>
            </table>
        </React.Fragment>
    );
};

export default Vakio