import React from 'react';
import { Redirect } from "react-router-dom";
import Modal from 'react-modal';
import moment from 'moment';
import axios from "axios";
import C from "../../constants";

const customStyles = {
    content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        transform             : 'translate(-50%, -50%)'
    }
};

class DeleteBet extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            modalIsOpen: false,
            redirect: false,
            accounting_home: '',
            accounting_visitor: '',
            accounting_sport: '',
            accounting_series: '',
            accounting_dateTime: '',
            accounting_gameFormat: '',
            accounting_selection: '',
            accounting_booker: '',
            accounting_odds: '',
            accounting_bet: '',
            accounting_win: '',
            accounting_result: ''
        };

        this.deleteModal = this.deleteModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    deleteModal() {
        this.setState({modalIsOpen: false, redirect: true});

        let id = this.props.location.pathname.substring(7);

        const headers = {
            'x-access-token': this.props.token
        };

        axios.delete(C.BASE_URL +id, {headers: headers})
            .then(
                res => console.log(res.data)
            );
    }

    closeModal() {
        this.setState({modalIsOpen: false, redirect: true});
        console.log('Close', this.props.match.params.id);
    }

    componentDidMount() {
        let id = this.props.location.pathname.substring(7);

        const headers = {
            'x-access-token': this.props.token
        };

        axios.get(C.BASE_URL +id, {headers: headers})
            .then(response => {
                this.setState({
                    modalIsOpen: true,
                    accounting_home: response.data.accounting_home,
                    accounting_visitor: response.data.accounting_visitor,
                    accounting_sport: response.data.accounting_sport,
                    accounting_series: response.data.accounting_series,
                    accounting_dateTime: moment(response.data.accounting_dateTime).format('DD.MM.YYYY HH.mm'),
                    accounting_gameFormat: response.data.accounting_gameFormat,
                    accounting_selection: response.data.accounting_selection,
                    accounting_booker: response.data.accounting_booker,
                    accounting_odds: response.data.accounting_odds,
                    accounting_bet: response.data.accounting_bet,
                    accounting_win: response.data.accounting_win,
                    accounting_result: response.data.accounting_result
                })
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    render() {
        let redirect = null;

        if (this.state.redirect)
        {
            redirect = <Redirect to="/"/>;
        }

        return (
            <div>
                {redirect}
                <Modal
                    isOpen={this.state.modalIsOpen}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    contentLabel="confirmDelete"
                >
                    <h2>Haluatko varmasti poistaa?</h2>
                    <p>{this.state.accounting_home} - {this.state.accounting_visitor}</p>
                    <p>{this.state.accounting_dateTime}</p>
                    <p>{parseFloat(Math.round(this.state.accounting_odds * 100) / 100).toFixed(3)} x {parseFloat(Math.round(this.state.accounting_bet * 100) / 100).toFixed(3)} = {parseFloat(Math.round(this.state.accounting_win * 100) / 100).toFixed(3)}</p>
                    <button className="btn btn-primary" onClick={this.deleteModal}>Delete</button>
                    <button className="btn btn-secondary" onClick={this.closeModal}>Cancel</button>
                </Modal>
            </div>
        );
    }
}

export default DeleteBet;