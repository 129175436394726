import React, { useState } from 'react'

const Telegram = (props) => {
  const betStyle = {
    paddingTop: 10,
    paddingLeft: 2,
    'border-radius': '4px',
    border: 'solid',
    borderWidth: 2,
    marginBottom: 10,
    'background-color': 'PaleTurquoise'
  }

  let removeText = 'Poista'
  let showRemoveButton = true
  let wagerText = 'Pelattu'
  let shoWagerButton = true

  const [render, setRender] = useState(false)
  const date = new Date(props.message.date)

  if (!props.showRemoved && (props.removedTelegramMessages && props.removedTelegramMessages.includes(date.getTime().toString()))){
    return null
  }
  else if (props.showRemoved && (props.removedTelegramMessages && props.removedTelegramMessages.includes(date.getTime().toString()))){
    betStyle['background-color'] = 'DarkGrey'
    shoWagerButton = false
    removeText = 'Palauta'
  }
  else if (!props.showWagered && (props.wageredTelegramMessages && props.wageredTelegramMessages.includes(date.getTime().toString()))){
    return null
  }
  else if (props.showWagered && (props.wageredTelegramMessages && props.wageredTelegramMessages.includes(date.getTime().toString()))){
    betStyle['background-color'] = 'Chartreuse'
    showRemoveButton = false
    wagerText = 'Palauta'
  }
  else if (!props.showHiddenUsers && props.hiddenTelegramUsers && props.hiddenTelegramUsers.length > 0 && props.hiddenTelegramUsers.includes(props.message.userName)){
    return null
  }

  if (props.notificationTelegramUsers && props.notificationTelegramUsers.length > 0 && props.notificationTelegramUsers.includes(props.message.userName)){
    betStyle['border-style'] = 'double'
    betStyle['border-width'] = '20px'
  }

  if (new Date().getTime() - date.getTime() > 5 * 24 * 60 * 60 * 1000){
    return null
  }

  const day = date.getDate()
  const month = date.getMonth() + 1
  const year = date.getFullYear()
  const hours = date.getHours()
  let minutes = date.getMinutes()

  if (minutes.toString().length < 2){
    minutes = '0' + minutes.toString();
  }

  let title = ''
  if (props.showTitle){
    title = ' - Telegram'
  }

  return (
    <div style={betStyle} onClick={() => setRender(!render)}>
      <h4>{props.message.userName} - {day}.{month}.{year} {hours}:{minutes} {title}</h4>
      <pre>{props.message.message}</pre>
      <p>
        {showRemoveButton ? <button className="btn btn-outline-secondary btn-sm" id={date.getTime().toString()} onClick={props.handleTelegramRemove}>{removeText}</button> : null }
        {shoWagerButton ? <button className="btn btn-outline-success btn-sm" id={date.getTime().toString()} onClick={props.handleTelegramWager}>{wagerText}</button> : null }
      </p>
    </div>
  )}

const NewlineText = rawText => {
  const text = rawText
  const newText = text.split('\n').map(str => <p>{str}</p>)
  
  return newText;
}

export default Telegram