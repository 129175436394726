const veikkausIds = {
  BANDY_SPORT_ID: 6,

  //BASEBALL_SPORT_ID: ,
  //MLB_LEAGUE_ID: ,

  BASKETBALL_SPORT_ID: 5,
  //FIBA_EUROBASKET_LEAGUE_ID: ,
  //FIBA_WC_LEAGUE_ID: ,
  BASKETBALL_FINLAND_CATEGORY_ID: 1,
  KORISLIIGA_TOURNAMENT_ID: 1,
  BASKETBALL_USA_CATEGORY_ID: 2,
  NBA_TOURNAMENT_ID: 1,
  BASKETBALL_INTERNATIONAL_CATEGORY_ID: 3,
  
  //DARTS_SPORT_ID: ,
  //ESPORTS_SPORT_ID: ,
  ESPORTS_CSGO_SPORT_ID: 33,
  ESPORTS_LOL_SPORT_ID: 39,
  ESPORTS_DOTA2_SPORT_ID: 41,
  ESPORTS_COD_SPORT_ID: 44,
  ESPORTS_ROCKET_LEAGUE_SPORT_ID: 61,

  FLOORBALL_SPORT_ID: 7,
  //SUOMEN_CUP_LEAGUE_ID: ,

  /*
  AMERICAN_FOOTBALL_SPORT_ID: ,
  NFL_LEAGUE_ID: ,
  NFL_ALTERNATE_LINES_LEAGUE_ID: ,
  NFL_PRESEASON_LEAGUE_ID: ,
  NFL_SUPER_BOWL_PROPS_LEAGUE_ID: ,
*/

  //FUTSAL_SPORT_ID: ,
  GOLF_SPORT_ID: 19,
  HANDBALL_SPORT_ID: 12,

  ICE_HOCKEY_SPORT_ID: 3,
  ICE_HOCKEY_FINLAND_CATEGORY_ID: 1,
  SM_LIIGA_TOURNAMENT_ID: 1,
  MESTIS_TOURNAMENT_ID: 2,
  ICE_HOCKEY_USA_CATEGORY_ID: 2,
  NHL_TOURNAMENT_ID: 1,
  ICE_HOCKEY_SWEDEN_CATEGORY_ID: 4,
  SHL_TOURNAMENT_ID: 1,
  HOCKEY_ALLSVENSKAN_TOURNAMENT_ID: 2,
  ICE_HOCKEY_SWITZERLAND_CATEGORY_ID: 7,
  NLA_TOURNAMENT_ID: 1,
  SWISS_LEAGUE_TOURNAMENT_ID: 2,
  ICE_HOCKEY_GERMANY_CATEGORY_ID: 8,
  DEL_TOURNAMENT_ID: 1,
  ICE_HOCKEY_RUSSIA_CATEGORY_ID: 9,
  KHL_TOURNAMENT_ID: 1,
  ICE_HOCKEY_CZECH_CATEGORY_ID: 10,
  CZECH_EXTRALIIGA_TOURNAMENT_ID: 1,
  ICE_HOCKEY_DENMARK_CATEGORY_ID: 12,
  METAL_TOURNAMENT_ID: 1,
  ICE_HOCKEY_SLOVAKIA_CATEGORY_ID: 13,
  SLOVAKIA_EXTRALIIGA_TOURNAMENT_ID: 1,
  ICE_HOCKEY_NORWAY_CATEGORY_ID: 14,
  NORWAY_LEAGUE_TOURNAMENT_ID: 1,
  ICE_HOCKEY_AUSTRIA_CATEGORY_ID: 15,
  AUSTRIA_LEAGUE_TOURNAMENT_ID: 1,
  ICE_HOCKEY_FRANCE_CATEGORY_ID: 16,
  FRENCH_LEAGUE_TOURNAMENT_ID: 1,

  MIXED_MARTIAL_ARTS_SPORT_ID: 40,
  UCF_CATEGORY_ID: 1,

  SNOOKER_SPORT_ID: 27,
  
  SOCCER_SPORT_ID: 1,
  SOCCER_FINLAND_CATEGORY_ID: 1,
  SUOMEN_CUP_TOURNAMENT_ID: 1,
  SOCCER_ENGLAND_CATEGORY_ID: 2,
  ENGLAND_PREMIER_LEAGUE_TOURNAMENT_ID: 1,
  ENGLAND_CHAMPIONSHIP_TOURNAMENT_ID: 2,
  ENGLAND_LEAGUE_1_TOURNAMENT_ID: 3,
  ENGLAND_LEAGUE_2_TOURNAMENT_ID: 4,
  SOCCER_ITALY_CATEGORY_ID: 3,
  SERIE_A_TOURNAMENT_ID: 1,
  SOCCER_GERMANY_CATEGORY_ID: 4,
  BUNDESLIGA_TOURNAMENT_ID: 1,
  BUNDESLIGA2_TOURNAMENT_ID: 2,
  SOCCER_SPAIN_CATEGORY_ID: 5,
  LA_LIGA_TOURNAMENT_ID: 1,
  SEQUNDA_LIGA_TOURNAMENT_ID: 2,
  SOCCER_HOLLAND_CATEGORY_ID: 6,
  SOCCER_FRANCE_CATEGORY_ID: 9,
  LIGUE1_TOURNAMENT_ID: 1,
  SOCCER_SCOTLAND_CATEGORY_ID: 10,
  SOCCER_TURKEY_CATEGORY_ID: 11,
  SOCCER_PORTUGAL_CATEGORY_ID: 12,
  SOCCER_SWEDEN_CATEGORY_ID: 13,
  SOCCER_INTERNATIONAL_CATEGORY_ID: 15,
  SOCCER_DENMARK_CATEGORY_ID: 16,
  SOCCER_INTERNATIONAL_CLUB_CATEGORY_ID: 17,
  UEFA_CHAMPIONS_LEAGUE_TOURNAMENT_ID: 1,
  UEFA_EUROPA_LEAGUE_TOURNAMENT_ID: 2,
  SOCCER_AUSTRIA_CATEGORY_ID: 18,
  SOCCER_SWITZERLAND_CATEGORY_ID: 19,
  SOCCER_GREECE_CATEGORY_ID: 27,
  SOCCER_ICELAND_CATEGORY_ID: 28,
  SOCCER_RUSSIA_CATEGORY_ID: 36,
  SOCCER_BRAZIL_CATEGORY_ID: 37,
  SOCCER_BELGIUM_CATEGORY_ID: 38,
  SOCCER_AUSTRALIA_CATEGORY_ID: 39,
  SOCCER_POLAND_CATEGORY_ID: 41,
  SOCCER_ARGENTINA_CATEGORY_ID: 42,
  SOCCER_CZECH_CATEGORY_ID: 43,
  SOCCER_HUNGARY_CATEGORY_ID: 44,
  SOCCER_MEXICO_CATEGORY_ID: 45,
  SOCCER_INDIA_CATEGORY_ID: 50,
  SOCCER_URUGUAY_CATEGORY_ID: 52,
  SOCCER_SLOVAKIA_CATEGORY_ID: 53,
  SOCCER_NORTHERN_IRELAND_CATEGORY_ID: 54,
  SOCCER_ISRAEL_CATEGORY_ID: 56,
  SOCCER_SOUTH_AFRICA_CATEGORY_ID: 60,
  SOCCER_ECUADOR_CATEGORY_ID: 64,

  /*
  SUOMEN_CUP_LEAGUE_ID: ,
  SUOMEN_CUP_CORNERS_LEAGUE_ID: ,
  KAKKONEN_LEAGUE_ID: ,
  KAKKONEN_CORNERS_LEAGUE_ID: ,
  KAKKOSEN_CUP_LEAGUE_ID: ,
  KANSALLINEN_LIIGA_LEAGUE_ID: ,
  KOLMONEN_LEAGUE_ID: ,
  LIIGA_CUP_LEAGUE_ID: ,
  LIIGA_CUP_CORNERS_LEAGUE_ID: ,
  FINLAND_U20_LEAGUE_ID: ,
  VEIKKAUSLIIGA_LEAGUE_ID: ,
  VEIKKAUSLIIGA_CORNERS_LEAGUE_ID: ,
  YKKONEN_LEAGUE_ID: ,
  YKKONEN_CORNERS_LEAGUE_ID: ,
  ENGLAND_CHAMPIONSHIP_LEAGUE_ID: ,
  ENGLAND_FA_CUP_LEAGUE_ID: ,
  ENGLAND_LEAGUE_1_LEAGUE_ID: ,
  ENGLAND_LEAGUE_2_LEAGUE_ID: ,
  ENGLAND_LEAGUE_CUP_LEAGUE_ID: ,
  ENGLAND_PREMIER_LEAGUE_ID: ,
  LIGUE_1_LEAGUE_ID: ,
  SERIE_A_LEAGUE_ID: ,
  COPPA_ITALIA_LEAGUE_ID: ,
  LA_LIGA_LEAGUE_ID: ,
  SEQUNDA_DIVISION_LEAGUE_ID: ,
  SPAIN_CUP_LEAGUE_ID: ,
  ALLSVENSKAN_LEAGUE_ID: ,
  UEFA_CHAMPIONS_LEAGUE_ID: ,
  UEFA_EUROPA_LEAGUE_ID: ,
*/
  /*
  AFC_LEAGUE_ID: ,
  IRAN_AZADEGAN_LEAGUE_ID: ,
  IRAN_CUP_LEAGUE_ID: ,
  IRAN_PRO_LEAGUE_ID: ,
  QATAR_STARS_LEAGUE_ID: ,
  QATAR_STARS_CUP_LEAGUE_ID: ,
  SAUDI_ARABIA_PRO_LEAGUE_ID: ,
  UAE_LEAGUE_ID: ,
  UAE_CUP_LEAGUE_ID: ,
*/
  //TABLE_TENNIS_SPORT_ID: ,

  TENNIS_SPORT_ID: 10,
  /*ATP_AUSTRALIAN_OPEN_LEAGUE_ID: ,
  ATP_AUSTRALIAN_OPEN_QUALIFIERS_LEAGUE_ID: ,
  ATP_AUSTRALIAN_OPEN_R1_LEAGUE_ID: ,
  ATP_AUSTRALIAN_OPEN_R2_LEAGUE_ID: ,
  ATP_AUSTRALIAN_OPEN_R3_LEAGUE_ID: ,
  ATP_AUSTRALIAN_OPEN_R16_LEAGUE_ID: ,
  ATP_AUSTRALIAN_OPEN_QF_LEAGUE_ID: ,
  ATP_AUSTRALIAN_OPEN_SF_LEAGUE_ID: ,
  ATP_AUSTRALIAN_OPEN_FINAL_LEAGUE_ID: ,
  ATP_FRENCH_OPEN_LEAGUE_ID: ,
  ATP_FRENCH_OPEN_QUALIFIERS_LEAGUE_ID: ,
  ATP_FRENCH_OPEN_R1_LEAGUE_ID: ,
  ATP_FRENCH_OPEN_R2_LEAGUE_ID: ,
  ATP_FRENCH_OPEN_R3_LEAGUE_ID: ,
  ATP_FRENCH_OPEN_R16_LEAGUE_ID: ,
  ATP_FRENCH_OPEN_QF_LEAGUE_ID: ,
  ATP_FRENCH_OPEN_SF_LEAGUE_ID: ,
  ATP_FRENCH_OPEN_FINAL_LEAGUE_ID: ,
  ATP_WIMBLEDON_OPEN_LEAGUE_ID: ,
  ATP_WIMBLEDON_OPEN_QUALIFIERS_LEAGUE_ID: ,
  ATP_WIMBLEDON_OPEN_R1_LEAGUE_ID: ,
  ATP_WIMBLEDON_OPEN_R2_LEAGUE_ID: ,
  ATP_WIMBLEDON_OPEN_R3_LEAGUE_ID: ,
  ATP_WIMBLEDON_OPEN_R16_LEAGUE_ID: ,
  ATP_WIMBLEDON_OPEN_QF_LEAGUE_ID: ,
  ATP_WIMBLEDON_OPEN_SF_LEAGUE_ID: ,
  ATP_WIMBLEDON_OPEN_FINAL_LEAGUE_ID: ,
  ATP_US_OPEN_LEAGUE_ID: ,
  ATP_US_OPEN_QUALIFIERS_LEAGUE_ID: ,
  ATP_US_OPEN_R1_LEAGUE_ID: ,
  ATP_US_OPEN_R2_LEAGUE_ID: ,
  ATP_US_OPEN_R3_LEAGUE_ID: ,
  ATP_US_OPEN_R16_LEAGUE_ID: ,
  ATP_US_OPEN_QF_LEAGUE_ID: ,
  ATP_US_OPEN_SF_LEAGUE_ID: ,
  ATP_US_OPEN_FINAL_LEAGUE_ID: ,*/

  VOLLEYBALL_SPORT_ID: 4,
  VOLLEYBALL_FINLAND_CATEGORY_ID: 1,
  MESTARUUSLIIGA_MEN_TOURNAMENT_ID: 1,
  MESTARUUSLIIGA_WOMEN_TOURNAMENT_ID: 2,

  //FIVB_WC_LEAGUE_ID: ,

  
  //CROSS_COUNTRY_SPORT_ID: ,
  //FORMULA1_SPORT_ID: ,
  //CYCLING_SPORT_ID: ,
  //NORDIC_COMBINED_SPORT_ID: ,
  //OLYMPICS_SPORT_ID: ,
  WINTER_SPORT_ID: 16,
  BIATHLON_CATEGORY_ID: 32,
  SKI_JUMPING_CATEGORY_ID: 34,
}

module.exports = {
  veikkausIds
}