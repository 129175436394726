import React, { useState, useEffect, useRef } from 'react'
import Notification from '../Notification'
import oddsService from './service'

const Odds = (props) => {
    const [betfair, setBetfair] = useState({})
    const [pinnacle, setPinnacle] = useState({})
    const [veikkaus, setVeikkaus] = useState({})
    const [home, setHome] = useState("")
    const [away, setAway] = useState("")
    const [className, setClassName] = useState(null)
    const [notificationMessage, setNotificationMessage] = useState(null)

    const getOdds = () => {
        const params = {
            home: home,
            away: away
        }

        console.log('Get odds', params)
        oddsService.getBetfair(params).then(response => {
            console.log('Betfair', response)
            setBetfair(response)
        })

        oddsService.getPinnacle(params).then(response => {
            console.log('Pinnacle', response)
            setPinnacle(response)
        })

        oddsService.getVeikkaus(params).then(response => {
            console.log('Veikkaus', response)
            setVeikkaus(response)
        })
    }

    useEffect(() => {
        /*oddsService.getBetfair().then(response => {
            console.log('Betfair', response)
            response.sort((b, a) => {
                const c = new Date(a.eventDate)
                const d = new Date(b.eventDate)
                return c-d
            })
            setBetfair(response)
        })*/
    }, [])
    
    useEffect(() => {
        /*oddsService.getPinnacle().then(response => {
            console.log('Pinnacle', response)
            response.sort((b, a) => {
                const c = new Date(a.eventDate)
                const d = new Date(b.eventDate)
                return c-d
            })
            setPinnacle(response)
        })*/
    }, [])
    
    useEffect(() => {
        /*oddsService.getVeikkaus().then(response => {
            console.log('Veikkaus', response)
            response.sort((b, a) => {
                const c = new Date(a.eventDate)
                const d = new Date(b.eventDate)
                return c-d
            })
            setVeikkaus(response)
        })*/
    }, [])

    return (
        <div>
          <Notification message={notificationMessage} className = {className} />
          <input
                name="home"
                type="text"
                placeholder="koti"
                value={home}
                onChange={({ target }) => setHome(target.value)}
          />
          <input
                name="away"
                type="text"
                placeholder="vieras"
                value={away}
                onChange={({ target }) => setAway(target.value)}
          />
          <button className="btn btn-primary" onClick={getOdds}>Hae</button>
          <br/>
          <pre>{ JSON.stringify(betfair, null, 2) }</pre>
          <hr/>
          <pre>{ JSON.stringify(pinnacle, null, 2) }</pre>
          <hr/>
          <pre>{ JSON.stringify(veikkaus, null, 2) }</pre>
        </div>
      )
}

export default Odds