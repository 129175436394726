import { betfairIds } from "./betfairIds"

const getPinnacleBetDetails = (pinnacleBet) => {
  let home = pinnacleBet.team1
  let away = pinnacleBet.team2

  if (pinnacleBet.homeTeamType === "Team2"){
    home = pinnacleBet.team2
    away = pinnacleBet.team1
  }
  let betType = pinnacleBet.betType
  let handicap = ''
  let bet = pinnacleBet.teamName
  try {
    switch (pinnacleBet.betType) {
      case "MONEYLINE":
        betType = "ML"
        break;
      case "SPREAD":
        betType = "AH"
        handicap = pinnacleBet.handicap
        break;
      case "TOTAL_POINTS":
        betType = "Total"
        handicap = pinnacleBet.handicap
        if (pinnacleBet.side === "OVER"){
          bet = "Over"
        }
        else if (pinnacleBet.side === "UNDER"){
          bet = "Under"
        }

        break;
      case "TEAM_TOTAL_POINTS":
        betType = bet + " Total"
        handicap = pinnacleBet.handicap
        if (pinnacleBet.side === "OVER"){
          bet = "Over"
        }
        else if (pinnacleBet.side === "UNDER"){
          bet = "Under"
        }

        break;
      default:
        
        break;
    }

    return {
      home: home,
      away: away,
      betType: betType,
      handicap: handicap,
      bet: bet
    }

  } catch (error) {
      console.log(error)
      return {
        home: home,
        away: away,
        betType: betType,
        handicap: handicap,
        bet: bet
      }
  }
}

const getBetfairBetDetails = (betfairBet) => {
  console.log('betfairBet', betfairBet)
  const convertLay = betfairBet.side === "LAY"
  let home = betfairBet.eventName
  let away = ''
  let betType = betfairBet.marketName
  let handicap = undefined
  let bet = betfairBet.betName
  let stake = betfairBet.sizeMatched
  if (betfairBet.eventName.includes(' v ')){
    const teams = betfairBet.eventName.split(' v ')
    if (teams && teams.length === 2){
      home = teams[0].trim()
      away = teams[1].trim()
    }
  }
  else if (betfairBet.eventName.includes(' @ ')){
    const teams = betfairBet.eventName.split(' @ ')
    if (teams && teams.length === 2){
      home = teams[1].trim()
      away = teams[0].trim()
    }
  }

  let odds = (betfairBet.averagePriceMatched - 1) * 0.98 + 1

  try {
        switch (betfairBet.marketType) {
          case 'MATCH_ODDS':
            if (parseInt(betfairBet.eventTypeId) === betfairIds.ICE_HOCKEY_SPORT_ID || parseInt(betfairBet.eventTypeId) === betfairIds.SOCCER_SPORT_ID){
              betType = "1X2"
            }
            else {
              betType = "ML"
            }
            
            break;
          case 'RT_MATCH_ODDS':
            betType = "1X2"
            break;
          case 'MONEY_LINE':
            betType = "ML"
            break;
          case 'ASIAN_HANDICAP':
            betType = "AH"
            handicap = betfairBet.handicap
            break;
          case 'COMBINED_TOTAL':
            betType = "Total"
            handicap = betfairBet.handicap
            break;
          case 'ALT_TOTAL_GOALS':
            betType = "Total"
            handicap = betfairBet.handicap
            break;
          case 'OVER_UNDER_25':
            handicap = 2.5
            betType = "Total"
            
            if (bet.includes('Over')){
              bet = 'Over'
            }
            else if (bet.includes('Under')){
              bet = 'Under'
            }

            break;
          case 'OVER_UNDER_35':
            handicap = 3.5
            betType = "Total"
            
            if (bet.includes('Over')){
              bet = 'Over'
            }
            else if (bet.includes('Under')){
              bet = 'Under'
            }

            break;
          case 'OVER_UNDER_45':
            handicap = 4.5
            betType = "Total"
            
            if (bet.includes('Over')){
              bet = 'Over'
            }
            else if (bet.includes('Under')){
              bet = 'Under'
            }

            break;
          case 'OVER_UNDER_55':
            handicap = 5.5
            betType = "Total"
            
            if (bet.includes('Over')){
              bet = 'Over'
            }
            else if (bet.includes('Under')){
              bet = 'Under'
            }

            break;
          case 'OVER_UNDER_65':
            handicap = 6.5
            betType = "Total"
            
            if (bet.includes('Over')){
              bet = 'Over'
            }
            else if (bet.includes('Under')){
              bet = 'Under'
            }

            break;
          case 'BOTH_TEAMS_TO_SCORE':
            betType = "BTTS"
            break;
          case 'WINNER':
            betType = "Voittaja"
            home = ''
            break;
          default:
            break;
        }

        if (convertLay){
          let convertedBet = ''
          if (bet === home){
            convertedBet = away
          }
          else if (bet === away){
            convertedBet = home
          }

          stake = (stake * (betfairBet.averagePriceMatched - 1))
          odds = (1 / (1 - (1 / betfairBet.averagePriceMatched)) - 1) * 0.98 + 1

          if (handicap && betType !== 'Total'){
            handicap = -handicap
          }

          switch (betType) {
            case '1X2':
              bet = convertedBet
              break;
            case 'ML':
              bet = convertedBet
              break;
            case 'AH':
              bet = convertedBet
              break;
            case 'Total':
              if (bet.includes('Over')){
                bet = 'Under'
              }
              else if (bet.includes('Under')){
                bet = 'Over'
              }
              //bet = convertedBet
              break;
            case 'BTTS':
              if (bet.toUpperCase() === 'YES'){
                bet = 'NO'
              }
              else if (bet.toUpperCase() === 'NO'){
                bet = 'YES'
              }
              break;
            case 'Voittaja':
              bet = 'Ei ' + bet
              break;
            default:
              break;
          }
        }

        if (handicap && handicap >= 0 && betType !== 'Total'){
          handicap = '+' + handicap
        }

        return {
          home: home,
          away: away,
          betType: betType,
          handicap: handicap,
          bet: bet,
          odds: odds,
          stake: stake
        }

  } catch (error) {
      console.log(error)
      return {
        home: home,
        away: away,
        betType: betType,
        handicap: handicap,
        bet: bet,
        odds: odds,
        stake: stake
      }
  }
}

const getVeikkausBetDetails = (veikkausBet) => {
  const teams = getVeikkausTeams(veikkausBet.event)
  let home = teams.home
  let away = teams.away
  let betType = veikkausBet.draw.rows[0].shortName
  let handicap = ''
  let bet = veikkausBet.draw.rows[0].id
  const winnerId = veikkausBet.wager.selections[0].competitors.main[0]
  const winnerIndex = veikkausBet.draw.rows[0].competitors.findIndex(c => c.id === winnerId)
  const splittedTeams = veikkausBet.event.name.split(' - ')

  try {
    switch (veikkausBet.draw.rows[0].shortName) {
      case "1X2":
        betType = "1X2"
        handicap = '-0.5'
        bet = veikkausBet.draw.rows[0].competitors[winnerIndex].name
        break;

      case "12":
        betType = "ML"
        bet = veikkausBet.draw.rows[0].competitors[winnerIndex].name
        break;

      case "HOME_HANDICAP":
        betType = "AH"
        if (veikkausBet.draw.rows[0].competitors[winnerIndex].name.includes(home)){
          handicap = parseFloat(veikkausBet.draw.rows[0].competitors[0].handicap) - 0.5
          bet = home
        }
        else if (veikkausBet.draw.rows[0].competitors[winnerIndex].name.includes(away)){
          handicap = -(parseFloat(veikkausBet.draw.rows[0].competitors[0].handicap) + 0.5)
          bet = away
        }
        
        break;

      case "AWAY_HANDICAP":
        betType = "AH"
        if (veikkausBet.draw.rows[0].competitors[winnerIndex].name.includes(home)){
          handicap = -(parseFloat(veikkausBet.draw.rows[0].competitors[0].handicap) + 0.5)
          bet = home
        }
        else if (veikkausBet.draw.rows[0].competitors[winnerIndex].name.includes(away)){
          handicap = parseFloat(veikkausBet.draw.rows[0].competitors[0].handicap) - 0.5
          bet = away
        }
        
        break;

      case "OVER_UNDER":  
        betType = "Total"
        handicap = parseFloat(veikkausBet.draw.rows[0].competitors[winnerIndex].handicap)
        if (veikkausBet.draw.rows[0].competitors[winnerIndex].name.includes('Yli')){
          bet = "Over"
        }
        else if (veikkausBet.draw.rows[0].competitors[winnerIndex].name.includes('Alle')){
          bet = "Under"
        }
        
        break;
      case "HOME_OVER_UNDER":
        betType = home + " Total"
        handicap = parseFloat(veikkausBet.draw.rows[0].competitors[winnerIndex].handicap)
        if (veikkausBet.draw.rows[0].competitors[winnerIndex].name.includes('Yli')){
          bet = "Over"
        }
        else if (veikkausBet.draw.rows[0].competitors[winnerIndex].name.includes('Alle')){
          bet = "Under"
        }

        break;

      case "AWAY_OVER_UNDER":
        betType = away + " Total"
        handicap = parseFloat(veikkausBet.draw.rows[0].competitors[winnerIndex].handicap)
        if (veikkausBet.draw.rows[0].competitors[winnerIndex].name.includes('Yli')){
          bet = "Over"
        }
        else if (veikkausBet.draw.rows[0].competitors[winnerIndex].name.includes('Alle')){
          bet = "Under"
        }

        break;
      case "HOME_CLEAN_SHEET":
        betType = away + " Total"
        handicap = 0.5
        bet = "Under"
        break;
      case "AWAY_CLEAN_SHEET":
        betType = home + " Total"
        handicap = 0.5
        bet = "Under"
        break;
      //case "CORRECT_SCORE":
        //return "Tulos"
        //break;
      case "OUTRIGHT_SHORT_TERM":
        betType = "Voittaja"
        bet = veikkausBet.draw.rows[0].competitors[winnerIndex].name
        break;
      /*
      case "BOTH_TEAMS_TO_SCORE":
        
        break;
      case "HALFTIME_FULLTIME":
      
        break;*/
      case "ANYTIME_GOALSCORER":
        betType = "Maalintekijä"
        bet = veikkausBet.draw.rows[0].competitors[winnerIndex].name
        break;
      /*case "SET_HANDICAP":
      
        break;
      case "TRIPLEHEAD":
        
        break;*/
      case "SET_1_WINNER":
        betType = "1. erä"
        if (splittedTeams === 2){
          home = splittedTeams[0]
          away = splittedTeams[1]
        }

        bet = veikkausBet.draw.rows[0].competitors[winnerIndex].name

        break;

      case "SET_2_WINNER":
        betType = "2. erä"
        if (splittedTeams === 2){
          home = splittedTeams[0]
          away = splittedTeams[1]
        }

        bet = veikkausBet.draw.rows[0].competitors[winnerIndex].name

        break;

      case "SET_3_WINNER":
        betType = "3. erä"
        if (splittedTeams === 2){
          home = splittedTeams[0]
          away = splittedTeams[1]
        }

        bet = veikkausBet.draw.rows[0].competitors[winnerIndex].name

        break;

      case "THREE_BALL":
        betType = "Ryhmän paras"
        bet = veikkausBet.draw.rows[0].competitors[winnerIndex].name
        switch (winnerIndex) {
          case 0:
            home = veikkausBet.draw.rows[0].competitors[1].name
            away = veikkausBet.draw.rows[0].competitors[2].name
            break;
          case 1:
            home = veikkausBet.draw.rows[0].competitors[0].name
            away = veikkausBet.draw.rows[0].competitors[2].name
            break;
          case 2:
            home = veikkausBet.draw.rows[0].competitors[0].name
            away = veikkausBet.draw.rows[0].competitors[1].name
            break;
          default:
            break;
        }
        break;
      case "HEAD2HEAD":
        betType = "H2H"
        bet = veikkausBet.draw.rows[0].competitors[winnerIndex].name
        break;
      /*case "DOUBLE_RESULT":
        
        break;
      case "DOUBLE_CHANCE":
      
        break;
      
      case "TOTAL_GOALS":
      
        break;
      case "GOALS_AWAY_TEAM":
      
        break;
      case "GOALS_HOME_TEAM":
      
        break;
      case "HALFTIME_1X2":
    
        break;
      case "MATCHBET_AND_TOTALS":
  
        break;
      */
      default:
        
        break;
    }

    return {
      home: home,
      away: away,
      betType: betType,
      handicap: handicap,
      bet: bet
    }

  } catch (error) {
      console.log(error)
      return {
        home: home,
        away: away,
        betType: betType,
        handicap: handicap,
        bet: bet
      }
  }
}

const getVeikkausTeams = event => {
  if (event.teams.length === 2){
    if (event.name.includes(event.teams[0].name + ' - ')){
      return{
        home: event.teams[0].name,
        away: event.teams[1].name
      }
    }
    else {
      return{
        home: event.teams[1].name,
        away: event.teams[0].name
      }
    }
  }
  else {
    return{
      home: '',
      away: ''
    }
  }
}

export {
  getPinnacleBetDetails,
  getBetfairBetDetails,
  getVeikkausBetDetails
}

/*
Veikkaus bet types:
'1X2'
'12'
'OVER_UNDER'
'CORRECT_SCORE'
'OUTRIGHT_SHORT_TERM'
'HOME_HANDICAP'
'AWAY_HANDICAP'
'BOTH_TEAMS_TO_SCORE'
'HALFTIME_FULLTIME'
'ANYTIME_GOALSCORER'
'SET_HANDICAP'
'TRIPLEHEAD'
'HEAD2HEAD'
'DOUBLE_RESULT'
'DOUBLE_CHANCE'
'HOME_OVER_UNDER'
'AWAY_OVER_UNDER'
'TOTAL_GOALS'
'GOALS_AWAY_TEAM'
'GOALS_HOME_TEAM'
'HALFTIME_1X2'
'MATCHBET_AND_TOTALS'
'HOME_CLEAN_SHEET'
'AWAY_CLEAN_SHEET'

'SET_1_GAME_HANDICAP'
'SET_1_TOTAL'
'SET_1_CORRECT_SCORE'
'SET_1_WINNER'
'SET_2_WINNER'
'TOTAL_NUMBER_OF_GAMES'
'TOTAL_NUMBER_OF_SETS_3'
'CORRECT_SET_SCORE_3'
'FIRST_QUARTER_1X2'
'FIRST_QUARTER_TOTAL',
'FIRST_GOALSCORER'
'FIRST_GOAL'
'TOTAL_MARGINS_REGULAR'
'TOTAL_MARGINS_NBA'
'WINNING_MARGINS'
'ODD_OR_EVEN_GOALS'
'P1_1X2_P2_1X2_P3_1X2'
'HALFTIME_HOME_OVER_UNDER'
'PENALTY_SHOOT_OUT_YES_NO'
'HALFTIME_AWAY_OVER_UNDER'
'FIRST_GOAL_INTERVAL_10_MIN'
'THREE_BALL'
*/