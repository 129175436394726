const pinnacleIds = {
  BANDY_SPORT_ID: 2,

  BASEBALL_SPORT_ID: 3,
  MLB_LEAGUE_ID: 246,

  BASKETBALL_SPORT_ID: 4,
  FIBA_EUROBASKET_LEAGUE_ID: 375,
  FIBA_WC_LEAGUE_ID: 397,
  KORISLIIGA_LEAGUE_ID: 408,
  NBA_LEAGUE_ID: 487,

  DARTS_SPORT_ID: 10,
  ESPORTS_SPORT_ID: 12,

  FLOORBALL_SPORT_ID: 14,
  SUOMEN_CUP_LEAGUE_ID: 829,

  AMERICAN_FOOTBALL_SPORT_ID: 15,
  NFL_LEAGUE_ID: 889,
  NFL_ALTERNATE_LINES_LEAGUE_ID: 200920,
  NFL_PRESEASON_LEAGUE_ID: 4347,
  NFL_SUPER_BOWL_PROPS_LEAGUE_ID: 203936,

  FUTSAL_SPORT_ID: 16,
  GOLF_SPORT_ID: 17,
  HANDBALL_SPORT_ID: 18,

  ICE_HOCKEY_SPORT_ID: 19,
  CZECH_EXTRA_LIGA_LEAGUE_ID: 1297,
  METAL_LIGAEN_LEAGUE_ID: 1316,
  CHL_LEAGUE_ID: 1325,
  SM_LIIGA_LEAGUE_ID: 1401,
  DEL_LEAGUE_ID: 1419,
  NHL_LEAGUE_ID: 1456,
  OLYMPICS_HOCKEY_LEAGUE_ID: 1470,
  KHL_LEAGUE_ID: 1484,
  SLOVAKIA_EXTRA_LIGA_LEAGUE_ID: 1498,
  HOCKEY_ALLSVENSKAN_LEAGUE_ID: 1512,
  SHL_LEAGUE_ID: 1517,
  NLA_LEAGUE_ID: 1532,
  IHHF_U20_LEAGUE_ID: 1586,
  IHHF_WC_LEAGUE_ID: 1599,
  IHHF_WC_OT_LEAGUE_ID: 1600,
  INTERNATIONAL_LEAGUE_ID: 1603,
  MESTIS_LEAGUE_ID: 4529,
  EURO_HOCKEY_TOUR_LEAGUE_ID: 4605,
  VHL_LEAGUE_ID: 5154,
  MHL_LEAGUE_ID: 149037,
  SWISS_LEAGUE_ID: 206707,
  NHL_ALTERNATE_LINES_LEAGUE_ID: 211417,

  MIXED_MARTIAL_ARTS_SPORT_ID: 22,
  UCF_LEAGUE_ID: 1624,

  SNOOKER_SPORT_ID: 28,
  
  SOCCER_SPORT_ID: 29,
  SUOMEN_CUP_LEAGUE_ID: 2020,
  SUOMEN_CUP_CORNERS_LEAGUE_ID: 197053,
  KAKKONEN_LEAGUE_ID: 2018,
  KAKKONEN_CORNERS_LEAGUE_ID: 201673,
  KAKKOSEN_CUP_LEAGUE_ID: 203922,
  KANSALLINEN_LIIGA_LEAGUE_ID: 197235,
  KOLMONEN_LEAGUE_ID: 201512,
  LIIGA_CUP_LEAGUE_ID: 4993,
  LIIGA_CUP_CORNERS_LEAGUE_ID: 192724,
  FINLAND_U20_LEAGUE_ID: 197320,
  VEIKKAUSLIIGA_LEAGUE_ID: 2024,
  VEIKKAUSLIIGA_CORNERS_LEAGUE_ID: 6385,
  YKKONEN_LEAGUE_ID: 2025,
  YKKONEN_CORNERS_LEAGUE_ID: 196769,
  ENGLAND_CHAMPIONSHIP_LEAGUE_ID: 1977,
  ENGLAND_FA_CUP_LEAGUE_ID: 1979,
  ENGLAND_LEAGUE_1_LEAGUE_ID: 1957,
  ENGLAND_LEAGUE_2_LEAGUE_ID: 1958,
  ENGLAND_LEAGUE_CUP_LEAGUE_ID: 1982,
  ENGLAND_PREMIER_LEAGUE_ID: 1980,
  LIGUE_1_LEAGUE_ID: 2036,
  SERIE_A_LEAGUE_ID: 2436,
  COPPA_ITALIA_LEAGUE_ID: 2147,
  LA_LIGA_LEAGUE_ID: 2196,
  SEQUNDA_DIVISION_LEAGUE_ID: 2432,
  SPAIN_CUP_LEAGUE_ID: 2462,
  ALLSVENSKAN_LEAGUE_ID: 1728,
  UEFA_CHAMPIONS_LEAGUE_ID: 2627,
  UEFA_EUROPA_LEAGUE_ID: 2630,

  AFC_LEAGUE_ID: 1708,
  IRAN_AZADEGAN_LEAGUE_ID: 209653,
  IRAN_CUP_LEAGUE_ID: 10346,
  IRAN_PRO_LEAGUE_ID: 2118,
  QATAR_STARS_LEAGUE_ID: 8128,
  QATAR_STARS_CUP_LEAGUE_ID: 10901,
  SAUDI_ARABIA_PRO_LEAGUE_ID: 10419,
  UAE_LEAGUE_ID: 8126,
  UAE_CUP_LEAGUE_ID: 2624,

  TABLE_TENNIS_SPORT_ID: 32,

  TENNIS_SPORT_ID: 33,
  ATP_AUSTRALIAN_OPEN_LEAGUE_ID: 198250,
  ATP_AUSTRALIAN_OPEN_QUALIFIERS_LEAGUE_ID: 3256,
  ATP_AUSTRALIAN_OPEN_R1_LEAGUE_ID: 3258,
  ATP_AUSTRALIAN_OPEN_R2_LEAGUE_ID: 3260,
  ATP_AUSTRALIAN_OPEN_R3_LEAGUE_ID: 3261,
  ATP_AUSTRALIAN_OPEN_R16_LEAGUE_ID: 3259,
  ATP_AUSTRALIAN_OPEN_QF_LEAGUE_ID: 3257,
  ATP_AUSTRALIAN_OPEN_SF_LEAGUE_ID: 3262,
  ATP_AUSTRALIAN_OPEN_FINAL_LEAGUE_ID: 3255,
  ATP_FRENCH_OPEN_LEAGUE_ID: 198469,
  ATP_FRENCH_OPEN_QUALIFIERS_LEAGUE_ID: 5619,
  ATP_FRENCH_OPEN_R1_LEAGUE_ID: 3283,
  ATP_FRENCH_OPEN_R2_LEAGUE_ID: 3285,
  ATP_FRENCH_OPEN_R3_LEAGUE_ID: 3286,
  ATP_FRENCH_OPEN_R16_LEAGUE_ID: 3284,
  ATP_FRENCH_OPEN_QF_LEAGUE_ID: 3282,
  ATP_FRENCH_OPEN_SF_LEAGUE_ID: 3287,
  ATP_FRENCH_OPEN_FINAL_LEAGUE_ID: 3280,
  ATP_WIMBLEDON_OPEN_LEAGUE_ID: 196976,
  ATP_WIMBLEDON_OPEN_QUALIFIERS_LEAGUE_ID: 3338,
  ATP_WIMBLEDON_OPEN_R1_LEAGUE_ID: 3333,
  ATP_WIMBLEDON_OPEN_R2_LEAGUE_ID: 3335,
  ATP_WIMBLEDON_OPEN_R3_LEAGUE_ID: 3336,
  ATP_WIMBLEDON_OPEN_R16_LEAGUE_ID: 3334,
  ATP_WIMBLEDON_OPEN_QF_LEAGUE_ID: 3332,
  ATP_WIMBLEDON_OPEN_SF_LEAGUE_ID: 3337,
  ATP_WIMBLEDON_OPEN_FINAL_LEAGUE_ID: 3330,
  ATP_US_OPEN_LEAGUE_ID: 197237,
  ATP_US_OPEN_QUALIFIERS_LEAGUE_ID: 3449,
  ATP_US_OPEN_R1_LEAGUE_ID: 3451,
  ATP_US_OPEN_R2_LEAGUE_ID: 3453,
  ATP_US_OPEN_R3_LEAGUE_ID: 3454,
  ATP_US_OPEN_R16_LEAGUE_ID: 3450,
  ATP_US_OPEN_QF_LEAGUE_ID: 3448,
  ATP_US_OPEN_SF_LEAGUE_ID: 3455,
  ATP_US_OPEN_FINAL_LEAGUE_ID: 3447,

  VOLLEYBALL_SPORT_ID: 34,
  MESTARUUSLIIGA_MEN_LEAGUE_ID: 4107,
  MESTARUUSLIIGA_WOMEN_LEAGUE_ID: 4108,
  FIVB_WC_LEAGUE_ID: 4493,
  VOLLEYBALL_FINLAND_CUP_LEAGUE_ID: 211767,

  BIATHLON_SPORT_ID: 41,
  SKI_JUMPING_SPORT_ID: 42,
  CROSS_COUNTRY_SPORT_ID: 43,
  FORMULA1_SPORT_ID: 44,
  CYCLING_SPORT_ID: 45,
  NORDIC_COMBINED_SPORT_ID: 50,
  OLYMPICS_SPORT_ID: 55
}

export {
  pinnacleIds
}