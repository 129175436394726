import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import  * as Datetime from 'react-datetime';
import moment from 'moment';
import PropTypes from 'prop-types';
import C from "../../constants";
import '../../../node_modules/react-datetime/css/react-datetime.css';
import axios from "axios";
import {Link} from "react-router-dom";

const Betfair = (props) => {
    const endPoints = [
        {value:'listEventTypes', label:'listEventTypes'},
        {value:'listCompetitions', label:'listCompetitions'},
        {value:'listTimeRanges', label:'listTimeRanges'},
        {value:'listEvents', label:'listEvents'},
        {value:'listMarketTypes', label:'listMarketTypes'},
        {value:'listCountries', label:'listCountries'},
        {value:'listVenues', label:'listVenues'},
        {value:'listMarketCatalogue', label:'listMarketCatalogue'},
        {value:'listMarketBook', label:'listMarketBook'},
        {value:'listRunnerBook', label:'listRunnerBook'},
        {value:'placeOrders', label:'placeOrders'},
        {value:'cancelOrders', label:'cancelOrders'},
        {value:'updateOrders', label:'updateOrders'},
        {value:'replaceOrders', label:'replaceOrders'},
        {value:'listCurrentOrders', label:'listCurrentOrders'},
        {value:'listClearedOrders', label:'listClearedOrders'},
        {value:'listMarketProfitAndLoss', label:'listMarketProfitAndLoss'}
    ];

    const [availableEventTypes, setAvailableEventTypes] = useState([]);
    const [selectedEventTypeIds, setSelectedEventTypeIds] = useState([]);
    const [availableCompetitions, setAvailableCompetitions] = useState([]);
    const [selectedCompetitionIds, setSelectedCompetitionIds] = useState([]);
    const [availableEvents, setAvailableEvents] = useState([]);
    const [selectedEventIds, setSelectedEventIds] = useState([]);
    const [availableMarkets, setAvailableMarkets] = useState([]);
    const [selectedMarketIds, setSelectedMarketIds] = useState([]);
    const [marketBook, setMarketBook] = useState('');


    const [results, setResults] = useState([]);

    useEffect(() => {
        getBetfair('listEventTypes');
    }, []);

    useEffect(() => {
        if (selectedEventTypeIds.length > 0)
        {
            getBetfair('listCompetitions');
        }
    }, [selectedEventTypeIds]);

    useEffect(() => {
        if (selectedCompetitionIds.length > 0)
        {
            getBetfair('listEvents');
        }
    }, [selectedCompetitionIds]);

    useEffect(() => {
        if (selectedEventIds.length > 0)
        {
            getBetfair('listMarketCatalogue');
        }
    }, [selectedEventIds]);

    useEffect(() => {
        if (selectedMarketIds.length > 0)
        {
            getBetfair('listMarketBook');
        }
    }, [selectedMarketIds]);

    const getBetfair = async (endpoint) => {
        if (props.token !== undefined && props.token !== null)
        {
            let params = {endpoint: endpoint, jsonRequest: createJsonRequest(endpoint)}
            const headers = {
                'x-access-token': props.token
            }

            axios({
                method: 'POST',
                url: C.BETFAIR_BASE_URL + '/betfair',
                headers: headers,
                params: params
            }).then(response => {
                handleResults(endpoint, response.data);
                })
                .catch(function (error){
                    console.log(error);
                });
        }
    };

    const createJsonRequest = (endpoint) => {
        let result = {};
        let filters = {};

        switch(endpoint) {
            case 'listEventTypes':
                result = {filter: filters};
                break;
            case 'listCompetitions':
                if (selectedEventTypeIds.length > 0)
                {
                    filters['eventTypeIds'] = selectedEventTypeIds;
                }

                result = {filter: filters};
                break;
            case 'listEvents':
                if (selectedCompetitionIds.length > 0)
                {
                    filters['competitionIds'] = selectedCompetitionIds;
                }
                else
                {
                    filters['marketStartTime'] = {from: "2020-01-25T00:00:00Z", to: "2020-01-26T00:00:00Z"};
                }

                result = {filter: filters};
                break;
            case 'listMarketCatalogue':
                if (selectedEventIds.length > 0)
                {
                    filters['eventIds'] = selectedEventIds;
                }

                result = {filter: filters};
                result['maxResults'] = 200;
                break;
            case 'listMarketBook':
                if (selectedMarketIds.length > 0)
                {
                    result['marketIds'] = selectedMarketIds;
                    result['priceProjection'] = {"priceData": ["EX_BEST_OFFERS", "EX_TRADED"]};
                }
                break;
            default:
                break;
        }

        return JSON.stringify(result);
    };

    const handleEventTypeClick = (event, id) => {
        if (selectedEventTypeIds.includes(id))
        {
            console.log('Remove eventTypeId', id);
            setSelectedEventTypeIds(selectedEventTypeIds.filter((e)=>(e !== id)));
        }
        else
        {
            console.log('Add eventTypeId', id);
            setSelectedEventTypeIds(selectedEventTypeIds => [...selectedEventTypeIds, id]);
        }
    };

    const handleCompetitionClick = (event, id) => {
        if (selectedCompetitionIds.includes(id))
        {
            console.log('Remove competitionId', id);
            setSelectedCompetitionIds(selectedCompetitionIds.filter((e)=>(e !== id)));
        }
        else
        {
            console.log('Add competitionId', id);
            setSelectedCompetitionIds(selectedCompetitionIds => [...selectedCompetitionIds, id]);
        }
    };

    const handleEventClick = (event, id) => {
        if (selectedEventIds.includes(id))
        {
            console.log('Remove eventId', id);
            setSelectedEventIds(selectedEventIds.filter((e)=>(e !== id)));
        }
        else
        {
            console.log('Add eventId', id);
            setSelectedEventIds(selectedEventIds => [...selectedEventIds, id]);
        }
    };

    const handleMarketClick = (event, id) => {
        if (selectedMarketIds.includes(id))
        {
            console.log('Remove marketId', id);
            setSelectedMarketIds(selectedMarketIds.filter((e)=>(e !== id)));
        }
        else
        {
            console.log('Add marketId', id);
            setSelectedMarketIds(selectedMarketIds => [...selectedMarketIds, id]);
        }
    };

    const eventTypeButtons = () => {
        return(
            availableEventTypes.map((eventType) => (
                <button type="button" name={eventType.eventType.id}
                        className = {selectedEventTypeIds.includes(eventType.eventType.id) ? "btn btn-primary" : "btn btn-info"}
                        onClick={(e) => handleEventTypeClick(e, eventType.eventType.id)}
                >{eventType.eventType.name}</button>
            )))
    };

    const competitionButtons = () => {
        return(
            availableCompetitions.map((competition) => (
                <button type="button" name={competition.competition.id}
                        className = {selectedCompetitionIds.includes(competition.competition.id) ? "btn btn-primary" : "btn btn-info"}
                        onClick={(e) => handleCompetitionClick(e, competition.competition.id)}
                >{competition.competition.name}</button>
            )))
    };

    const eventButtons = () => {
        return(
            availableEvents.map((event) => (
                <button type="button" name={event.event.id}
                        className = {selectedEventIds.includes(event.event.id) ? "btn btn-primary" : "btn btn-info"}
                        onClick={(e) => handleEventClick(e, event.event.id)}
                >{event.event.name}</button>
            )))
    };

    const marketButtons = () => {
        return(
            availableMarkets.map((market) => (
                <button type="button" name={market.marketId}
                        className = {selectedMarketIds.includes(market.marketId) ? "btn btn-primary" : "btn btn-info"}
                        onClick={(e) => handleMarketClick(e, market.marketId)}
                >{market.marketName}</button>
            )))
    };

    const odds = () => {
        let oddsRow = [];
        let normalizer = 0;
        let row1 = '';
        let row2 = '';

        if (marketBook.length)
        {
            for (let i = 0; i < marketBook.length; i++)
            {
                for (let j = 0; j < marketBook[i].runners.length; j++)
                {
                    row1 += marketBook[i].runners[j].lastPriceTraded + ' ';
                    oddsRow.push(marketBook[i].runners[j].lastPriceTraded);
                    normalizer += 1 /marketBook[i].runners[j].lastPriceTraded;
                }
            }

            for (let i = 0; i < oddsRow.length; i++)
            {
                row2 += Math.round(100 * ((normalizer) / oddsRow[i]) * 100) / 100 + '% ';
            }
        }

        return(
            <React.Fragment>
                <label>{row1}</label><br/>
                <label>{row2}</label>
            </React.Fragment>
        )
    };

    const getAllEvents = () => {
        if (selectedEventTypeIds.length > 0)
        {
            getBetfair('listEvents');
        }
    };

    const handleResults = (endpoint, response) => {
        console.log('handleResults', endpoint, response);
        switch(endpoint) {
            case 'listEventTypes':
                let filteredEventTypes = [];

                const allowedEvents = ['Soccer', 'Tennis', 'Golf', 'Volleyball', 'Snooker', 'American Football', 'Baseball', 'Basketball', 'Ice Hockey', 'Darts'];

                for (let i = 0; i < response.length; i++)
                {
                    if (allowedEvents.includes(response[i].eventType.name))
                    {
                        filteredEventTypes.push(response[i]);
                    }
                }

                setAvailableEventTypes(filteredEventTypes);
                console.log('response', filteredEventTypes);
                break;
            case 'listCompetitions':
                setAvailableCompetitions(response);
                break;
            case 'listEvents':
                setAvailableEvents(response);
                break;
            case 'listMarketCatalogue':
                setAvailableMarkets(response);
                break;
            case 'listMarketBook':
                setMarketBook(response);
                break;
            default:
                setResults(response);
                break;
        }
    };

    return (
        <React.Fragment>
            <br/>
            { eventTypeButtons() }
            <br/>
            { competitionButtons() }
            <br/>
            { eventButtons() }
            <br/>
            { marketButtons() }
            <br/>
            { odds() }
            <pre>{ JSON.stringify(results, null, 2) }</pre>
            <br/>
            <button className="btn btn-primary" onClick={getAllEvents}>Hae kaikki pelit</button>
            <br/>
        </React.Fragment>
    );
};

export default Betfair;