import React from 'react';

import classes from './Toolbar.css';
import Logo from '../../Logo/Logo';
import NavigationItems from '../NavigationItems/NavigationItems';

const toolbar = ( props ) => (
    <header className={classes.Toolbar}>
        <div className={classes.Logo}>
            <Logo />
        </div>
        <nav>
            <NavigationItems isAuthenticated={props.isAuth} isAdvanced={props.isAdvanced} />
        </nav>
    </header>
);

export default toolbar