import C from "../constants";

const names = () => {
    return ({'teams': [...convertToFinalList(premierLeagueTeamsList),
            ...convertToFinalList(championshipTeamsList),
            ...convertToFinalList(scottishTeamsList),
            ...convertToFinalList(laLigaTeamsList),
            ...convertToFinalList(serieATeamsList),
            ...convertToFinalList(bundesligaTeamsList),
            ...convertToFinalList(bundesliga2TeamsList),
            ...convertToFinalList(ligue1TeamsList),
            ...convertToFinalList(veikkausLiigaTeamsList),
            ...convertToFinalList(ykkonenTeamsList),
            ...convertToFinalList(kakkonenTeamsList),
            ...convertToFinalList(kolmonenTeamsList),
            ...convertToFinalList(nuortenSMJalkapalloTeamsList),
            ...convertToFinalList(naistenLiigaTeamsList),
            ...convertToFinalList(allsvenskanFootballTeamsList),
            ...convertToFinalList(superettanTeamsList),
            ...convertToFinalList(swedenDivision1TeamsList),
            ...convertToFinalList(swedenDivision2TeamsList),
            ...convertToFinalList(danishSuperligaTeamsList),
            ...convertToFinalList(mlsTeamsList),
            ...convertToFinalList(belgianFirstDivisionATeamsList),
            ...convertToFinalList(belgianFirstDivisionBTeamsList),
            ...convertToFinalList(eredivisieTeamsList),
            ...convertToFinalList(eersteDivisieTeamsList),
            ...convertToFinalList(brazilSerieATeamsList),
            ...convertToFinalList(ecuadorLigaProTeamsList),
            ...convertToFinalList(paraguayPrimeraDivisionTeamsList),
            ...convertToFinalList(nhlTeamsList),
            ...convertToFinalList(khlTeamsList),
            ...convertToFinalList(liigaTeamsList),
            ...convertToFinalList(mestisTeamsList),
            ...convertToFinalList(shlTeamsList),
            ...convertToFinalList(allsvenskanIceHockeyTeamsList),
            ...convertToFinalList(mlbTeamsList),
            ...convertToFinalList(nbaTeamsList),
            ...convertToFinalList(nflTeamsList),
            ...convertToFinalList(tennisList),
            ...convertToFinalList(golfList),
            ...convertToFinalList(countriesList),
        ],
        'footballTeams': [...convertToFinalList(premierLeagueTeamsList),
            ...convertToFinalList(championshipTeamsList),
            ...convertToFinalList(scottishTeamsList),
            ...convertToFinalList(laLigaTeamsList),
            ...convertToFinalList(serieATeamsList),
            ...convertToFinalList(bundesligaTeamsList),
            ...convertToFinalList(bundesliga2TeamsList),
            ...convertToFinalList(ligue1TeamsList),
            ...convertToFinalList(veikkausLiigaTeamsList),
            ...convertToFinalList(ykkonenTeamsList),
            ...convertToFinalList(kakkonenTeamsList),
            ...convertToFinalList(kolmonenTeamsList),
            ...convertToFinalList(nuortenSMJalkapalloTeamsList),
            ...convertToFinalList(naistenLiigaTeamsList),
            ...convertToFinalList(allsvenskanFootballTeamsList),
            ...convertToFinalList(superettanTeamsList),
            ...convertToFinalList(swedenDivision1TeamsList),
            ...convertToFinalList(swedenDivision2TeamsList),
            ...convertToFinalList(danishSuperligaTeamsList),
            ...convertToFinalList(mlsTeamsList),
            ...convertToFinalList(belgianFirstDivisionATeamsList),
            ...convertToFinalList(belgianFirstDivisionBTeamsList),
            ...convertToFinalList(eredivisieTeamsList),
            ...convertToFinalList(eersteDivisieTeamsList),
            ...convertToFinalList(brazilSerieATeamsList),
            ...convertToFinalList(ecuadorLigaProTeamsList),
            ...convertToFinalList(paraguayPrimeraDivisionTeamsList),
            ...convertToFinalList(countriesList),
        ],
        'iceHockeyTeams': [...convertToFinalList(nhlTeamsList),
            ...convertToFinalList(khlTeamsList),
            ...convertToFinalList(liigaTeamsList),
            ...convertToFinalList(mestisTeamsList),
            ...convertToFinalList(shlTeamsList),
            ...convertToFinalList(allsvenskanIceHockeyTeamsList),
            ...convertToFinalList(countriesList),
        ],
        'baseBallTeams': [...convertToFinalList(mlbTeamsList)],
        'basketBallTeams': [...convertToFinalList(nbaTeamsList), ...convertToFinalList(countriesList)],
        'volleyBallTeams': [...convertToFinalList(countriesList)],
        'americanFootballTeams': [...convertToFinalList(nflTeamsList)],
        'tennisPlayers': [...convertToFinalList(tennisList)],
        'golfPlayers': [...convertToFinalList(golfList)],
        'sports': [...convertToFinalList(sportList)],
        'series': [...convertToFinalList(footballSeriesList),
            ...convertToFinalList(iceHockeySeriesList),
            ...convertToFinalList(baseBallSeriesList),
            ...convertToFinalList(basketBallSeriesList),
            ...convertToFinalList(americanFootballSeriesList)
        ],
        'footballSeries': [...convertToFinalList(footballSeriesList)],
        'iceHockeySeries': [...convertToFinalList(iceHockeySeriesList)],
        'golfCompetitions': [...convertToFinalList(golfCompetitionsList)],
        'baseballSeries': [...convertToFinalList(baseBallSeriesList)],
        'basketBallSeries': [...convertToFinalList(basketBallSeriesList)],
        'volleyBallSeries': [...convertToFinalList(volleyBallSeriesList)],
        'americanFootballSeries': [...convertToFinalList(americanFootballSeriesList)],
        'trottingSeries': [...convertToFinalList(trottingSeriesList)],
        'esportsSeries': [...convertToFinalList(esportsSeriesList)],
        'gameFormats': [...convertToFinalList(gameFormatList)],
        'sides': [...convertToFinalList(sideList)],
        'totals': [...convertToFinalList(totalList)],
        'scores': [...convertToFinalList(scoreList)],
        'h2hs': [...convertToFinalList(h2hList)],
        'corners': [...convertToFinalList(sideList), ...convertToFinalList(totalList)],
        'bookers': [...convertToFinalList(bookers)],
        'results': resultList})
};

const convertToFinalList = (list) => {
    let finalList = [];
    for (let i = 0; i < list.length; i++) {
        finalList.push({ value: list[i], label: list[i] });
    }

    return finalList;
};

const sportList = [
    'Jalkapallo',
    'Jääkiekko',
    'Tennis',
    'Baseball',
    'Koripallo',
    'Amerikkalainen jalkapallo',
    'Esports',
    'Ravit',
    'Lentopallo',
    'Golf',
    'Snooker',
    'Salibandy',
    'Jääpallo',
    'Käsipallo',
    'Darts',
    'Futsal',
    'Pesäpallo',
    'Pyöräily',
    'Yleisurheilu'
];

const footballSeriesList = [
    'Maaottelu',
    'Valioliiga',
    'Championship',
    'La Liga',
    'Serie A',
    'Bundesliiga',
    'Bundesliiga 2',
    'Mestarien liiga',
    'Eurooppa liiga',
    'Veikkausliiga',
    'Ykkönen',
    'Kakkonen',
    'Kolmonen',
    'Nuorten SM jalkapallo',
    'Naisten liiga',
    'Allsvenskan jalkapallo',
    'Superettan',
    'Sweden Division 1',
    'Sweden Division 2',
    'Sweden Division 3',
    'MLS',
    'AFC Champions League',
    'Qatar Stars League',
    'UAE League',
    'Persian Gulf League',
    'Belgian First Division A',
    'Belgian First Division B',
    'Eredivisie',
    'Eerste Divisie',
    'Brazil Serie A',
    'Ecuador Liga Pro',
    'Paraguay Primera Division',
    'Harjoituspeli'
];

const iceHockeySeriesList = [
    'NHL',
    'KHL',
    'Liiga',
    'Mestis',
    'SHL',
    'Allsvenskan jääkiekko',
    'VHL',
    'MHL',
    'DEL',
    'Maaottelu',
    'Harjoituspeli'
];

const golfCompetitionsList = [
    'Safeway Open',
    'CIMB Classic',
    'CJ Cup',
    'WGC-HSBC Champions',
    'Sanderson Farms Championship',
    'Shriners Hospitals for Children Open',
    'Mayakoba Golf Classic',
    'RSM Classic',
    'Sentry Tournament of Champions',
    'Sony Open in Hawaii',
    'Desert Classic',
    'Farmers Insurance Open',
    'Waste Management Phoenix Open',
    'AT&T Pebble Beach Pro-Am',
    'Genesis Open',
    'WGC-Mexico Championship',
    'Puerto Rico Open',
    'The Honda Classic',
    'Arnold Palmer Invitational',
    'The Players Championship',
    'Valspar Championship',
    'WGC-Dell Technologies Match Play',
    'Corales Puntacana Resort and Club Championship',
    'Valero Texas Open',
    'Masters Tournament',
    'RBC Heritage',
    'Zurich Classic of New Orleans',
    'Wells Fargo Championship',
    'AT&T Byron Nelson',
    'PGA Championship',
    'Charles Schwab Challenge',
    'The Memorial Tournament',
    'RBC Canadian Open',
    'U.S. Open',
    'Travelers Championship',
    'Rocket Mortgage Classic',
    '3M Open',
    'John Deere Classic',
    'The Open Championship',
    'Barbasol Championship',
    'WGC-FedEx St. Jude Invitational',
    'Barracuda Championship',
    'Wyndham Championship',
    'The Northern Trust',
    'BMW Championship',
    'Tour Championship'
];

const baseBallSeriesList = [
    'MLB',
    'Harjoituspeli'
];

const basketBallSeriesList = [
    'NBA',
    'Korisliiga',
    'Maaottelu',
    'Harjoituspeli'
];

const volleyBallSeriesList = [
    'Maaottelu',
    'Mestaruusliiga'
];

const americanFootballSeriesList = [
    'NFL',
    'NCAA',
    'Harjoituspeli'
];

const trottingSeriesList = [
    'Lähtö 1',
    'Lähtö 2',
    'Lähtö 3',
    'Lähtö 4',
    'Lähtö 5',
    'Lähtö 6',
    'Lähtö 7',
    'Lähtö 8',
    'Lähtö 9',
    'Lähtö 10',
    'Lähtö 11',
    'Lähtö 12',
    'Lähtö 13',
    'Lähtö 14',
    'Lähtö 15',
];

const esportsSeriesList = [
    'CS:GO',
    'League of Legends',
    'Dota 2',
    'PUBG',
    'Rainbow Six',
    'StarCraft'
];

const gameFormatList = [
    'Side',
    'Total',
    'Voittaja',
    'Tulos',
    'H2H',
    'Puoliaika/lopputulos',
    'Kulmapotkut',
    'Multi',
    'Tekee pisteen',
    'Tekee maalin',
    'Tekee ensimmäisen maalin',
    'Tekee viimeisen maalin',
    'Tekee 2 maalia',
    'Tekee 3 maalia',
    'Laukaukset'
];

const sideList = [
    '1 ML',
    '2 ML',
    '1 -0.5',
    '2 -0.5',
    '1 +0.5',
    '2 +0.5',
    'X',
    '1 +0',
    '2 +0',
    '1 -1.5',
    '2 -1.5',
    '1 +1.5',
    '2 +1.5',
    '1 -2.5',
    '2 -2.5',
    '1 +2.5',
    '2 +2.5',
    '1 -0.25',
    '2 -0.25',
    '1 +0.25',
    '2 +0.25',
    '1 -0.75',
    '2 -0.75',
    '1 +0.75',
    '2 +0.75',
    '1 -1',
    '2 -1',
    '1 +1',
    '2 +1',
    '1 -1.25',
    '2 -1.25',
    '1 +1.25',
    '2 +1.25',
    '1 -1.75',
    '2 -1.75',
    '1 +1.75',
    '2 +1.75',
    '1 -2',
    '2 -2',
    '1 +2',
    '2 +2',
    '1 -2.25',
    '2 -2.25',
    '1 +2.25',
    '2 +2.25',
    '1 -2.75',
    '2 -2.75',
    '1 +2.75',
    '2 +2.75',
    '1 -3',
    '2 -3',
    '1 +3',
    '2 +3',
    '1 -3.25',
    '2 -3.25',
    '1 +3.25',
    '2 +3.25',
    '1 -3.75',
    '2 -3.75',
    '1 +3.75',
    '2 +3.75',
    '1 -4',
    '2 -4',
    '1 +4',
    '2 +4'
];

const totalList = [
    'BTTS',
    'U2.5',
    'O2.5',
    'U5.5',
    'O5.5',
    'U0.5',
    'O0.5',
    'U0.75',
    'O0.75',
    'U1.0',
    'O1.0',
    'U1.5',
    'O1.5',
    'U2.0',
    'O2.0',
    'U3.0',
    'O3.0',
    'U3.5',
    'O3.5',
    'U4.0',
    'O4.0',
    'U4.5',
    'O4.5',
    'U5.0',
    'O5.0',
    'U6.0',
    'O6.0',
    'U6.5',
    'O6.5',
    'U7.0',
    'O7.0',
    'U7.5',
    'O7.5',
    'U8.0',
    'O8.0',
    'U8.5',
    'O8.5',
    'U9.0',
    'O9.0',
    'U9.5',
    'O9.5',
    'U10.0',
    'O10.0',
    'U10.5',
    'O10.5',
    'U11.0',
    'O11.0',
    'U11.5',
    'O11.5',
    'U12.0',
    'O12.0',
    'U12.5',
    'O12.5',
    'U13.0',
    'O13.0',
    'U13.5',
    'O13.5',
    'U14.0',
    'O14.0',
    'U14.5',
    'O14.5',
    'U15.0',
    'O15.0',
    'U15.5',
    'O15.5',
    'U16.0',
    'O16.0',
    'U16.5',
    'O16.5',
];

const scoreList = [
    '0-0',
    '1-0',
    '0-1',
    '1-1',
    '2-0',
    '0-2',
    '2-1',
    '1-2',
    '2-2',
    '3-0',
    '0-3',
    '3-1',
    '1-3',
    '3-2',
    '2-3',
    '3-3',
    '4-0',
    '0-4',
    '4-1',
    '1-4',
    '4-2',
    '2-4',
    '4-3',
    '3-4',
    '4-4',
    '5-0',
    '0-5',
    '5-1',
    '1-5',
    '5-2',
    '2-5',
    '5-3',
    '3-5',
    '5-4',
    '4-5',
    '5-5'
];

const h2hList = [
    '1',
    '2'
];

const bookers = [
    'Pinnacle',
    '1xBet',
    '22Bet',
    'bet-at-home',
    'Bet365',
    'BetCRIS',
    'Betfair Sports',
    'Betfair Exchange',
    'Bethard',
    'Betsafe',
    'Betsson',
    'BetVictor',
    'Betway',
    'BoyleSports',
    'bwin',
    'Coolbet',
    'Expekt',
    'LeoVegas',
    'Marathonbet',
    'Matchbook',
    'Nordicbet',
    'Paf',
    'SBObet',
    'Scandibet',
    'Unibet',
    'Veikkaus',
    'William Hill'
];

const resultList = [
    { value: '0', label: C.PENDING },
    { value: '1', label: C.WIN },
    { value: '2', label: C.LOSE },
    { value: '3', label: C.HALFWIN },
    { value: '4', label: C.HALFLOSE },
    { value: '5', label: C.PUSH },
    { value: '6', label: C.CANCELLED }
];

const countriesList = [
    'Afganistan',
    'Alankomaat',
    'Albania',
    'Algeria',
    'Andorra',
    'Angola',
    'Antigua ja Barbuda',
    'Arabiemiirikunnat',
    'Argentiina',
    'Armenia',
    'Australia',
    'Azerbaidžan',
    'Bahama',
    'Bahrain',
    'Bangladesh',
    'Barbados',
    'Belgia',
    'Belize',
    'Benin',
    'Bhutan',
    'Bolivia',
    'Bosnia ja Hertsegovina',
    'Botswana',
    'Brasilia',
    'Brunei',
    'Bulgaria',
    'Burkina Faso',
    'Burundi',
    'Chile',
    'Costa Rica',
    'Djibouti',
    'Dominica',
    'Dominikaaninen tasavalta',
    'Ecuador',
    'Egypti',
    'El Salvador',
    'Englanti',
    'Eritrea',
    'Espanja',
    'Etelä-Afrikka',
    'Etelä-Korea',
    'Etiopia',
    'Fidži',
    'Filippiinit',
    'Gabon',
    'Gambia',
    'Georgia',
    'Ghana',
    'Grenada',
    'Grönlanti',
    'Guatemala',
    'Guinea',
    'Guinea'-'Bissau',
    'Guyana',
    'Haiti',
    'Honduras',
    'Hong Kong',
    'Indonesia',
    'Intia',
    'Irak',
    'Iran',
    'Irlanti',
    'Islanti',
    'Iso-Britannia',
    'Israel',
    'Italia',
    'Itä-Timor',
    'Itävalta',
    'Jamaika',
    'Japani',
    'Jemen',
    'Jordania',
    'Kambodža',
    'Kamerun',
    'Kanada',
    'Kap Verde',
    'Kazakstan',
    'Kenia',
    'Keski-Afrikan tasavalta',
    'Kiina',
    'Kirgisia',
    'Kiribati',
    'Kolumbia',
    'Komorit',
    'Kongon tasavalta',
    'Kosovo',
    'Kreikka',
    'Kroatia',
    'Kuuba',
    'Kuwait',
    'Kypros',
    'Laos',
    'Latvia',
    'Lesotho',
    'Libanon',
    'Liberia',
    'Libya',
    'Liechtenstein',
    'Liettua',
    'Luxemburg',
    'Madagaskar',
    'Makedonia',
    'Malawi',
    'Malediivit',
    'Malesia',
    'Mali',
    'Malta',
    'Marokko',
    'Marshallinsaaret',
    'Mauritania',
    'Mauritius',
    'Meksiko',
    'Mikronesia',
    'Moldova',
    'Monaco',
    'Mongolia',
    'Montenegro',
    'Mosambik',
    'Myanmar',
    'Namibia',
    'Nauru',
    'Nepal',
    'Nicaragua',
    'Niger',
    'Nigeria',
    'Norja',
    'Norsunluurannikko',
    'Oman',
    'Pakistan',
    'Palau',
    'Palestiina',
    'Panama',
    'Papua-Uusi-Guinea',
    'Paraguay',
    'Peru',
    'Pohjois-Irlanti',
    'Pohjois-Korea',
    'Portugali',
    'Puerto Rico',
    'Puola',
    'Päiväntasaajan Guinea',
    'Qatar',
    'Ranska',
    'Romania',
    'Ruanda',
    'Ruotsi',
    'Saint Kitts ja Nevis',
    'Saint Lucia',
    'Saint Vincent ja Grenadiinit',
    'Saksa',
    'Salomonsaaret',
    'Sambia',
    'Samoa',
    'San Marino',
    'São Tomé ja Príncipe',
    'Saudi-Arabia',
    'Senegal',
    'Serbia',
    'Seychellit',
    'Sierra Leone',
    'Singapore',
    'Skotlanti',
    'Slovakia',
    'Slovenia',
    'Somalia',
    'Sri Lanka',
    'Sudan',
    'Suomi',
    'Suriname',
    'Sveitsi',
    'Swazimaa',
    'Syyria',
    'Tadžikistan',
    'Taiwan',
    'Tansania',
    'Tanska',
    'Thaimaa',
    'Togo',
    'Tonga',
    'Trinidad ja Tobago',
    'Tšad',
    'Tšekki',
    'Tunisia',
    'Turkki',
    'Turkmenistan',
    'Tuvalu',
    'Uganda',
    'Ukraina',
    'Unkari',
    'Uruguay',
    'Uusi-Seelanti',
    'Uzbekistan',
    'Valko-Venäjä',
    'Vanuatu',
    'Vatikaanivaltio',
    'Venezuela',
    'Venäjä',
    'Vietnam',
    'Viro',
    'Wales',
    'Yhdysvallat',
    'Zimbabwe'
];

const premierLeagueTeamsList = [
    'Arsenal',
    'Aston Villa',
    'Bournemouth',
    'Brighton',
    'Burnley',
    'Chelsea',
    'Crystal Palace',
    'Everton',
    'Leicester',
    'Liverpool',
    'Manchester City',
    'Manchester United',
    'Newcastle',
    'Norwich',
    'Sheffield United',
    'Southampton',
    'Tottenham',
    'Watford',
    'West Ham',
    'Wolverhampton'
];

const championshipTeamsList = [
    'Barnsley',
    'Birmingham City',
    'Blackburn Rovers',
    'Brentford',
    'Bristol City',
    'Cardiff City',
    'Charlton Athletic',
    'Derby County',
    'Fulham',
    'Huddersfield Town',
    'Hull City',
    'Leeds United',
    'Luton Town',
    'Middlesbrough',
    'Millwall',
    'Nottingham Forest',
    'Preston North End',
    'Queens Park Rangers',
    'Reading',
    'Sheffield Wednesday',
    'Stoke City',
    'Swansea City',
    'West Bromwich Albion',
    'Wigan Athletic'
];

const scottishTeamsList = [
    'Aberdeen',
    'Celtic',
    'Hamilton Academical',
    'Heart of Midlothian',
    'Hibernian',
    'Kilmarnock',
    'Livingston',
    'Motherwell',
    'Rangers',
    'Ross County',
    'St Johnstone',
    'St Mirren'
];

const laLigaTeamsList = [
    'Alaves',
    'Athletic Bilbao',
    'Atletico Madrid',
    'Barcelona',
    'Betis',
    'Celta Vigo',
    'Eibar',
    'Espanyol',
    'Getafe',
    'Granada CF',
    'Leganes',
    'Levante',
    'Mallorca',
    'Osasuna',
    'Real Madrid',
    'Real Sociedad',
    'Sevilla',
    'Valencia',
    'Valladolid',
    'Villarreal'
];

const serieATeamsList = [
    'Atalanta',
    'Bologna',
    'Brescia',
    'Cagliari',
    'Fiorentina',
    'Genoa',
    'Hellas Verona',
    'Internazionale',
    'Juventus',
    'Lazio',
    'Lecce',
    'Milan',
    'Napoli',
    'Parma',
    'Roma',
    'Sampdoria',
    'Sassuolo',
    'SPAL',
    'Torino',
    'Udinese'
];

const bundesligaTeamsList = [
    'FC Augsburg',
    'Bayer Leverkusen',
    'Bayern Munich',
    'Borussia Dortmund',
    'Borussia Mönchengladbach',
    'Eintracht Frankfurt',
    'Fortuna Düsseldorf',
    'SC Freiburg',
    'Hertha BSC',
    'TSG 1899 Hoffenheim',
    'FC Köln',
    'RB Leipzig',
    'FSV Mainz',
    'SC Paderborn',
    'Schalke 04',
    'Union Berlin',
    'Werder Bremen',
    'VfL Wolfsburg'
];

const bundesliga2TeamsList = [
    'Arminia Bielefeld',
    'Aue',
    'Bochum',
    'Darmstadt',
    'Greuther Furth',
    'Hamburger SV',
    'Hannover',
    'Heidenheim',
    'Holstein Kiel',
    'Karlsruher',
    'Nurnberg',
    'Regensburg',
    'Sandhausen',
    'SG Dynamo Dresden',
    'St. Pauli',
    'Stuttgart',
    'VfL Osnabruck',
    'Wehen'
];

const ligue1TeamsList = [
    'Amiens',
    'Angers',
    'Bordeaux',
    'Brest',
    'Dijon',
    'Lille',
    'Lyon',
    'Marseille',
    'Metz',
    'Monaco',
    'Montpellier',
    'Nantes',
    'Nice',
    'Nîmes',
    'Paris Saint-Germain',
    'Reims',
    'Rennes',
    'Saint-Étienne',
    'Strasbourg',
    'Toulouse'
];

const veikkausLiigaTeamsList = [
    'FC Honka',
    'FC Inter',
    'FC Lahti',
    'HIFK jalkapallo',
    'HJK',
    'IFK Mariehamn',
    'Ilves jalkapallo',
    'KPV',
    'KuPS',
    'RoPS',
    'SJK',
    'VPS'
];

const ykkonenTeamsList = [
    'AC Kajaani',
    'AC Oulu',
    'Ekenäs IF',
    'FC Haka',
    'FC KTP',
    'FF Jaro',
    'MuSa',
    'MYPA',
    'TPS jalkapallo',
    'TPV'
];

const kakkonenTeamsList = [
    'Kiffen',
    'PEPO',
    'MiPK',
    'MP',
    'VJS',
    'JIPPO',
    'Sudet',
    'Reipas',
    'JäPS',
    'NJS',
    'Viikingit',
    'Klubi 04',
    'Pallo-Iirot',
    'TamU',
    'Ilves/2',
    'KaaPo',
    'SalPa',
    'EPS',
    'GrIFK',
    'Honka/Akatemia',
    'FC Espoo',
    'FC Jazz',
    'KäPa',
    'Gnistan',
    'RoPS/2',
    'JS Hercules',
    'OLS',
    'PK-37',
    'KuFu98',
    'GBK',
    'JBK',
    'VPS-j',
    'FCV',
    'Vasa IFK',
    'KajHa',
    'SJK/2'
];

const kolmonenTeamsList = [
    'Atlantis FC',
    'BK-46',
    'EIF/Akademi',
    'EsPa',
    'FC Espoo/2',
    'FC FINNKURD',
    'FC HIK',
    'HPS',
    'HIFK/2',
    'NuPS',
    'SexyPöxyt',
    'Toukolan Teräs',
    'Atlantis FC/Akatemia',
    'CLE',
    'HooGee',
    'KoiPS',
    'LPS',
    'LePa',
    'Ponnistajat',
    'PK-35',
    'PuiU',
    'SibboV',
    'TiPS Vantaa',
    'TuPS',
    'FC Futura',
    'FC Kontu',
    'FC Kuusysi',
    'FC Viikingit/S.C.P.',
    'HerTo',
    'HyPS',
    'JoKi',
    'JäPS/47',
    'Nopsa',
    'RiPS',
    'PKKU',
    'Valtti',
    'IPS',
    'FC Peltirumpu',
    'HaPK',
    'Kultsu FC',
    'KyPa',
    'PeKa',
    'Ri-Pa',
    'STPS',
    'SavU',
    'SiU',
    'Union Plaani',
    'Virky',
    'AC BARCA',
    'FC Blackbird',
    'JJK-Villiketut',
    'JPS',
    'Kings Kuopio',
    'KuKi',
    'LehPa-77',
    'PAVE',
    'SC Riverball',
    'ToU',
    'Warkaus JK',
    'AC OULU Akatemia',
    'AS Moon',
    'Kemi City FC',
    'HauPa, Haukipudas',
    'OTP',
    'RoPo',
    'Roi Utd',
    'SoPa',
    'TP-47',
    'ACE',
    'FJK',
    'HJS Akatemia',
    'Ilves-Kissat',
    'JanPa',
    'Loiske',
    'NoPS',
    'PS-44',
    'TKT',
    'TP-T',
    'TPV/2',
    'PP-70',
    'FC Inter 2',
    'FC Åland',
    'JIK',
    'JyTy',
    'MaPS',
    'PIF',
    'Peimari United',
    'RaiFu',
    'SoVo',
    'TOVE',
    'TPK',
    'TuWe',
    'Wilpas',
    'ÅIFK',
    'Esse IK',
    'I-JBK',
    'KPS',
    'KPV/Akatemia',
    'Kasko IK',
    'Korsnäs FF',
    'NIK',
    'Närpes Kraft',
    'SIF',
    'Sporting Kristina',
    'VPV',
    'Virkiä',
    'FC Kiisto',
    'Töölön taisto'
];

const nuortenSMJalkapalloTeamsList = [
    'TPS U20',
    'FC Haka-j/HJS YJ U20',
    'FC Inter U20',
    'HIFK U20',
    'KuPS U20',
    'FC Espoo U20',
    'VPS-j U20',
    'FC Jazz U20',
    'Ilves U20',
    'JJK U20',
    'Atlantis FC U20',
    'VJS U20',
    'KaaPo U20',
    'SJK U20',
    'FC Lahti U20',
    'HJK U20',
    'JanPa/FJK YJ U20',
    'PK-35 U20',
    'Gnistan U20',
    'FC Honka U20',
    'MP U20',
    'JäPS U20',
    'TPV U20',
    'RiPS U20',
    'LoPa U20'
];

const naistenLiigaTeamsList = [
    'FC Honka naiset',
    'HJK naiset',
    'IK Myran naiset',
    'Ilves naiset',
    'JyPK naiset',
    'KuPS naiset',
    'ONS naiset',
    'TiPS naiset',
    'TPS naiset',
    'Åland United naiset'
];

const allsvenskanFootballTeamsList = [
    'AFC Eskilstuna',
    'AIK',
    'BK Häcken',
    'Djurgårdens IF',
    'Falkenbergs FF',
    'GIF Sundsvall',
    'Hammarby IF',
    'Helsingborgs IF',
    'IF Elfsborg',
    'IFK Göteborg',
    'IFK Norrköping',
    'IK Sirius',
    'Kalmar FF',
    'Malmö FF',
    'Örebro SK',
    'Östersunds FK'
];

const superettanTeamsList = [
    'Brage',
    'Brommapojkarna',
    'Dalkurd',
    'Degerfors',
    'Frej',
    'GAIS',
    'Halmstad',
    'Jönköpings',
    'Mjällby',
    'Norrby',
    'Örgryte',
    'Östers',
    'Syrianska',
    'Trelleborgs',
    'Vårbergs',
    'Västerås SK'
];

const swedenDivision1TeamsList = [
    'Akropolis',
    'Bodens',
    'Carlstad',
    'Forward',
    'Gefle',
    'Karlslunds',
    'Karlstad',
    'Linkoping City',
    'Nykopings',
    'Rynninge',
    'Sandvikens',
    'Sollentuna',
    'Sylvia',
    'Team TG FF',
    'Umeå FC',
    'Vasalunds',
    'Assyriska IK',
    'Atvidabergs',
    'Eskilsminne',
    'Kristianstads',
    'Landskrona',
    'Lindome',
    'Ljungskile',
    'Lunds',
    'Oddevold',
    'Oskarshamns',
    'Skovde AIK',
    'Torns',
    'Trollhättan',
    'Tvaakers',
    'Utsiktens',
    'Varnamo'
];

const swedenDivision2TeamsList = [
    'Anundsjo',
    'Friska Viljor',
    'Gottne IF',
    'IFK Luleå',
    'IFK Ostersund',
    'IFK Umea',
    'Kramfors-Alliansen',
    'Pitea',
    'Sandviks',
    'Skellefteå',
    'Taftea IK',
    'Timrå',
    'Umeå FC',
    'Ytterhogdals',
    'Enkopings SK',
    'Gute',
    'Hudiksvalls',
    'IFK Stocksund',
    'Karlbergs',
    'Kungsangens',
    'Kvarnsvedens',
    'Lidingo IFK',
    'Mora',
    'Skiljebo',
    'Sundbybergs',
    'Taby',
    'Upsala',
    'Valbo FF',
    'Angelholms',
    'Assyriska BK',
    'Eslovs',
    'Halmia',
    'Hittarps',
    'IFK Malmo',
    'Olympic',
    'Onsala',
    'Prespa Birlik',
    'Qviding',
    'Rosengard',
    'Stafsinge',
    'Ullareds IK',
    'Vinbergs',
    'AFK Linkopings',
    'Arameisk-Syrianska',
    'Aspudden Tellus',
    'Assyriska FF',
    'Assyriska IF',
    'Enskede',
    'Haninge',
    'Huddinge',
    'Motala',
    'Newroz',
    'Sodertalje FK',
    'Stockholm Internazionale',
    'Tyreso',
    'Varmbols',
    'Amal',
    'Gauthiod',
    'Grebbestads',
    'Lidkopings FK',
    'Nordvärmland',
    'Örebro Syrianska',
    'Sävedalens IF',
    'Stenungsunds',
    'Tidaholm IFK',
    'Torslanda',
    'Uddevalla',
    'Vanersborgs FK',
    'Vanersborgs IF',
    'Vårgårda IK',
    'Akropolis',
    'Alvsjo',
    'Enskede',
    'Eskilstuna City',
    'Gute',
    'Hammarby TFF',
    'IFK Eskilstuna',
    'Karlskoga',
    'Karlslunds',
    'Panellinios',
    'Rynninge',
    'Varmbols',
    'Almeboda/Linneryd',
    'Asarums',
    'Bromolla',
    'Dalstorps',
    'Hassleholms IF',
    'Husqvarna',
    'IFK Berga',
    'IFK Hassleholm',
    'Karlskrona',
    'Nassjo',
    'Nosaby IF',
    'Osterlen FF',
    'Rappe GOIF',
    'Raslatts'
];

const danishSuperligaTeamsList = [
    'AaB',
    'AGF',
    'Brøndby',
    'Copenhagen',
    'Esbjerg',
    'Hobro',
    'Horsens',
    'Lyngby',
    'Midtjylland',
    'Nordsjælland',
    'OB',
    'Randers',
    'Silkeborg',
    'SønderjyskE'
];

const eredivisieTeamsList = [
    'ADO Den Haag',
    'Ajax',
    'AZ Alkmaar',
    'Emmen',
    'Feyenoord',
    'Fortuna Sittard',
    'Groningen',
    'Heerenveen',
    'Heracles Almelo',
    'PEC Zwolle',
    'PSV 	Eindhoven',
    'RKC Waalwijk',
    'Sparta Rotterdam',
    'Twente',
    'Utrecht',
    'Vitesse',
    'VVV-Venlo',
    'Willem II'
];

const eersteDivisieTeamsList = [
    'Almere City',
    'Breda',
    'Cambuur',
    'Den Bosch',
    'Dordrecht',
    'Eindhoven FC',
    'Excelsior',
    'FC Volendam',
    'G.A. Eagles',
    'Graafschap',
    'Helmond',
    'Jong Ajax',
    'Jong AZ',
    'Jong PSV',
    'Jong Utrecht',
    'Maastricht',
    'Nijmegen',
    'Oss',
    'Roda',
    'Telstar'
];

const belgianFirstDivisionATeamsList = [
    'Anderlecht',
    'Antwerp',
    'Cercle Brugge',
    'Charleroi',
    'Club Brugge',
    'Eupen',
    'Excel Mouscron',
    'Genk',
    'Gent',
    'Kortrijk',
    'Mechelen',
    'Oostende',
    'Sint-Truiden',
    'Standard Liège',
    'Waasland-Beveren',
    'Zulte-Waregem'
];

const belgianFirstDivisionBTeamsList = [
    'Beerschot Wilrijk',
    'Lokeren',
    'Lommel',
    'OH Leuven',
    'Roeselare',
    'Union SG',
    'Virton',
    'Westerlo'
];

const brazilSerieATeamsList = [
    'Athletico-PR',
    'Atletico-MG',
    'Avai',
    'Bahia',
    'Botafogo RJ',
    'Ceara',
    'Chapecoense-SC',
    'Corinthians',
    'Cruzeiro',
    'CSA',
    'Flamengo RJ',
    'Fluminense',
    'Fortaleza',
    'Goias',
    'Gremio',
    'Internacional',
    'Palmeiras',
    'Santos',
    'Sao Paulo',
    'Vasco da Gama'
];

const ecuadorLigaProTeamsList = [
    'America de Quito',
    'Aucas',
    'Barcelona SC',
    'Delfin',
    'Deportivo Cuenca',
    'EL Nacional',
    'Emelec',
    'Fuerza Amarilla',
    'Guayaquil City',
    'Ind. del Valle',
    'LDU Quito',
    'Macara',
    'Mushuc Runa',
    'Olmedo',
    'Tecnico Universitario',
    'Universidad Catolica'
];

const paraguayPrimeraDivisionTeamsList = [
    'Cerro Porteno',
    'Deportivo Capiata',
    'Deportivo Santani',
    'Diaz',
    'Guarani',
    'Libertad Asuncion',
    'Nacional Asuncion',
    'Olimpia Asuncion',
    'River Plate Paraguay',
    'Sportivo San Lorenzo',
    'Sol de America',
    'Sportivo Luqueno'
];

const mlsTeamsList = [
    'Atlanta United FC',
    'Chicago Fire',
    'FC Cincinnati',
    'Colorado Rapids',
    'Columbus Crew SC',
    'D.C. United',
    'FC Dallas',
    'Houston Dynamo',
    'LA Galaxy',
    'Los Angeles FC',
    'Minnesota United FC',
    'Montreal Impact',
    'New England Revolution',
    'New York City FC',
    'New York Red Bulls',
    'Orlando City SC',
    'Philadelphia Union',
    'Portland Timbers',
    'Real Salt Lake',
    'San Jose Earthquakes',
    'Seattle Sounders FC',
    'Sporting Kansas City',
    'Toronto FC',
    'Vancouver Whitecaps FC'
];

const nhlTeamsList = [
    'Arizona Coyotes',
    'Anaheim Ducks',
    'Boston Bruins',
    'Buffalo Sabres',
    'Calgary Flames',
    'Carolina Hurricanes',
    'Chicago Blackhawks',
    'Colorado Avalanche',
    'Columbus Blue Jackets',
    'Dallas Stars',
    'Detroit Red Wings',
    'Edmonton Oilers',
    'Florida Panthers',
    'Los Angeles Kings',
    'Minnesota Wild',
    'Montreal Canadiens',
    'Nashville Predators',
    'New Jersey Devils',
    'New York Islanders',
    'New York Rangers',
    'Ottawa Senators',
    'Philadelphia Flyers',
    'Pittsburgh Penguins',
    'St. Louis Blues',
    'San Jose Sharks',
    'Tampa Bay Lighting',
    'Toronto Maple Leafs',
    'Vancouver Canucks',
    'Vegas Golden Knights',
    'Washington Capitals',
    'Winnipeg Jets',
];

const khlTeamsList = [
    'Admiral Vladivostok',
    'Ak Bars Kazan',
    'Amur Khabarovsk',
    'Avangard Omsk',
    'Avtomobilist Yekaterinburg',
    'Barys Nur-Sultan',
    'CSKA Moskova',
    'Dynamo Minsk',
    'Dynamo Riga',
    'Dynamo Moskova',
    'HK Sochi',
    'Jokerit',
    'Kunlun Red Star',
    'Lokomotiv Yaroslavl',
    'Metallurg Magnitogorsk',
    'Neftekhimik Nizhnekamsk',
    'Salavat Yulaev Ufa',
    'Severstal Cherepovets',
    'Sibir Novosibirsk',
    'SKA Pietari',
    'Spartak Moskova',
    'Torpedo Nizhny Novgorod',
    'Traktor Chelyabinsk',
    'Vityaz Podolsk'
];

const liigaTeamsList = [
    'HIFK',
    'HPK',
    'Ilves',
    'Jukurit',
    'JYP',
    'KalPa',
    'KooKoo',
    'Kärpät',
    'Lukko',
    'Pelicans',
    'SaiPa',
    'Sport',
    'Tappara',
    'TPS',
    'Ässät'
];

const mestisTeamsList = [
    'Hermes',
    'Hokki',
    'IPK',
    'Jokipojat',
    'Ketterä',
    'KeuPa HT',
    'Kiekko-Vantaa',
    'KOOVEE',
    'Peliitat',
    'RoKi',
    'SaPKo',
    'TUTO'
];

const shlTeamsList = [
    'Brynäs',
    'Djurgårdens IF',
    'Frölunda HC',
    'Färjestad BK',
    'HV71',
    'IK Oskarshamn',
    'Leksands IF',
    'Linköping HC',
    'Luleå HF',
    'Malmö Redhawks',
    'Rögle BK',
    'Skellefteå AIK',
    'Växjö Lakers HC',
    'Örebro HK'
];

const allsvenskanIceHockeyTeamsList = [
    'AIK',
    'Almtuna IS',
    'BIK Karlskoga',
    'HC Vita Hästen',
    'IF Björklöven',
    'Karlskrona HK',
    'Kristianstads IK',
    'MODO Hockey',
    'Mora IK',
    'Södertälje SK',
    'Timrå IK',
    'Tingsryds AIF',
    'Västerviks IK',
    'Västerås IK'
];

const mlbTeamsList = [
    'Arizona Diamondbacks',
    'Atlanta Braves',
    'Baltimore Orioles',
    'Boston Red Sox',
    'Chicago White Sox',
    'Chicago Cubs',
    'Cincinnati Reds',
    'Cleveland Indians',
    'Colorado Rockies',
    'Detroit Tigers',
    'Houston Astros',
    'Kansas City Royals',
    'Los Angeles Angels',
    'Los Angeles Dodgers',
    'Miami Marlins',
    'Milwaukee Brewers',
    'Minnesota Twins',
    'New York Yankees',
    'New York Mets',
    'Oakland Athletics',
    'Philadelphia Phillies',
    'Pittsburgh Pirates',
    'San Diego Padres',
    'San Francisco Giants',
    'Seattle Mariners',
    'St. Louis Cardinals',
    'Tampa Bay Rays',
    'Texas Rangers',
    'Toronto Blue Jays',
    'Washington Nationals'
];

const nbaTeamsList = [
    'Atlanta Hawks',
    'Boston Celtics',
    'Brooklyn Nets',
    'Charlotte Hornets',
    'Chicago Bulls',
    'Cleveland Cavaliers',
    'Dallas Mavericks',
    'Denver Nuggets',
    'Detroit Pistons',
    'Golden State Warriors',
    'Houston Rockets',
    'Indiana Pacers',
    'Los Angeles Clippers',
    'Los Angeles Lakers',
    'Memphis Grizzlies',
    'Miami Heat',
    'Milwaukee Bucks',
    'Minnesota Timberwolves',
    'New Orleans Pelicans',
    'New York Knicks',
    'Oklahoma City Thunder',
    'Orlando Magic',
    'Philadelphia 76ers',
    'Phoenix Suns',
    'Portland Trail Blazers',
    'Sacramento Kings',
    'San Antonio Spurs',
    'Toronto Raptors',
    'Utah Jazz',
    'Washington Wizards'
];

const nflTeamsList = [
    'Arizona Cardinals',
    'Atlanta Falcons',
    'Baltimore Ravens',
    'Buffalo Bills',
    'Carolina Panthers',
    'Chicago Bears',
    'Cincinnati Bengals',
    'Cleveland Browns',
    'Dallas Cowboys',
    'Denver Broncos',
    'Detroit Lions',
    'Green Bay Packers',
    'Houston Texans',
    'Indianapolis Colts',
    'Jacksonville Jaguars',
    'Kansas City Chiefs',
    'Los Angeles Chargers',
    'Los Angeles Rams',
    'Miami Dolphins',
    'Minnesota Vikings',
    'New England Patriots',
    'New Orleans Saints',
    'New York Giants',
    'New York Jets',
    'Oakland Raiders',
    'Philadelphia Eagles',
    'Pittsburgh Steelers',
    'San Francisco 49ers',
    'Seattle Seahawks',
    'Tampa Bay Buccaneers',
    'Tennessee Titans',
    'Washington Redskins'
];

const tennisList = [
    'Novak Djokovic',
    'Rafael Nadal',
    'Roger Federer',
    'Dominic Thiem',
    'Alexander Zverev',
    'Stefanos Tsitsipas',
    'Kei Nishikori',
    'Kevin Anderson',
    'Karen Khachanov',
    'Fabio Fognini',
    'Juan Martin del Potro',
    'John Isner',
    'Daniil Medvedev',
    'Borna Coric',
    'Gael Monfils',
    'Nikoloz Basilashvili',
    'Milos Raonic',
    'Marin Cilic',
    'Stan Wawrinka',
    'Matteo Berrettini',
    'Felix Auger-Aliassime',
    'Roberto Bautista Agut',
    'David Goffin',
    'Diego Schwartzman',
    'Gilles Simon',
    'Guido Pella',
    'Denis Shapovalov',
    'Lucas Pouille',
    'Alex de Minaur',
    'Kyle Edmund',
    'Taylor Fritz',
    'Benoit Paire',
    'Jan-Lennard Struff',
    'Cristian Garin',
    'Laslo Djere',
    'Dusan Lajovic',
    'Fernando Verdasco',
    'Frances Tiafoe',
    'Pierre-Hugues Herbert',
    'Radu Albot',
    'Marco Cecchinato',
    'Adrian Mannarino',
    'Nick Kyrgios',
    'Jordan Thompson',
    'Pablo Cuevas',
    'Lorenzo Sonego',
    'Richard Gasquet',
    'Hubert Hurkacz',
    'Grigor Dimitrov',
    'Pablo Carreno Busta',
    'Marton Fucsovics',
    'Filip Krajinovic',
    'Nicolas Jarry',
    'Feliciano Lopez',
    'Cameron Norrie',
    'Martin Klizan',
    'Philipp Kohlschreiber',
    'Mikhail Kukushkin',
    'Leonardo Mayer',
    'Juan Ignacio Londero',
    'Daniel Evans',
    'Casper Ruud',
    'Reilly Opelka',
    'John Millman',
    'Sam Querrey',
    'Ugo Humbert',
    'Miomir Kecmanovic',
    'Yoshihito Nishioka',
    'Joao Sousa',
    'Mackenzie McDonald',
    'Steve Johnson',
    'Jo-Wilfried Tsonga',
    'Andreas Seppi',
    'Roberto Carballes Baena',
    'Federico Delbonis',
    'Robin Haase',
    'Ricardas Berankis',
    'Pablo Andujar',
    'Andrey Rublev',
    'Ivo Karlovic',
    'Jeremy Chardy',
    'Alexander Bublik',
    'Hugo Dellien',
    'Corentin Moutet',
    'Aljaz Bedene',
    'Lloyd Harris',
    'Bradley Klahn',
    'Marius Copil',
    'Thomas Fabbiano',
    'Jaume Munar',
    'Damir Dzumhur',
    'Ernests Gulbis',
    'Prajnesh Gunneswaran',
    'Tennys Sandgren',
    'Matthew Ebden',
    'Bernard Tomic',
    'Henri Laaksonen',
    'Yannick Maden',
    'Albert Ramos-Vinolas',
    'Malek Jaziri',
    'Alexei Popyrin',
    'Stefano Travaglia',
    'Antoine Hoang',
    'Dennis Novak',
    'Marcel Granollers',
    'Paolo Lorenzi',
    'Pedro Sousa',
    'Kamil Majchrzak',
    'Taro Daniel',
    'Tomas Berdych',
    'Denis Kudla',
    'Brayden Schnur',
    'Thiago Monteiro',
    'Guido Andreozzi',
    'Denis Istomin',
    'Gregoire Barrere',
    'Jason Jung',
    'Elias Ymer',
    'Matthias Bachinger',
    'Andrej Martin',
    'Peter Gojowczyk',
    'Mikael Ymer',
    'Salvatore Caruso',
    'Jiri Vesely',
    'Soonwoo Kwon',
    'Pedro Martinez',
    'Ryan Harrison',
    'Evgeny Donskoy',
    'Lukas Rosol',
    'Dominik Koepfer',
    'Alejandro Davidovich Fokina',
    'Lorenzo Giustino',
    'Bjorn Fratangelo',
    'Tommy Paul',
    'Marcos Baghdatis',
    'Sergiy Stakhovsky',
    'David Ferrer',
    'Ilya Ivashka',
    'Peter Polansky',
    'Sebastian Ofner',
    'Alex Bolt',
    'Gianluca Mager',
    'Nicolas Mahut',
    'Kimmer Coppejans',
    'James Duckworth',
    'Adrian Menendez-Maceiras',
    'Rudolf Molleker',
    'Dustin Brown',
    'Filippo Baldi',
    'Oscar Otte',
    'Ramkumar Ramanathan',
    'Michael Mmoh',
    'Hyeon Chung',
    'Dudi Sela',
    'Marcos Giron',
    'Tatsuma Ito',
    'Quentin Halys',
    'Norbert Gombos',
    'Go Soeda',
    'Alessandro Giannessi',
    'Facundo Bagnis',
    'Marco Trungelliti',
    'Blaz Rola',
    'Guillermo Garcia-Lopez',
    'Jozef Kovalik',
    'Viktor Troicki',
    'Egor Gerasimov',
    'Tommy Robredo',
    'Jay Clarke',
    'Joao Domingues',
    'Ruben Bemelmans',
    'Mikael Torpegaard',
    'Jack Sock',
    'Federico Gaio',
    'Maximilian Marterer',
    'Federico Coria',
    'Stefano Napolitano',
    'Facundo Arguello',
    'Nikola Milojevic',
    'Yasutaka Uchiyama',
    'Filip Horansky',
    'Noah Rubin',
    'Mitchell Krueger',
    'Emilio Gomez',
    'Carlos Berlocq',
    'Simone Bolelli',
    'Vasek Pospisil',
    'Jason Kubler',
    'Marc Polmans',
    'Darian King',
    'Maxime Janvier',
    'Thanasi Kokkinakis',
    'Christopher Eubanks',
    'Daniel Elahi Galan',
    'James Ward',
    'Viktor Galovic',
    'Donald Young',
    'Tallon Griekspoor',
    'Constant Lestienne',
    'Aleksandr Nedovyesov'
];

const golfList = [
    'Brooks Koepka',
    'Dustin Johnson',
    'Rory McIlroy',
    'Justin Rose',
    'Tiger Woods',
    'Bryson DeChambeau',
    'Francesco Molinari',
    'Jon Rahm',
    'Justin Thomas',
    'Patrick Cantlay',
    'Xander Schauffele',
    'Gary Woodland',
    'Matt Kuchar',
    'Rickie Fowler',
    'Paul Casey',
    'Adam Scott',
    'Tony Finau',
    'Jason Day',
    'Webb Simpson',
    'Tommy Fleetwood',
    'Bubba Watson',
    'Louis Oosthuizen',
    'Matt Wallace',
    'Marc Leishman',
    'Patrick Reed',
    'Chez Reavie',
    'Kevin Kisner',
    'Phil Mickelson',
    'Hideki Matsuyama',
    'Matthew Fitzpatrick',
    'Keegan Bradley',
    'Kevin Na',
    'Shane Lowry',
    'Rafa Cabrera Bello',
    'Eddie Pepperell',
    'Sergio Garcia',
    'Henrik Stenson',
    'Jordan Spieth',
    'Ian Poulter',
    'Bernd Wiesberger',
    'Billy Horschel',
    'Cameron Smith',
    'Tyrrell Hatton',
    'Brandt Snedeker',
    'Haotong Li',
    'Andrew Putnam',
    'Alex Noren',
    'Jim Furyk',
    'C.T. Pan',
    'Justin Harding',
    'Lucas Bjerregaard',
    'Kiradech Aphibarnrat',
    'Jazz Janewattananond',
    'Charles Howell III',
    'J.B. Holmes',
    'Byeong Hun An',
    'Kyle Stanley',
    'Abraham Ancer',
    'Scott Piercy',
    'Emiliano Grillo',
    'Sungjae Im',
    'Jorge Campillo',
    'Thorbjorn Olesen',
    'Adam Hadwin',
    'Siwoo Kim',
    'Keith Mitchell',
    'Branden Grace',
    'Ryan Palmer',
    'Sunghoon Kang',
    'Aaron Wise',
    'Shugo Imahira',
    'Jason Kokrak',
    'Russell Knox',
    'Luke List',
    'Danny Willett',
    'Lucas Glover',
    'Andrea Pavan',
    'Lee Westwood',
    'Joaquin Niemann',
    'Joost Luiten',
    'Tom Lewis',
    'Ryan Moore',
    'Joel Dahmen',
    'Erik van Rooyen',
    'Charley Hoffman',
    'Martin Kaymer',
    'Corey Conners',
    'Kevin Streelman',
    'Rory Sabbatini',
    'Mike Lorenzo-Vera',
    'Brian Harman',
    'Dylan Frittelli',
    'Mikko Korhonen',
    'Benjamin Hebert',
    'Adrian Otaegui',
    'Kevin Tway',
    'Graeme McDowell',
    'Max Homa',
    'Jhonattan Vegas',
    'Nate Lashley',
    'Guido Migliozzi',
    'Rikuya Hoshino',
    'Chesson Hadley',
    'Romain Langasque',
    'Alexander Bjork',
    'Andy Sullivan',
    'Ryan Fox',
    'Thomas Pieters',
    'Daniel Berger',
    'Satoshi Kodaira',
    'Zach Johnson',
    'Kurt Kitayama',
    'Sam Burns',
    'Julian Suri',
    'Vaughn Taylor',
    'Michael Thompson',
    'Jimmy Walker',
    'Adam Long',
    'Danny Lee',
    'Marcus Kinhult',
    'JC Ritchie',
    'Beau Hossler',
    'Shaun Norris',
    'Brendan Jones',
    'Paul Waring',
    'Pat Perez',
    'Yuta Ikeda',
    'Adri Arnaus',
    'Zhang Xinjun',
    'David Lipsky',
    'Jordan L Smith',
    'Scottie Scheffler',
    'Patton Kizzire',
    'Stewart Cink',
    'Lucas Herbert',
    'Nick Watney',
    'Andrew Landry',
    'Matthew Wolff',
    'Russell Henley',
    'Christiaan Bezuidenhout',
    'Sanghyun Park',
    'Cameron Champ',
    'Thomas Detry',
    'Brian Stuard',
    'Ross Fisher',
    'Robert MacIntyre',
    'Ryan Armour',
    'J.J Spaun',
    'Richard Sterne',
    'Oliver Wilson',
    'Zack Sucher',
    'Robby Shelton IV',
    'Bud Cauley',
    'JT Poston',
    'Sean Crocker',
    'Scott Stallings',
    'Peter Uihlein',
    'Charl Schwartzel',
    'Junggon Hwang',
    'Denny McCarthy',
    'Jason Dufner',
    'Kevin Chappell',
    'Scott Vincent',
    'Troy Merritt',
    'Brian Gay',
    'Masahiro Kawamura',
    'Victor Perez',
    'Talor Gooch',
    'Ryuko Tokimatsu',
    'Alexander Levy',
    'Chris Paisley',
    'Collin Morikawa',
    'Aaron Rai',
    'Harold Varner III',
    'Ted Potter Jr',
    'Wyndham Clark',
    'Austin Cook',
    'Brandon Stone',
    'Brad Kennedy',
    'Yuki Inamori',
    'Ryo Ishikawa',
    'Chris Wood',
    'Brice Garnett',
    'Nacho Elvira',
    'Antoine Rozner',
    'John Catlin',
    'Yoshinori Fujimoto',
    'Jason Scrivener',
    'Scott Hend',
    'Sam Ryder',
    'Doc Redman',
    'Gaganjeet Bhullar',
    'Paul Dunne',
    'Jake Ian McLeod',
    'Mikumu Horikawa',
    'Shubhankar Sharma',
    'Mackenzie Hughes',
    'Dean Burmester',
    'Aaron Baddeley',
    'Martin Trainer',
    'Shota Akiyoshi',
    'Patrick Rodgers',
    'Peter Malnati',
    'Joachim B Hansen',
    'Matt Jones',
    'Chris Stroud',
    'Gavin Green',
    'Darren Fichardt',
    'Bronson Burgoon',
    'Pablo Larrazabal',
    'Jonas Blixt',
    'Brendan Steele',
    'Kelly Kraft',
    'George Coetzee',
    'Alvaro Quiros',
    'Viktor Hovland',
    'Louis de Jager',
    'Hyungjoon Lee',
    'Carlos Ortiz',
    'Y.E. Yang',
    'Andrew Johnston',
    'Nelson Ledesma',
    'Matthias Schwab',
    'Tyler McCumber',
    'Fabrizio Zanotti',
    'Kyounghoon Lee',
    'Chris Kirk',
    'Kodai Ichihara',
    'Dimitrios Papadatos',
    'Kazuki Higa',
    'Roger Sloan',
    'Daniel van Tonder',
    'Darius Van Driel',
    'Kramer Hickok',
    'Matt Every',
    'Maverick Antcliff',
    'Adam Schenk',
    'Wes Roach',
    'Juan Sebastian Munoz',
    'David Law',
    'Poom Saksansin',
    'Grant Forrest',
    'Cameron Davis',
    'Harry Higgs',
    'Chase Seiffert',
    'Wonjoon Lee',
    'Robert Rock',
    'Sihwan Kim',
    'Robin Roussel',
    'Chan Kim',
    'Rhys Enoch',
    'Lanto Griffin',
    'Seungsu Han',
    'Nick Taylor',
    'Connor Syme',
    'Jaco Ahlers',
    'Wade Ormsby',
    'Richy Werenski',
    'Henrik Norlander',
    'Scott Brown',
    'Hyunwoo Ryu',
    'Scott Jamieson',
    'Maximilian Kieffer',
    'Sam Horsfield',
    'Stephen Gallacher',
    'Tomoharu Otsuki',
    'Ricardo Santos',
    'Sepp Straka',
    'Trey Mullinax',
    'Wu Ashun',
    'Matthew Griffin',
    'Rhein Gibson',
    'Prom Meesawat',
    'Martin Laird',
    'Robert Streb',
    'Zander Lombard',
    'Hiroshi Iwata',
    'Gunn Charoenkul',
    'Hosung Choi',
    'David Drysdale',
    'Johannes Veerman',
    'Shawn Stefani',
    'Atomu Shigenaga',
    'Jean-Paul Strydom',
    'Ryan Brehm',
    'Mark Anderson',
    'Kalle Samooja',
    'Ross McGowan',
    'Michael Gellerman',
    'Anirban Lahiri',
    'Matthew Southgate',
    'Meenwhee Kim',
    'Scott Langley',
    'Angelo Que',
    'Jack Singh Brar',
    'Steve Surry',
    'Jamie Lovemark',
    'Stephan Jaeger',
    'Cameron Tringale',
    'Ewen Ferguson'
];

export default names;