import React from 'react'
import PropTypes from 'prop-types'
//import './App.css'

import Button from './Button'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Table from 'react-bootstrap/Table'

const MonivetoPercentagesForm = props => {

    const scoreOddsList = () => {
        let rows = []
        if (props.scoreOdds !== undefined && props.scoreOdds != null && props.scoreOdds.odds.length > 0)
        {
            const odds = props.scoreOdds.odds

            let home = []
            let draw = []
            let away = []

            for (let i = 0; i < odds.length; i++)
            {
                if (odds[i].home > odds[i].away){
                    home.push(<p>{odds[i].home} - {odds[i].away}:  {Math.round(odds[i].odds * 10) / 10}</p>);
                }

                if (odds[i].home === odds[i].away){
                    draw.push(<p>{odds[i].home} - {odds[i].away}:  {Math.round(odds[i].odds * 10) / 10}</p>);
                }

                if (odds[i].home < odds[i].away){
                    away.push(<p>{odds[i].home} - {odds[i].away}:  {Math.round(odds[i].odds * 10) / 10}</p>);
                }
            }

            let numberOfRows = home.length
            if (away.length > numberOfRows){
                numberOfRows = away.length
            }


            for (let i = 0; i < numberOfRows; i++){
                rows.push(
                    <tr>
                        <td><label>{home.length > i ? home[i] : ""}</label></td>
                        <td><label>{draw.length > i ? draw[i] : ""}</label></td>
                        <td><label>{away.length > i ? away[i] : ""}</label></td>
                    </tr>)
            }
        }
        else
        {
            return null;
        }

        return (<React.Fragment>
            <Table striped bordered hover size="sm" style={{ marginTop: 20 }}>
                <thead>
                <tr>
                    <td>Koti</td>
                    <td>Tasapeli</td>
                    <td>Vieras</td>
                </tr>
                </thead>
                <tbody>
                    { rows }
                </tbody>
            </Table>
        </React.Fragment>);
    }

    return (
        <div>
            <Container>
                <Row>
                    <Col>
                        <Form>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="basic-addon1">1</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                    id={props.id}
                                    placeholder="0"
                                    aria-label="1X2 - 1"
                                    min="0"
                                    max="100"
                                    value={props.odds1X2_1}
                                    onChange={props.handleOdds1X2_1Change}
                                />
                            </InputGroup>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="basic-addon1">X</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                    id={props.id}
                                    placeholder="0"
                                    aria-label="1X2 - X"
                                    min="0"
                                    max="100"
                                    value={props.odds1X2_X}
                                    onChange={props.handleOdds1X2_XChange}
                                />
                            </InputGroup>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="basic-addon1">2</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                    id={props.id}
                                    placeholder="0"
                                    aria-label="1X2 - 2"
                                    min="0"
                                    max="100"
                                    value={props.odds1X2_2}
                                    onChange={props.handleOdds1X2_2Change}
                                />
                            </InputGroup>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="basic-addon1">1</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                    id={props.id}
                                    placeholder="0"
                                    aria-label="12 - 1"
                                    min="0"
                                    max="100"
                                    value={props.odds12_1}
                                    onChange={props.handleOdds12_1Change}
                                />
                            </InputGroup>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="basic-addon1">2</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                    id={props.id}
                                    placeholder="0"
                                    aria-label="12 - 2"
                                    min="0"
                                    max="100"
                                    value={props.odds12_2}
                                    onChange={props.handleOdds12_2Change}
                                />
                            </InputGroup>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="basic-addon1">Over 4.5</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                    id={props.id}
                                    placeholder="0"
                                    aria-label="Over 4.5"
                                    min="0"
                                    max="100"
                                    value={props.over45}
                                    onChange={props.handleOver45Change}
                                />
                            </InputGroup>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="basic-addon1">Under 4.5</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                    id={props.id}
                                    placeholder="0"
                                    aria-label="Under 4.5"
                                    min="0"
                                    max="100"
                                    value={props.under45}
                                    onChange={props.handleUnder45Change}
                                />
                            </InputGroup>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="basic-addon1">Over 5.5</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                    id={props.id}
                                    placeholder="0"
                                    aria-label="Over 5.5"
                                    min="0"
                                    max="100"
                                    value={props.over55}
                                    onChange={props.handleOver55Change}
                                />,
                            </InputGroup>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="basic-addon1">Under 5.5</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                    id={props.id}
                                    placeholder="0"
                                    aria-label="Under 5.5"
                                    min="0"
                                    max="100"
                                    value={props.under55}
                                    onChange={props.handleUnder55Change}
                                />
                            </InputGroup>
                            <ButtonToolbar aria-label="Toolbar with button groups">
                                <ButtonGroup className="mr-2" aria-label="First group">
                                    <Button onClick={props.handleSaveChanges} id={props.id} name={props.id} type="submit" text='Laske'></Button>
                                </ButtonGroup>
                            </ButtonToolbar>
                        </Form>
                    </Col>
                </Row>
            </Container>
            { scoreOddsList() }
        </div>
    )
}

MonivetoPercentagesForm.propTypes = {
    handleOdds1X2_1Change: PropTypes.func,
    handleOdds1X2_XChange: PropTypes.func,
    handleOdds1X2_2Change: PropTypes.func,
    handleOdds12_1Change: PropTypes.func,
    handleOdds12_2Change: PropTypes.func,
    handleOver45Change: PropTypes.func,
    handleUnder45Change: PropTypes.func,
    handleOver55Change: PropTypes.func,
    handleUnder55Change: PropTypes.func,
    handleSaveChanges: PropTypes.func
}

const getDateTimeString = (date) => {
    let hour = date.getHours()
    hour = (hour < 10 ? "0" : "") + hour

    let min  = date.getMinutes();
    min = (min < 10 ? "0" : "") + min

    let year = date.getFullYear()

    let month = date.getMonth() + 1
    month = (month < 10 ? "0" : "") + month

    let day  = date.getDate()
    day = (day < 10 ? "0" : "") + day

    return day + "." + month + "." + year + " " + hour + "." + min
}

export default MonivetoPercentagesForm