import React, { useState, useEffect, useRef } from 'react'
import AlikerroinMessage from './AlikerroinMessage'
import Nordicbet from './Nordicbet'
import Telegram from './Telegram'
import Twitter from './Twitter'
import Ylikerroin from './Ylikerroin'
import Notification from '../Notification'
import tipsService from './service'

import useSound from 'use-sound'
import bells from '../../sounds/bells.mp3'
import explosion from '../../sounds/explosion.mp3'
import jinglebells from '../../sounds/jinglebells.mp3'
import shhh from '../../sounds/shhh.mp3'

const Tips = (props) => {
  const [sounds, setSounds] = useState(true)
  const [showTimeLine, setShowTimeLine] = useState(true)
  const [showHiddenUsers, setShowHiddenUsers] = useState(false)
  const [showRemoved, setShowRemoved] = useState(false)
  const [showWagered, setShowWagered] = useState(false)
  const [userSettings, setUserSettings] = useState({})
  const [alikerroinMessage, setAlikerroinMessage] = useState([])
  const [nordicbet, setNordicbet] = useState([])
  const [telegram, setTelegram] = useState([])
  const [twitter, setTwitter] = useState([])
  const [ylikerroin, setYlikerroin] = useState([])
  const [hiddenNordicbetUsers, setHiddenNordicbetUsers] = useState([])
  const [hiddenOddserUsers, setHiddenOddserUsers] = useState([])
  const [hiddenTelegramUsers, setHiddenTelegramUsers] = useState([])
  const [hiddenTwitterUsers, setHiddenTwitterUsers] = useState([])
  const [hiddenYlikerroinUsers, setHiddenYlikerroinUsers] = useState([])
  const [notificationNordicbetUsers, setNotificationNordicbetUsers] = useState([])
  const [notificationOddserUsers, setNotificationOddserUsers] = useState([])
  const [notificationTelegramUsers, setNotificationTelegramUsers] = useState([])
  const [notificationTwitterUsers, setNotificationTwitterUsers] = useState([])
  const [notificationYlikerroinUsers, setNotificationYlikerroinUsers] = useState([])
  const [wageredAlikerroinMessages, setWageredAlikerroinMessages] = useState([])
  const [wageredNordicbetMessages, setWageredNordicbetMessages] = useState([])
  const [wageredOddserMessages, setWageredOddserMessages] = useState([])
  const [wageredTelegramMessages, setWageredTelegramMessages] = useState([])
  const [wageredTwitterMessages, setWageredTwitterMessages] = useState([])
  const [wageredYlikerroinMessages, setWageredYlikerroinMessages] = useState([])
  const [wageredYlikerroinBets, setWageredYlikerroinBets] = useState([])
  const [removedAlikerroinMessages, setRemovedAlikerroinMessages] = useState([])
  const [removedNordicbetMessages, setRemovedNordicbetMessages] = useState([])
  const [removedOddserMessages, setRemovedOddserMessages] = useState([])
  const [removedTelegramMessages, setRemovedTelegramMessages] = useState([])
  const [removedTwitterMessages, setRemovedTwitterMessages] = useState([])
  const [removedYlikerroinMessages, setRemovedYlikerroinMessages] = useState([])
  const [removedYlikerroinBets, setRemovedYlikerroinBets] = useState([])
  const [className, setClassName] = useState(null)
  const [notificationMessage, setNotificationMessage] = useState(null)

  const [playBells] = useSound(bells)
  const [playExplosion] = useSound(explosion)
  const [playJinglebells] = useSound(jinglebells)
  const [playShhh] = useSound(shhh)

  const tipsStyle = {
    'background-color': 'black'
  }

  useEffect(() => {
    tipsService.getUserSettings(props.token).then(response => {
      console.log('UserSettings', response)
      setHiddenNordicbetUsers(response.hiddenNordicbetUsers)
      setHiddenOddserUsers(response.hiddenOddserUsers)
      setHiddenTelegramUsers(response.hiddenTelegramUsers)
      setHiddenTwitterUsers(response.hiddenTwitterUsers)
      setHiddenYlikerroinUsers(response.hiddenYlikerroinUsers)
      setNotificationNordicbetUsers(response.notificationNordicbetUsers)
      setNotificationOddserUsers(response.notificationOddserUsers)
      setNotificationTelegramUsers(response.notificationTelegramUsers)
      setNotificationTwitterUsers(response.notificationTwitterUsers)
      setNotificationYlikerroinUsers(response.notificationYlikerroinUsers)
      setWageredAlikerroinMessages(response.wageredAlikerroinMessages)
      setWageredNordicbetMessages(response.wageredNordicbetMessages)
      setWageredOddserMessages(response.wageredOddserMessages)
      setWageredTelegramMessages(response.wageredTelegramMessages)
      setWageredTwitterMessages(response.wageredTwitterMessages)
      setWageredYlikerroinMessages(response.wageredYlikerroinMessages)
      setWageredYlikerroinBets(response.wageredYlikerroinBets)
      setRemovedAlikerroinMessages(response.removedAlikerroinMessages)
      setRemovedNordicbetMessages(response.removedNordicbetMessages)
      setRemovedOddserMessages(response.removedOddserMessages)
      setRemovedTelegramMessages(response.removedTelegramMessages)
      setRemovedTwitterMessages(response.removedTwitterMessages)
      setRemovedYlikerroinMessages(response.removedYlikerroinMessages)
      setRemovedYlikerroinBets(response.removedYlikerroinBets)
      setUserSettings(response)
    })
  }, [])

  useEffect(() => {
    tipsService.getAlikerroinMessage().then(response => {
      console.log('AlikerroinMessage', response)
      response.sort((b, a) => {
        const c = new Date(a.eventDate)
        const d = new Date(b.eventDate)
        return c-d
      })
      setAlikerroinMessage(response)
    })
  }, [])

  useEffect(() => {
    tipsService.getNordicbet().then(response => {
      console.log('Nordicbet', response)
      response.sort((b, a) => {
        const c = new Date(a.eventDate)
        const d = new Date(b.eventDate)
        return c-d
      })
      setNordicbet(response)
    })
  }, [])

  useEffect(() => {
    tipsService.getTelegram().then(response => {
      console.log('Telegram', response)
      response.sort((b, a) => {
        const c = new Date(a.date)
        const d = new Date(b.date)
        return c-d
      })
      setTelegram(response)
    })
  }, [])

  useEffect(() => {
    tipsService.getTwitter().then(response => {
      console.log('Twitter', response)
      response.sort((b, a) => {
        const c = new Date(a.date)
        const d = new Date(b.date)
        return c-d
      })
      setTwitter(response)
    })
  }, [])

  useEffect(() => {
    tipsService.getYlikerroin().then(response => {
      console.log('Ylikerroin', response)
      response.sort((b, a) => {
        const c = new Date(a.messageDate)
        const d = new Date(b.messageDate)
        return c-d
      })
      setYlikerroin(response)
    })
  }, [])

  useInterval(() => {
    tipsService.getUserSettings(props.token).then(response => {
      if (JSON.stringify(response) != JSON.stringify(userSettings)){
        console.log('UserSettings', response)
        setHiddenNordicbetUsers(response.hiddenNordicbetUsers)
        setHiddenOddserUsers(response.hiddenOddserUsers)
        setHiddenTelegramUsers(response.hiddenTelegramUsers)
        setHiddenTwitterUsers(response.hiddenTwitterUsers)
        setHiddenYlikerroinUsers(response.hiddenYlikerroinUsers)
        setNotificationNordicbetUsers(response.notificationNordicbetUsers)
        setNotificationOddserUsers(response.notificationOddserUsers)
        setNotificationTelegramUsers(response.notificationTelegramUsers)
        setNotificationTwitterUsers(response.notificationTwitterUsers)
        setNotificationYlikerroinUsers(response.notificationYlikerroinUsers)
        setWageredAlikerroinMessages(response.wageredAlikerroinMessages)
        setWageredNordicbetMessages(response.wageredNordicbetMessages)
        setWageredOddserMessages(response.wageredOddserMessages)
        setWageredTelegramMessages(response.wageredTelegramMessages)
        setWageredTwitterMessages(response.wageredTwitterMessages)
        setWageredYlikerroinMessages(response.wageredYlikerroinMessages)
        setWageredYlikerroinBets(response.wageredYlikerroinBets)
        setRemovedAlikerroinMessages(response.removedAlikerroinMessages)
        setRemovedNordicbetMessages(response.removedNordicbetMessages)
        setRemovedOddserMessages(response.removedOddserMessages)
        setRemovedTelegramMessages(response.removedTelegramMessages)
        setRemovedTwitterMessages(response.removedTwitterMessages)
        setRemovedYlikerroinMessages(response.removedYlikerroinMessages)
        setRemovedYlikerroinBets(response.removedYlikerroinBets)
        setUserSettings(response)
      }
    })
  }, 50000)

  useInterval(() => {
    tipsService.getAlikerroinMessage().then(response => {
      response.sort((b, a) => {
        const c = new Date(a.eventDate)
        const d = new Date(b.eventDate)
        return c-d
      })

      if (JSON.stringify(response) != JSON.stringify(alikerroinMessage)){
        if (sounds){
          let playNormalNotification = false
          for (let i = 0; i < response.length; i++){
            const index = alikerroinMessage.findIndex(y => y.url === response[i].url)
            if (index < 0){
              playNormalNotification = true
            }
          }
  
          if (playNormalNotification){
            playBells()
          }
        }

        setAlikerroinMessage(response)
        console.log('AlikerroinMessage', response)
      }
    })
  }, 5700)

  useInterval(() => {
    tipsService.getNordicbet().then(response => {
      response.sort((b, a) => {
        const c = new Date(a.eventDate)
        const d = new Date(b.eventDate)
        return c-d
      })

      if (JSON.stringify(response) != JSON.stringify(nordicbet)){
        if (sounds){
          let playNormalNotification = false
          for (let i = 0; i < response.length; i++){
            const index = nordicbet.findIndex(y => y.url === response[i].url)
            if (index < 0){
              playNormalNotification = true
            }
          }
  
          if (playNormalNotification){
            playBells()
          }
        }

        setNordicbet(response)
        console.log('Nordicbet', response)
      }
    })
  }, 5100)

  useInterval(() => {
    tipsService.getTelegram().then(response => {
      response.sort((b, a) => {
        const c = new Date(a.date)
        const d = new Date(b.date)
        return c-d
      })
      
      if (JSON.stringify(response) != JSON.stringify(telegram)){
        if (sounds){
          let playNormalNotification = false
          for (let i = 0; i < response.length; i++){
            const index = telegram.findIndex(y => y._id === response[i]._id)
            if (index < 0){
              if (notificationTelegramUsers.includes(response[i].userName)){
                playNormalNotification = false
                i = response.length
                playJinglebells()
              }
              else if (!hiddenTelegramUsers.includes(response[i].userName)){
                playNormalNotification = true
              }
            }
          }
  
          if (playNormalNotification){
            playBells()
          }
        }

        setTelegram(response)
        console.log('Telegram', response)
      }
    })
  }, 1655)

  useInterval(() => {
    tipsService.getTwitter().then(response => {
      response.sort((b, a) => {
        const c = new Date(a.date)
        const d = new Date(b.date)
        return c-d
      })

      if (JSON.stringify(response) != JSON.stringify(twitter)){
        if (sounds){
          let playNormalNotification = false
          for (let i = 0; i < response.length; i++){
            if (!response[i].message.toLowerCase().includes("live")){
              const index = twitter.findIndex(y => y._id === response[i]._id)
              if (index < 0){
                if (notificationTwitterUsers.includes(response[i].user)){
                  playNormalNotification = false
                  i = response.length
                  playJinglebells()
                }
                else if (!hiddenTwitterUsers.includes(response[i].user)){
                  playNormalNotification = true
                }
              }
            }
          }
  
          if (playNormalNotification){
            playBells()
          }
        }

        setTwitter(response)
        console.log('Twitter', response)
      }
    })
  }, 1468)

  useInterval(() => {
    tipsService.getYlikerroin().then(response => {
      response.sort((b, a) => {
        const c = new Date(a.messageDate)
        const d = new Date(b.messageDate)
        return c-d
      })

      if (JSON.stringify(response) != JSON.stringify(ylikerroin)){
        if (sounds){
          let playNormalNotification = false
          for (let i = 0; i < response.length; i++){
            const index = ylikerroin.findIndex(y => y._id === response[i]._id)
            if (index < 0){
              if (notificationYlikerroinUsers.includes(response[i].user)){
                playNormalNotification = false
                i = response.length
                playJinglebells()
              }
              else if (!hiddenYlikerroinUsers.includes(response[i].user)){
                playNormalNotification = true
              }
            }
            else {
              if (ylikerroin[index].bets.length < response[i].bets.length){
                if (notificationYlikerroinUsers.includes(response[i].user)){
                  playNormalNotification = false
                  i = response.length
                  playJinglebells()
                }
                else if (!hiddenYlikerroinUsers.includes(response[i].user)){
                  playNormalNotification = true
                }
              }
            }
          }
  
          if (playNormalNotification){
            playBells()
          }
        }

        setYlikerroin(response)
        console.log('Ylikerroin', response)
      }
    })
  }, 1985)

  const handleAlikerroinMessageRemove = async (event) => {
    event.preventDefault()
    try {
      let removeOrRestore = ''
      let tempRemovedAlikerroinMessages = removedAlikerroinMessages
      if (!tempRemovedAlikerroinMessages.includes(event.target.id)){
        tempRemovedAlikerroinMessages.push(event.target.id)
        removeOrRestore = 'remove'
      }
      else {
        tempRemovedAlikerroinMessages = tempRemovedAlikerroinMessages.filter(e => e !== event.target.id)
        removeOrRestore = 'removeRestore'
      }

      let tempUserSettings = userSettings
      tempUserSettings.removedAlikerroinMessages = tempRemovedAlikerroinMessages
      setRemovedAlikerroinMessages(tempRemovedAlikerroinMessages)
      const tipsAccounting = {
        id: event.target.id,
        tipsType: 'AlikerroinMessage',
        tipsAccounting: removeOrRestore
      }
      updateTipsAccounting(tipsAccounting)

    } catch (exception) {
      console.log('exception', exception)
    }
  }

  const handleAlikerroinMessageWager = async (event) => {
    event.preventDefault()
    try {
      let wagerOrRestore = ''
      let tempWageredAlikerroinMessages = wageredAlikerroinMessages
      if (!tempWageredAlikerroinMessages.includes(event.target.id)){
        tempWageredAlikerroinMessages.push(event.target.id)
        wagerOrRestore = 'wager'
      }
      else {
        tempWageredAlikerroinMessages = tempWageredAlikerroinMessages.filter(e => e !== event.target.id)
        wagerOrRestore = 'wagerRestore'
      }

      let tempUserSettings = userSettings
      tempUserSettings.wageredAlikerroinMessages = tempWageredAlikerroinMessages
      setWageredAlikerroinMessages(tempWageredAlikerroinMessages)
      const tipsAccounting = {
        id: event.target.id,
        tipsType: 'AlikerroinMessage',
        tipsAccounting: wagerOrRestore
      }
      updateTipsAccounting(tipsAccounting)

    } catch (exception) {
      console.log('exception', exception)
    }
  }

  const handleNordicbetRemove = async (event) => {
    event.preventDefault()
    try {
      let removeOrRestore = ''
      let tempRemovedNordicbetMessages = removedNordicbetMessages
      if (!tempRemovedNordicbetMessages.includes(event.target.id)){
        tempRemovedNordicbetMessages.push(event.target.id)
        removeOrRestore = 'remove'
      }
      else {
        tempRemovedNordicbetMessages = tempRemovedNordicbetMessages.filter(e => e !== event.target.id)
        removeOrRestore = 'removeRestore'
      }

      let tempUserSettings = userSettings
      tempUserSettings.removedNordicbetMessages = tempRemovedNordicbetMessages
      setRemovedNordicbetMessages(tempRemovedNordicbetMessages)
      const tipsAccounting = {
        id: event.target.id,
        tipsType: 'Nordicbet',
        tipsAccounting: removeOrRestore
      }
      updateTipsAccounting(tipsAccounting)

    } catch (exception) {
      console.log('exception', exception)
    }
  }

  const handleNordicbetWager = async (event) => {
    event.preventDefault()
    try {
      let wagerOrRestore = ''
      let tempWageredNordicbetMessages = wageredNordicbetMessages
      if (!tempWageredNordicbetMessages.includes(event.target.id)){
        tempWageredNordicbetMessages.push(event.target.id)
        wagerOrRestore = 'wager'
      }
      else {
        tempWageredNordicbetMessages = tempWageredNordicbetMessages.filter(e => e !== event.target.id)
        wagerOrRestore = 'wagerRestore'
      }

      let tempUserSettings = userSettings
      tempUserSettings.wageredNordicbetMessages = tempWageredNordicbetMessages
      setWageredNordicbetMessages(tempWageredNordicbetMessages)
      const tipsAccounting = {
        id: event.target.id,
        tipsType: 'Nordicbet',
        tipsAccounting: wagerOrRestore
      }
      updateTipsAccounting(tipsAccounting)

    } catch (exception) {
      console.log('exception', exception)
    }
  }

  const handleTelegramRemove = async (event) => {
    event.preventDefault()
    try {
      let removeOrRestore = ''
      let tempRemovedTelegramMessages = removedTelegramMessages
      if (!tempRemovedTelegramMessages.includes(event.target.id)){
        tempRemovedTelegramMessages.push(event.target.id)
        removeOrRestore = 'remove'
      }
      else {
        tempRemovedTelegramMessages = tempRemovedTelegramMessages.filter(e => e !== event.target.id)
        removeOrRestore = 'removeRestore'
      }

      let tempUserSettings = userSettings
      tempUserSettings.removedTelegramMessages = tempRemovedTelegramMessages
      setRemovedTelegramMessages(tempRemovedTelegramMessages)
      const tipsAccounting = {
        id: event.target.id,
        tipsType: 'Telegram',
        tipsAccounting: removeOrRestore
      }
      updateTipsAccounting(tipsAccounting)

    } catch (exception) {
      console.log('exception', exception)
    }
  }

  const handleTelegramWager = async (event) => {
    event.preventDefault()
    try {
      let wagerOrRestore = ''
      let tempWageredTelegramMessages = wageredTelegramMessages
      if (!tempWageredTelegramMessages.includes(event.target.id)){
        tempWageredTelegramMessages.push(event.target.id)
        wagerOrRestore = 'wager'
      }
      else {
        tempWageredTelegramMessages = tempWageredTelegramMessages.filter(e => e !== event.target.id)
        wagerOrRestore = 'wagerRestore'
      }

      let tempUserSettings = userSettings
      tempUserSettings.wageredTelegramMessages = tempWageredTelegramMessages
      setWageredTelegramMessages(tempWageredTelegramMessages)
      const tipsAccounting = {
        id: event.target.id,
        tipsType: 'Telegram',
        tipsAccounting: wagerOrRestore
      }
      updateTipsAccounting(tipsAccounting)

    } catch (exception) {
      console.log('exception', exception)
    }
  }

  const handleTwitterRemove = async (event) => {
    event.preventDefault()
    try {
      let removeOrRestore = ''
      let tempRemovedTwitterMessages = removedTwitterMessages
      if (!tempRemovedTwitterMessages.includes(event.target.id)){
        tempRemovedTwitterMessages.push(event.target.id)
        removeOrRestore = 'remove'
      }
      else {
        tempRemovedTwitterMessages = tempRemovedTwitterMessages.filter(e => e !== event.target.id)
        removeOrRestore = 'removeRestore'
      }

      let tempUserSettings = userSettings
      tempUserSettings.removedTwitterMessages = tempRemovedTwitterMessages
      setRemovedTwitterMessages(tempRemovedTwitterMessages)
      const tipsAccounting = {
        id: event.target.id,
        tipsType: 'Twitter',
        tipsAccounting: removeOrRestore
      }
      updateTipsAccounting(tipsAccounting)

    } catch (exception) {
      console.log('exception', exception)
    }
  }

  const handleTwitterWager = async (event) => {
    event.preventDefault()
    try {
      let wagerOrRestore = ''
      let tempWageredTwitterMessages = wageredTwitterMessages
      if (!tempWageredTwitterMessages.includes(event.target.id)){
        tempWageredTwitterMessages.push(event.target.id)
        wagerOrRestore = 'wager'
      }
      else {
        tempWageredTwitterMessages = tempWageredTwitterMessages.filter(e => e !== event.target.id)
        wagerOrRestore = 'wagerRestore'
      }

      let tempUserSettings = userSettings
      tempUserSettings.wageredTwitterMessages = tempWageredTwitterMessages
      setWageredTwitterMessages(tempWageredTwitterMessages)
      const tipsAccounting = {
        id: event.target.id,
        tipsType: 'Twitter',
        tipsAccounting: wagerOrRestore
      }
      updateTipsAccounting(tipsAccounting)

    } catch (exception) {
      console.log('exception', exception)
    }
  }

  const handleYlikerroinRemove = async (event) => {
    event.preventDefault()
    try {
      let removeOrRestore = ''
      let tempRemovedYlikerroinMessages = removedYlikerroinMessages
      if (!tempRemovedYlikerroinMessages.includes(event.target.id)){
        tempRemovedYlikerroinMessages.push(event.target.id)
        removeOrRestore = 'remove'
      }
      else {
        tempRemovedYlikerroinMessages = tempRemovedYlikerroinMessages.filter(e => e !== event.target.id)
        removeOrRestore = 'removeRestore'
      }

      let tempUserSettings = userSettings
      tempUserSettings.removedYlikerroinMessages = tempRemovedYlikerroinMessages
      setRemovedYlikerroinMessages(tempRemovedYlikerroinMessages)
      const tipsAccounting = {
        id: event.target.id,
        tipsType: 'Ylikerroin',
        tipsAccounting: removeOrRestore
      }
      updateTipsAccounting(tipsAccounting)

    } catch (exception) {
      console.log('exception', exception)
    }
  }

  const handleYlikerroinWager = async (event) => {
    event.preventDefault()
    try {
      let wagerOrRestore = ''
      let tempWageredYlikerroinMessages = wageredYlikerroinMessages
      if (!tempWageredYlikerroinMessages.includes(event.target.id)){
        tempWageredYlikerroinMessages.push(event.target.id)
        wagerOrRestore = 'wager'
      }
      else {
        tempWageredYlikerroinMessages = tempWageredYlikerroinMessages.filter(e => e !== event.target.id)
        wagerOrRestore = 'wagerRestore'
      }

      let tempUserSettings = userSettings
      tempUserSettings.wageredYlikerroinMessages = tempWageredYlikerroinMessages
      setWageredYlikerroinMessages(tempWageredYlikerroinMessages)
      const tipsAccounting = {
        id: event.target.id,
        tipsType: 'Ylikerroin',
        tipsAccounting: wagerOrRestore
      }
      updateTipsAccounting(tipsAccounting)

    } catch (exception) {
      console.log('exception', exception)
    }
  }

  const handleYlikerroinBetRemove = async (event) => {
    event.preventDefault()
    try {
      let removeOrRestore = ''
      let tempRemovedYlikerroinBets = removedYlikerroinBets
      if (!tempRemovedYlikerroinBets.includes(event.target.id)){
        tempRemovedYlikerroinBets.push(event.target.id)
        removeOrRestore = 'remove'
      }
      else {
        tempRemovedYlikerroinBets = tempRemovedYlikerroinBets.filter(e => e !== event.target.id)
        removeOrRestore = 'removeRestore'
      }

      let tempUserSettings = userSettings
      tempUserSettings.removedYlikerroinBets = tempRemovedYlikerroinBets
      setRemovedYlikerroinBets(tempRemovedYlikerroinBets)
      const tipsAccounting = {
        id: event.target.id,
        tipsType: 'YlikerroinBet',
        tipsAccounting: removeOrRestore
      }
      updateTipsAccounting(tipsAccounting)

    } catch (exception) {
      console.log('exception', exception)
    }
  }

  const handleYlikerroinBetWager = async (event) => {
    event.preventDefault()
    try {
      let wagerOrRestore = ''
      let tempWageredYlikerroinBets = wageredYlikerroinBets
      if (!tempWageredYlikerroinBets.includes(event.target.id)){
        tempWageredYlikerroinBets.push(event.target.id)
        wagerOrRestore = 'wager'
      }
      else {
        tempWageredYlikerroinBets = tempWageredYlikerroinBets.filter(e => e !== event.target.id)
        wagerOrRestore = 'wagerRestore'
      }

      let tempUserSettings = userSettings
      tempUserSettings.wageredYlikerroinBets = tempWageredYlikerroinBets
      setWageredYlikerroinBets(tempWageredYlikerroinBets)
      const tipsAccounting = {
        id: event.target.id,
        tipsType: 'YlikerroinBet',
        tipsAccounting: wagerOrRestore
      }
      updateTipsAccounting(tipsAccounting)

    } catch (exception) {
      console.log('exception', exception)
    }
  }

  const saveUserSettings = (newUserSettings) => {
    try{
      console.log('Save user settings', newUserSettings)
      tipsService.setUserSettings(newUserSettings, props.token).then(response => {
        console.log('Saved user settings', response)
        setUserSettings(newUserSettings)
        /*setClassName('notification')
        setNotificationMessage(`Asetukset tallennettu`)
        setTimeout(() => {
          setClassName(null)
          setNotificationMessage(null)
        }, 5000)*/
      })
    } catch (exception) {
      console.log('Failed to save user settings', exception)
      setClassName('error')
      setNotificationMessage('Asetusten tallentaminen epäonnistui')
      setTimeout(() => {
        setClassName(null)
        setNotificationMessage(null)
      }, 5000)
    }
  }

  const updateTipsAccounting = (tipsAccounting) => {
    try{
      console.log('Update tips accounting', tipsAccounting)
      tipsService.setTipsAccounting(tipsAccounting, props.token).then(response => {
        console.log('Updated tips accounting', response)
      })
    } catch (exception) {
      console.log('Failed to Update tips accounting', exception)
      setClassName('error')
      setNotificationMessage('Päivittäminen epäonnistui')
      setTimeout(() => {
        setClassName(null)
        setNotificationMessage(null)
      }, 5000)
    }
  }

  const getOrderOfMessages = () => {
    let messages = []
    for (let i = 0; i < alikerroinMessage.length; i++){
      messages.push({id: alikerroinMessage[i]._id, date: alikerroinMessage[i].date, type: 'AlikerroinMessage'})
    }

    for (let i = 0; i < nordicbet.length; i++){
      messages.push({id: nordicbet[i]._id, date: nordicbet[i].tipsDate, type: 'Nordicbet'})
    }

    for (let i = 0; i < telegram.length; i++){
      messages.push({id: telegram[i]._id, date: telegram[i].date, type: 'Telegram'})
    }

    for (let i = 0; i < twitter.length; i++){
      if (!(twitter[i].message.toLowerCase().includes(" live") || twitter[i].message.toLowerCase().includes("live "))){
        messages.push({id: twitter[i]._id, date: twitter[i].date, type: 'Twitter'})
      }
    }

    for (let i = 0; i < ylikerroin.length; i++){
      messages.push({id: ylikerroin[i]._id, date: ylikerroin[i].messageDate, type: 'Ylikerroin'})
    }

    messages.sort((b, a) => {
      let c = new Date(a.date)
      let d = new Date(b.date)
      return c-d
    })

    return messages
  }

  const separated = () => {
    return(
      <div>
        <h2>AlikerroinMessage</h2>
        {alikerroinMessage.map(bet =>
            <AlikerroinMessage
            key={bet.url}
            bet={bet}
            removedAlikerroinMessages={removedAlikerroinMessages}
            wageredAlikerroinMessages={wageredAlikerroinMessages}
            showHiddenUsers={showHiddenUsers}
            showRemoved={showRemoved}
            showWagered={showWagered}
            showTitle={false}
            handleAlikerroinMessageRemove={handleAlikerroinMessageRemove}
            handleAlikerroinMessageWager={handleAlikerroinMessageWager}
          />
        )}
        <h2>Nordicbet</h2>
        {nordicbet.map(bet =>
            <Nordicbet
            key={bet.url}
            bet={bet}
            hiddenNordicbetUsers={hiddenNordicbetUsers}
            notificationNordicbetUsers={notificationNordicbetUsers}
            removedNordicbetMessages={removedNordicbetMessages}
            wageredNordicbetMessages={wageredNordicbetMessages}
            showHiddenUsers={showHiddenUsers}
            showRemoved={showRemoved}
            showWagered={showWagered}
            showTitle={false}
            handleNordicbetRemove={handleNordicbetRemove}
            handleNordicbetWager={handleNordicbetWager}
          />
        )}
        <h2>Telegram</h2>
        {telegram.map(message =>
          <Telegram
            key={message.date} message={message}
            hiddenTelegramUsers={hiddenTelegramUsers}
            notificationTelegramUsers={notificationTelegramUsers}
            removedTelegramMessages={removedTelegramMessages}
            wageredTelegramMessages={wageredTelegramMessages}
            showHiddenUsers={showHiddenUsers}
            showRemoved={showRemoved}
            showWagered={showWagered}
            showTitle={false}
            handleTelegramRemove={handleTelegramRemove}
            handleTelegramWager={handleTelegramWager}
          />
        )}
        <h2>Twitter</h2>
        {twitter.map(tweet =>
          <Twitter
            key={tweet.id}
            tweet={tweet}
            hiddenTwitterUsers={hiddenTwitterUsers}
            notificationTwitterUsers={notificationTwitterUsers}
            removedTwitterMessages={removedTwitterMessages}
            wageredTwitterMessages={wageredTwitterMessages}
            showHiddenUsers={showHiddenUsers}
            showRemoved={showRemoved}
            showWagered={showWagered}
            showTitle={false}
            handleTwitterRemove={handleTwitterRemove}
            handleTwitterWager={handleTwitterWager}
          />
        )}
        <h2>Ylikerroin</h2>
        {ylikerroin.map(yk =>
          <Ylikerroin
            key={yk._id}
            ylikerroin={yk}
            hiddenYlikerroinUsers={hiddenYlikerroinUsers}
            notificationYlikerroinUsers={notificationYlikerroinUsers}
            removedYlikerroinMessages={removedYlikerroinMessages}
            wageredYlikerroinMessages={wageredYlikerroinMessages}
            removedYlikerroinBets={removedYlikerroinBets}
            wageredYlikerroinBets={wageredYlikerroinBets}
            showHiddenUsers={showHiddenUsers}
            showRemoved={showRemoved}
            showWagered={showWagered}
            showTitle={false}
            handleYlikerroinRemove={handleYlikerroinRemove}
            handleYlikerroinWager={handleYlikerroinWager}
            handleYlikerroinBetRemove={handleYlikerroinBetRemove}
            handleYlikerroinBetWager={handleYlikerroinBetWager}
          />
        )}
      </div>
    )
  }

  const timeLine = () => {
    const messageOrder = getOrderOfMessages()
    let messages = []
    messageOrder.forEach(message =>{
      let index = -1
      switch (message.type) {
        case 'AlikerroinMessage':
          index = alikerroinMessage.findIndex(n => n._id === message.id)
          if (index >= 0){
            let alikerroinMessageMessage = alikerroinMessage[index]
            messages.push(
              <AlikerroinMessage
                key={alikerroinMessageMessage.date} message={alikerroinMessageMessage}
                removedAlikerroinMessages={removedAlikerroinMessages}
                wageredAlikerroinMessages={wageredAlikerroinMessages}
                showRemoved={showRemoved}
                showWagered={showWagered}
                showTitle={true}
                handleAlikerroinMessageRemove={handleAlikerroinMessageRemove}
                handleAlikerroinMessageWager={handleAlikerroinMessageWager}
              />
            )
          }

          break;
        case 'Nordicbet':
          index = nordicbet.findIndex(n => n._id === message.id)
          if (index >= 0){
            let nordicbetMessage = nordicbet[index]
            messages.push(
              <Nordicbet
                key={nordicbetMessage.url}
                bet={nordicbetMessage}
                hiddenNordicbetUsers={hiddenNordicbetUsers}
                notificationNordicbetUsers={notificationNordicbetUsers}
                removedNordicbetMessages={removedNordicbetMessages}
                wageredNordicbetMessages={wageredNordicbetMessages}
                showHiddenUsers={showHiddenUsers}
                showRemoved={showRemoved}
                showWagered={showWagered}
                showTitle={true}
                handleNordicbetRemove={handleNordicbetRemove}
                handleNordicbetWager={handleNordicbetWager}
            />
            )
          }

          break;
        case 'Telegram':
          index = telegram.findIndex(n => n._id === message.id)
          if (index >= 0){
            let telegramMessage = telegram[index]
            messages.push(
              <Telegram
                key={telegramMessage.date} message={telegramMessage}
                hiddenTelegramUsers={hiddenTelegramUsers}
                notificationTelegramUsers={notificationTelegramUsers}
                removedTelegramMessages={removedTelegramMessages}
                wageredTelegramMessages={wageredTelegramMessages}
                showHiddenUsers={showHiddenUsers}
                showRemoved={showRemoved}
                showWagered={showWagered}
                showTitle={true}
                handleTelegramRemove={handleTelegramRemove}
                handleTelegramWager={handleTelegramWager}
              />
            )
          }

          break;
        case 'Twitter':
          index = twitter.findIndex(n => n._id === message.id)
          if (index >= 0){
            let tweet = twitter[index]
            messages.push(
              <Twitter
                key={tweet.id}
                tweet={tweet}
                hiddenTwitterUsers={hiddenTwitterUsers}
                notificationTwitterUsers={notificationTwitterUsers}
                removedTwitterMessages={removedTwitterMessages}
                wageredTwitterMessages={wageredTwitterMessages}
                showHiddenUsers={showHiddenUsers}
                showRemoved={showRemoved}
                showWagered={showWagered}
                showTitle={true}
                handleTwitterRemove={handleTwitterRemove}
                handleTwitterWager={handleTwitterWager}
              />
            )
          }

          break;
        case 'Ylikerroin':
          index = ylikerroin.findIndex(n => n._id === message.id)
          if (index >= 0){
            let ylikerroinMessage = ylikerroin[index]
            messages.push(
              <Ylikerroin
                key={ylikerroinMessage._id}
                ylikerroin={ylikerroinMessage}
                hiddenYlikerroinUsers={hiddenYlikerroinUsers}
                notificationYlikerroinUsers={notificationYlikerroinUsers}
                removedYlikerroinMessages={removedYlikerroinMessages}
                wageredYlikerroinMessages={wageredYlikerroinMessages}
                removedYlikerroinBets={removedYlikerroinBets}
                wageredYlikerroinBets={wageredYlikerroinBets}
                showHiddenUsers={showHiddenUsers}
                showRemoved={showRemoved}
                showWagered={showWagered}
                showTitle={true}
                handleYlikerroinRemove={handleYlikerroinRemove}
                handleYlikerroinWager={handleYlikerroinWager}
                handleYlikerroinBetRemove={handleYlikerroinBetRemove}
                handleYlikerroinBetWager={handleYlikerroinBetWager}
              />
            )
          }

          break;
      }
    })

    return messages
  }

  return (
    <div className= {tipsStyle}>
      <input
          type="checkbox"
          onChange={(event) => setSounds(event.currentTarget.checked)}
          checked={sounds}
      />
      <label>Äänet&nbsp;&nbsp;&nbsp;&nbsp;</label>
      <input
          type="checkbox"
          onChange={(event) => setShowTimeLine(event.currentTarget.checked)}
          checked={showTimeLine}
      />
      <label>Näytä aikajana&nbsp;&nbsp;&nbsp;&nbsp;</label>
      <input
          type="checkbox"
          onChange={(event) => setShowHiddenUsers(event.currentTarget.checked)}
          checked={showHiddenUsers}
      />
      <label>Näytä piilotetut käyttäjät&nbsp;&nbsp;&nbsp;&nbsp;</label>
      <input
          type="checkbox"
          onChange={(event) => setShowRemoved(event.currentTarget.checked)}
          checked={showRemoved}
      />
      <label>Näytä poistetut&nbsp;&nbsp;&nbsp;&nbsp;</label>
      <input
          type="checkbox"
          onChange={(event) => setShowWagered(event.currentTarget.checked)}
          checked={showWagered}
      />
      <label>Näytä pelatut&nbsp;&nbsp;&nbsp;&nbsp;</label>
      <Notification message={notificationMessage} className = {className} />
      {showTimeLine ? timeLine() : separated()}
    </div>
  )
}

const useInterval = (callback, delay) => {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export default Tips