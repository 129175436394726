import React, {useEffect, useState } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { Form } from 'react-bootstrap';
import C from "../../constants";
import '../../../node_modules/react-datetime/css/react-datetime.css';

const VakioRows = (props) => {
    const [rows, setRows] = useState([]);
    const [fixtures, setFixtures] = useState(null);
    const [updateTime, setUpdateTime] = useState(1);
    const [showUpdateTime, setShowUpdateTime] = useState("Updating...");
    const [updatesTimeRunning, setUpdatesTimeRunning] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [percentages, setPercentages] = useState(null);
    const [availableEventTypes, setAvailableEventTypes] = useState([]);
    const [selectedEventTypeIds, setSelectedEventTypeIds] = useState([]);
    const [availableCompetitions, setAvailableCompetitions] = useState([]);
    const [selectedCompetitionIds, setSelectedCompetitionIds] = useState([]);
    const [availableEvents, setAvailableEvents] = useState([]);
    const [selectedEventIds, setSelectedEventIds] = useState([]);
    const [automatedEventIds, setAutomatedEventIds] = useState([]);
    const [availableMarkets, setAvailableMarkets] = useState([]);
    const [selectedMarketIds, setSelectedMarketIds] = useState([]);
    const [marketBook, setMarketBook] = useState('');
    const [overall, setOverall] = useState([0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]);
    const [eVlimit, seteVlimit] = useState(1.0);
    const [kellyLimit, setKellyLimit] = useState(0.0001);
    const [kellyDivider, setKellyDivider] = useState(1);
    const [winLimit, setWinLimit] = useState(1000);
    const [selectedRows, setSelectedRows] = useState([]);
    const [wageredRows, setWageredRows] = useState([]);
    const [totalProbability, setTotalProbability] = useState(0);
    const [totalWageredProbability, setTotalWageredProbability] = useState(0);
    const [showWageredRows, setShowWageredRows] = useState(false);
    const [resultRow, setResultRow] = useState('');
    const [wageredPercentages, setWageredPercentages] = useState('');

    const sleep = (waitTimeInMs) => new Promise(resolve => setTimeout(resolve, waitTimeInMs));

    useEffect(() => {
        getBetfair('listEventTypes');
    }, []);

    useEffect(() => {
        if (selectedEventTypeIds.length > 0)
        {
            getBetfair('listCompetitions');
        }
    }, [selectedEventTypeIds]);

    useEffect(() => {
        if (selectedCompetitionIds.length > 0)
        {
            getBetfair('listEvents');
        }
    }, [selectedCompetitionIds]);

    useEffect(() => {
        if (selectedEventIds.length > 0)
        {
            getBetfair('listMarketCatalogue');
        }
    }, [selectedEventIds]);

    useEffect(() => {
        async function getMarketIds() {
            if (automatedEventIds.length > 0)
            {
                for (let i = 0; i < fixtures.games.length; i++)
                {
                    if (fixtures.games[i].hasOwnProperty('betfairEventId'))
                    {
                        await sleep(200);
                        getBetfair('listMarketCatalogue', fixtures.games[i].betfairEventId);
                    }
                }
            }
        }

        getMarketIds();

    }, [automatedEventIds]);

    useEffect(() => {
        if (selectedMarketIds.length > 0)
        {
            getBetfair('listMarketBook');
        }
    }, [selectedMarketIds]);

    useEffect(() => {
        if (marketBook.length > 0)
        {
            updateBetfairPercentages();
        }
    }, [marketBook]);

    useEffect(() => {
        vakioRowsList()
        //updateMarketIds();

        if (selectedMarketIds.length > 0)
        {
            //getBetfair('listMarketBook');
        }

        calculateOveralls();
    }, [percentages]);

    useEffect(() => {
        try {
            let calculatedRows = [];
            for (let i = 0; i < rows.length; i++)
            {
                if (rows[i].eV >= eVlimit && (rows[i].kelly * 100 / kellyDivider) > kellyLimit && rows[i].win > winLimit)
                {
                    const rowBase3 = RadixConverter(rows[i].rowBase10, 10, 3);
                    calculatedRows.push({row: GetRowToShow(rowBase3), probability: rows[i].probability, eV: rows[i].eV, kelly: rows[i].kelly, win: rows[i].win, numberOfBets: rows[i].numberOfBets, accepted: false});
                }
            }
            if (calculatedRows.length > 0)
            {
                let sortedCalculatedRows = calculatedRows.sort((a, b) => (a.kelly > b.kelly) ? -1 : 1);
                sortedCalculatedRows = sortedCalculatedRows.filter((sortedCalculatedRows, index, self) =>
                    index === self.findIndex((t) => (t.row === sortedCalculatedRows.row)));

                setTotalProbability(sortedCalculatedRows.map(item => item.probability).reduce((prev, next) => prev + next));
                setSelectedRows(sortedCalculatedRows);
            }
        } catch(e) {
            console.log(e);
        }
    }, [eVlimit, kellyLimit, kellyDivider, winLimit, rows]);

    const updateBetfairPercentages = () => {
        let newPercentages = percentages;
        for (let i = 0; i < marketBook.length; i++) {
            for (let k = 0; k < fixtures.games.length; k++) {
                if (newPercentages[k][12] === marketBook[i].marketId) {
                    let oddsRow = [];
                    let normalizer = 0;

                    for (let j = 0; j < marketBook[i].runners.length; j++) {
                        if (marketBook[i].runners[j].lastPriceTraded > 0)
                        {
                            oddsRow.push(marketBook[i].runners[j].lastPriceTraded);
                            normalizer += 1 / marketBook[i].runners[j].lastPriceTraded;
                        }
                    }

                    if (oddsRow.length > 0 && normalizer > 0)
                    {
                        if (!(oddsRow[0] === 0 || oddsRow[1] === 0 || oddsRow[2] === 0))
                        {
                            normalizer = 1 / normalizer;
                            newPercentages[k][3] = Math.round(100 * ((normalizer) / oddsRow[0]) * 100) / 100;
                            newPercentages[k][5] = Math.round(100 * ((normalizer) / oddsRow[1]) * 100) / 100;
                            newPercentages[k][4] = Math.round(100 * ((normalizer) / oddsRow[2]) * 100) / 100;
                        }
                    }
                }
            }
        }

        setPercentages(newPercentages);
    };

    const getBetfair = async (endpoint, betfairEventId) => {
        if (props.token !== undefined && props.token !== null)
        {
            const headers = {
                'x-access-token': props.token
            }
    
            if (selectedMarketIds.length > 10 && endpoint === 'listMarketBook')
            {
                let params1 = { endpoint: endpoint, jsonRequest: createBetfairJsonRequest('listMarketBook1')};
                axios({
                    method: 'POST',
                    url: C.BETFAIR_BASE_URL + '/betfair',
                    params: params1,
                    headers: headers
                }).then(response => {
                    handleResults(endpoint, response.data);
                    if (selectedMarketIds.length > 10 && endpoint === 'listMarketBook')
                    {
                        let params2 = {endpoint: endpoint, jsonRequest: createBetfairJsonRequest('listMarketBook2')};
                        axios({
                            method: 'POST',
                            url: C.BETFAIR_BASE_URL + '/betfair',
                            params: params2,
                            headers: headers
                        }).then(response => {
                            handleResults(endpoint, response.data);
                        })
                            .catch(function (error){
                                console.log(error);
                            });
                    }})
                    .catch(function (error){
                        console.log(error);
                    });
            }
            else
            {
                let params = {endpoint: endpoint, jsonRequest: createBetfairJsonRequest(endpoint, betfairEventId)};
                axios({
                    method: 'POST',
                    url: C.BETFAIR_BASE_URL + '/betfair',
                    params: params,
                    headers: headers
                })
                    .then(response => {
                    handleResults(endpoint, response.data, betfairEventId);
                })
                    .catch(function (error){
                        console.log(error);
                    });
            }
        }
    };

    const createBetfairJsonRequest = (endpoint, betfairEventId) => {
        let result = {};
        let filters = {};

        switch(endpoint) {
            case 'listEventTypes':
                result = {filter: filters};
                break;
            case 'listCompetitions':
                if (selectedEventTypeIds.length > 0)
                {
                    filters['eventTypeIds'] = selectedEventTypeIds;
                }

                result = {filter: filters};
                break;
            case 'listEvents':
                if (selectedCompetitionIds.length > 0)
                {
                    filters['competitionIds'] = selectedCompetitionIds;
                }
                else
                {
                    filters['eventTypeIds'] = selectedEventTypeIds;
                    filters['marketStartTime'] = {from: new Date(), to: new Date(new Date().setDate(new Date().getDate()+3))};
                    filters['marketTypeCodes'] = ['MATCH_ODDS'];
                }

                result = {filter: filters};
                break;
            case 'listMarketCatalogue':

                if (betfairEventId)
                {
                    filters['eventIds'] = [betfairEventId];
                }
                else
                {
                    if (selectedEventIds.length > 0) {
                        filters['eventIds'] = selectedEventIds;
                    }
                }

                filters['marketTypeCodes'] = ['MATCH_ODDS'];
                result = {filter: filters};
                result['maxResults'] = 200;
                break;
            case 'listMarketBook':
                if (selectedMarketIds.length > 0)
                {
                    result['marketIds'] = selectedMarketIds;
                    result['priceProjection'] = {"priceData": ["EX_TRADED"]};
                }
                break;
            case 'listMarketBook1':
                result['marketIds'] = selectedMarketIds.slice(0, selectedMarketIds.length / 2);
                result['priceProjection'] = {"priceData": ["EX_TRADED"]};
                break;
            case 'listMarketBook2':
                result['marketIds'] = selectedMarketIds.slice(selectedMarketIds.length / 2);
                result['priceProjection'] = {"priceData": ["EX_TRADED"]};
                break;
            default:
                break;
        }

        return JSON.stringify(result);
    };

    const handleEventTypeClick = (event, id) => {
        if (selectedEventTypeIds.includes(id))
        {
            setSelectedEventTypeIds(selectedEventTypeIds.filter((e)=>(e !== id)));
        }
        else
        {
            setSelectedEventTypeIds(selectedEventTypeIds => [...selectedEventTypeIds, id]);
        }
    };

    const handleCompetitionClick = (event, id) => {
        if (selectedCompetitionIds.includes(id))
        {
            setSelectedCompetitionIds(selectedCompetitionIds.filter((e)=>(e !== id)));
        }
        else
        {
            setSelectedCompetitionIds(selectedCompetitionIds => [...selectedCompetitionIds, id]);
        }
    };

    const handleEventClick = (event, id) => {
        if (selectedEventIds.includes(id))
        {
            setSelectedEventIds(selectedEventIds.filter((e)=>(e !== id)));
        }
        else
        {
            setSelectedEventIds(selectedEventIds => [...selectedEventIds, id]);
        }
    };

    const handleMarketClick = (event, id) => {
        if (selectedMarketIds.includes(id))
        {
            setSelectedMarketIds(selectedMarketIds.filter((e)=>(e !== id)));
        }
        else
        {
            setSelectedMarketIds(selectedMarketIds => [...selectedMarketIds, id]);
        }
    };

    const eventTypeButtons = () => {
        return(
            availableEventTypes.map((eventType) => (
                <button type="button" name={eventType.eventType.id}
                        className = {selectedEventTypeIds.includes(eventType.eventType.id) ? "btn btn-primary" : "btn btn-info"}
                        onClick={(e) => handleEventTypeClick(e, eventType.eventType.id)}
                >{eventType.eventType.name}</button>
            )))
    };

    const competitionButtons = () => {
        return(
            availableCompetitions.map((competition) => (
                <button type="button" name={competition.competition.id}
                        className = {selectedCompetitionIds.includes(competition.competition.id) ? "btn btn-primary" : "btn btn-info"}
                        onClick={(e) => handleCompetitionClick(e, competition.competition.id)}
                >{competition.competition.name}</button>
            )))
    };

    const eventButtons = () => {
        return(
            availableEvents.map((event) => (
                <button type="button" name={event.event.id}
                        className = {selectedEventIds.includes(event.event.id) ? "btn btn-primary" : "btn btn-info"}
                        onClick={(e) => handleEventClick(e, event.event.id)}
                >{event.event.name}</button>
            )))
    };

    const marketButtons = () => {
        return(
            availableMarkets.map((market) => (
                <button type="button" name={market.marketId}
                        className = {selectedMarketIds.includes(market.marketId) ? "btn btn-primary" : "btn btn-info"}
                        onClick={(e) => handleMarketClick(e, market.marketId)}
                >{market.marketName}</button>
            )))
    };

    const betfairMarketIds = () => {
        let row = '';

        if (marketBook.length > 0)
        {
            for (let i = 0; i < marketBook.length; i++)
            {
                for (let j = 0; j < marketBook[i].runners.length; j++)
                {
                    row += marketBook[i].marketId + ' ';
                }
            }
        }

        return(
            <React.Fragment>
                <label>{row}</label>
            </React.Fragment>
        )
    };

    const handleResults = (endpoint, response, betfairEventId) => {
        switch(endpoint) {
            case 'listEventTypes':
                let filteredEventTypes = [];
                //const allowedEvents = ['Soccer', 'American Football', 'Basketball', 'Ice Hockey']
                const allowedEvents = ['Soccer']

                for (let i = 0; i < response.length; i++)
                {
                    if (allowedEvents.includes(response[i].eventType.name))
                    {
                        filteredEventTypes.push(response[i]);
                    }
                }

                setAvailableEventTypes(filteredEventTypes);
                getRows();
                break;
            case 'listCompetitions':
                console.log('listCompetitions', response);
                let sortedResponse = response.sort((a, b) => {
                    const textA = a.competitionRegion.toUpperCase();
                    const textB = b.competitionRegion.toUpperCase();
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                });
                setAvailableCompetitions(sortedResponse);
                break;
            case 'listEvents':
                setMarketIdsToVakio(response);
                break;
            case 'listMarketCatalogue':
                let matchOdds = [];
                let marketIds = [];
                console.log('selectedEventIds', selectedEventIds);
                console.log('listMarketCatalogue', response);
                let newFixtures = fixtures;
                let newPercentages = percentages;
                for (let i = 0; i < response.length; i++)
                {
                    if (response[i].marketName === 'Match Odds')
                    {
                        const index = newFixtures.games.findIndex((obj => obj.betfairEventId === betfairEventId));
                        newFixtures.games[index]['betfairMarketId'] = response[i].marketId;
                        console.log('listMarketCatalogue', response[i]);
                        matchOdds.push(response[i]);
                        marketIds.push(response[i].marketId);
                        newPercentages[newFixtures.games[index].id - 1][12] = response[i].marketId;
                        newPercentages[newFixtures.games[index].id - 1][9] = false;
                        newPercentages[newFixtures.games[index].id - 1][10] = true;
                    }
                }
                //setFixtures(newFixtures);
                setPercentages(newPercentages);
                setAvailableMarkets(matchOdds);
                setSelectedMarketIds(marketIds);
                break;
            case 'listMarketBook':
                console.log('listMarketBook', response);
                setMarketBook(response);
                break;
            default:
                break;
        }
    };

    const setMarketIdsToVakio = (events) => {
        console.log('events', events)

        return

        const teamsConvert = [
            { betfair: 'Sheff Utd', veikkaus: 'Sheffield U'},
            { betfair: 'Man City', veikkaus: 'Manchester C'},
            { betfair: 'Man Utd', veikkaus: 'Manchester U'},
            { betfair: 'West Brom', veikkaus: 'West Bromwich'},
            { betfair: 'Oxford Utd', veikkaus: 'Oxford U'},
            { betfair: 'Norwich', veikkaus: 'Norwich C'},
            { betfair: 'Newcastle', veikkaus: 'Newcastle U'},
            { betfair: 'Crystal Palace', veikkaus: 'Crystal P'},
            { betfair: 'Wolves', veikkaus: 'Wolverhampton'},
            { betfair: 'Cardiff', veikkaus: 'Cardiff C'},
            { betfair: 'Reading', veikkaus: 'Reading FC'},
            { betfair: 'Coventry', veikkaus: 'Coventry C'},
            { betfair: 'Bristol City', veikkaus: 'Bristol C'},
            { betfair: 'Bristol Rovers', veikkaus: 'Bristol R'},
            { betfair: 'Fleetwood Town', veikkaus: 'Fleetwood'},
            { betfair: 'Ipswich', veikkaus: 'Ipswich T'},
            { betfair: 'Lincoln', veikkaus: 'Lincoln C'},
            { betfair: 'Peterborough', veikkaus: 'Peterborough U'},
            { betfair: 'Leeds', veikkaus: 'Leeds U'},
            { betfair: 'Nottm Forest', veikkaus: 'Nottingham'},
            { betfair: 'Sheff Wed', veikkaus: 'Sheffield W'},
            { betfair: 'Blackpool', veikkaus: 'Blackpool FC'},
            { betfair: 'Burton', veikkaus: 'Burton Albion'},
            { betfair: 'Oxford Utd', veikkaus: 'Oxford U'},
            { betfair: 'Accrington', veikkaus: 'Accrington S'},
            { betfair: 'Bayern Munich', veikkaus: 'Bayern München'},
            { betfair: 'Sociedad', veikkaus: 'Real Sociedad'},
            { betfair: 'Newport County', veikkaus: 'Newport Co'},
            { betfair: 'Aldershot', veikkaus: 'Aldershot T'},
            { betfair: 'Dag and Red', veikkaus: 'Dagenham&Red.'},
        ];

        let newFixtures = fixtures;
        let foundEventIds = [];
        for (let i = 0; i < events.length; i++)
        {
            if (events[i].hasOwnProperty('event'))
            {
                if (events[i].event.hasOwnProperty('name'))
                {
                    let teams = events[i].event.name.split(' v ');

                    if (teams.length === 2)
                    {
                        const index0 = teamsConvert.findIndex((obj => obj.betfair === teams[0]));
                        if (index0 >= 0)
                        {
                            teams[0] = teamsConvert[index0].veikkaus;
                        }

                        const index1 = teamsConvert.findIndex((obj => obj.betfair === teams[1]));
                        if (index1 >= 0)
                        {
                            teams[1] = teamsConvert[index1].veikkaus;
                        }

                        for (let j = 0; j < fixtures.games.length; j++)
                        {
                            if (teams[0] === fixtures.games[j].home && teams[1] === fixtures.games[j].away)
                            {
                                //console.log('teams', teams);
                                foundEventIds.push(events[i].event.id);
                                console.log('eventId', events[i].event.id, teams);
                                newFixtures.games[j]['betfairEventId'] = events[i].event.id;
                            }
                        }
                    }
                }
            }
        }

        setFixtures(newFixtures);
        setAvailableEvents(events);

        if (foundEventIds.length > 0)
        {
            setAutomatedEventIds(foundEventIds);
        }
    };

    const getRows = () => {
        let id = props.location.pathname;
        setIsLoading(true);
        setShowUpdateTime("Updating...");
        axios.get(C.BASE_URL +id, {headers: { 'x-access-token': props.token }})
            .then(response => {
                console.log('getrows response', response.data);

                switch(response.data.fixtures.name) {
                    case 'Jalkapallo':
                        setSelectedEventTypeIds(['1']);
                        break;
                    case 'Jääkiekko':
                        setSelectedEventTypeIds(['7524']);
                        break;
                    case 'Koripallo':
                        setSelectedEventTypeIds(['7522']);
                        break;
                    case 'Am. jalkapallo':
                        setSelectedEventTypeIds(['6423']);
                        break;
                }

                let initResultRow = '';
                let initArray = new Array(response.data.fixtures.games.length);
                for (let i = 0; i < initArray.length; i++) {
                    //const index = response.data.veikkausPercentages.findIndex((obj => obj.veikkausId === response.data.fixtures.games[i].veikkausId));
                    let veikkausPercentages = [];
                    //if (index < 0)
                    //{
                        veikkausPercentages.push(0);
                        veikkausPercentages.push(0);
                        veikkausPercentages.push(0);
                    //}
                    //else
                    //{
                        //veikkausPercentages.push(response.data.veikkausPercentages[index].percentages[0]);
                        //veikkausPercentages.push(response.data.veikkausPercentages[index].percentages[1]);
                        //veikkausPercentages.push(response.data.veikkausPercentages[index].percentages[2]);
                    //}

                    /*initArray[i] = [veikkausPercentages[0], veikkausPercentages[1], veikkausPercentages[2],
                        response.data.percentages[i].betfairPercentages[0],
                        response.data.percentages[i].betfairPercentages[1],
                        response.data.percentages[i].betfairPercentages[2],
                        response.data.percentages[i].customPercentages[0],
                        response.data.percentages[i].customPercentages[1],
                        response.data.percentages[i].customPercentages[2],
                        response.data.percentages[i].useVeikkausPercentages,
                        response.data.percentages[i].useBetfairPercentages,
                        response.data.percentages[i].useCustomPercentages,
                        response.data.percentages[i].betfairMarketId]*/

                    initArray[i] = [veikkausPercentages[0], veikkausPercentages[1], veikkausPercentages[2],
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        1,
                        0]

                    initArray[i].push(overall)
                    initResultRow += '1'
                }

                setResultRow(initResultRow)

                setFixtures(response.data.fixtures)
                setPercentages(initArray)
                setRows(response.data.rows)
                if (!updatesTimeRunning)
                {
                    getUpdateTime(response.data.fixtures.id)
                }

                setUpdatesTimeRunning(true)
                setIsLoading(false)
                //vakioRowsList()
            })
            .catch(error => {
                console.log(error);
            })
    };

    const getWageredRows = () => {
        const headers = {
            'x-access-token': props.token
        };

        let id = props.location.pathname.substring(10);

        console.log('fixtures', fixtures);

        axios.get(C.BASE_URL + '/vakiowager' + id, {headers: headers})
            .then(response => {
                console.log(response);

                setTotalWageredProbability(response.data.results.map(item => item.probability).reduce((prev, next) => prev + next));
                let newWageredRows = response.data.results;

                for (let i = 0; i < newWageredRows.length; i++)
                {
                    let counter = 0;
                    for (let j = 0; j < newWageredRows[i].row.length; j++)
                    {
                        if (newWageredRows[i].row.charAt([j]) === resultRow.charAt([j]))
                        {
                            counter++;
                        }
                    }

                    newWageredRows[i]['result'] = counter;
                }

                newWageredRows = newWageredRows.sort((a, b) => (a.result > b.result) ? -1 : 1);
                console.log('newWageredPercentages', response.data.percentages);
                setWageredRows(newWageredRows);
                setWageredPercentages(response.data.percentages);
            });
    };

    const vakioRowsList = () => {
        if (fixtures && percentages)
        {
            //console.log('vakioRowsList fixtures', fixtures)
            //console.log('vakioRowsList percentages', percentages)
            let vakioRows = [];
            for (let i = 0; i < fixtures.games.length; i++)
            {
                vakioRows.push(<VakioRow game={fixtures.games[i]} percentage={percentages[i]} id={i}/>)
            }

            return vakioRows;
        }
        else
        {
            return null;
        }
    };

    const vakioResultRowsList = () => {
        if (fixtures && percentages)
        {
            let vakioResultRows = [];
            for (let i = 0; i < fixtures.games.length; i++)
            {
                vakioResultRows.push(<VakioResultRow game={fixtures.games[i]} percentage={percentages[i]} id={i}/>);
            }

            return vakioResultRows;
        }
        else
        {
            return null;
        }
    };

    const listWageredRows = () => {
        try {
            if (wageredRows && wageredRows.length > 0)
            {
                return(
                    wageredRows.map((wageredRow) => (
                        <tr>
                            <td><label>{wageredRow.row}</label></td>
                            <td><label>{wageredRow.result}</label></td>
                            <td><label>{precise(wageredRow.probability * 100, 3)}</label></td>
                            <td><label>{wageredRow.eV}</label></td>
                            <td><label>{(wageredRow.kelly * 100 / kellyDivider).round(5)} %</label></td>
                            <td><label>{wageredRow.win}</label></td>
                            <td><label>{wageredRow.numberOfBets}</label></td>
                            <td><label>{((Math.floor((10000 * (wageredRow.kelly / kellyDivider)) * 100)) / 100).toFixed(2)}</label></td>
                        </tr>
                    )))
            }
            return null;
        } catch(e) {
            console.log(e);
            return null;
        }
    };

    const handleInputChange = (event) => {
        let newPercentages = percentages;
        switch(event.target.name) {
            case 'C1':
                newPercentages[event.target.id][6] = parseFloat(event.target.value);
                break;
            case 'CX':
                newPercentages[event.target.id][7] = parseFloat(event.target.value);
                break;
            case 'C2':
                newPercentages[event.target.id][8] = parseFloat(event.target.value);
                break;
            case 'useVeikkaus':
                newPercentages[event.target.id][9] = !newPercentages[event.target.id][9];
                break;
            case 'useBetfair':
                newPercentages[event.target.id][10] = !newPercentages[event.target.id][10];
                break;
            case 'useCustom':
                newPercentages[event.target.id][11] = !newPercentages[event.target.id][11];
                break;
            case 'betfairMarketId':
                newPercentages[event.target.id][12] = event.target.value;
                break;
            default:
                break;
        }

        setPercentages(newPercentages);
        setShowUpdateTime("Updating..." + newPercentages[event.target.id][0] + newPercentages[event.target.id][1] + newPercentages[event.target.id][2]
            + newPercentages[event.target.id][3] + newPercentages[event.target.id][4] + newPercentages[event.target.id][5]
            + newPercentages[event.target.id][6] + newPercentages[event.target.id][7] + newPercentages[event.target.id][8]);
    };

    const handleSelectChange = (event, id) => {
        let newResultRow =  resultRow;
        console.log('result', event, id);
        newResultRow = newResultRow.replaceAt(id, event.value);
        setResultRow(newResultRow);
    };

    const options = [
        { value: '1', label: '1' },
        { value: 'X', label: 'X' },
        { value: '2', label: '2' }
    ]

    //<td>{props.game.id + '. ' +  props.game.home + ' - ' +  props.game.away}</td>

    const VakioRow = props => (
            <tr>
                <td>{props.id + 1}. {props.game}</td>
                <td><input type="checkbox" id={props.id} name={"useVeikkaus"} checked={props.percentage[9]} onChange={handleInputChange}/></td>
                <td><label id={props.id}>{props.percentage[0]}</label></td>
                <td><label id={props.id}>{props.percentage[1]}</label></td>
                <td><label id={props.id}>{props.percentage[2]}</label></td>
                <td><input type="checkbox" id={props.id} name={"useBetfair"} checked={props.percentage[10]} onChange={handleInputChange}/></td>
                <td><label id={props.id}>{props.percentage[3]}</label></td>
                <td><label id={props.id}>{props.percentage[4]}</label></td>
                <td><label id={props.id}>{props.percentage[5]}</label></td>
                <td><input type="checkbox" id={props.id} name={"useCustom"} checked={props.percentage[11]} onChange={handleInputChange}/></td>
                <td><input type="number" id={props.id} name={"C1"} value={props.percentage[6]} onChange={handleInputChange}/></td>
                <td><input type="number" id={props.id} name={"CX"} value={props.percentage[7]} onChange={handleInputChange}/></td>
                <td><input type="number" id={props.id} name={"C2"} value={props.percentage[8]} onChange={handleInputChange}/></td>
                <td><label id={props.id}>{overall[props.game.id - 1]}</label></td>
                <td><input type="text" id={props.id} name={"betfairMarketId"} value={props.percentage[12].toString()} onChange={handleInputChange}/></td>
            </tr>
    )

    const VakioResultRow = props => (
        <tr>
            <td>{props.game.id + '. ' +  props.game.home + ' - ' +  props.game.away}</td>
            <td><Select value={{value: resultRow.charAt(props.game.id - 1), label: resultRow.charAt(props.game.id - 1)}} options = {options} onChange={(e) => handleSelectChange(e, props.id)}/></td>
            <td><label>{wageredPercentages[props.game.id - 1][0].round(2)}</label></td>
            <td><label>{wageredPercentages[props.game.id - 1][1].round(2)}</label></td>
            <td><label>{wageredPercentages[props.game.id - 1][2].round(2)}</label></td>
        </tr>
    );

    const calculateOveralls = () => {
        if (fixtures)
        {
            let newOveralls = [];
            for (let i = 0; i < fixtures.games.length; i++)
            {
                let newOverall = 0;
                if (percentages[i][9])
                {
                    newOverall = percentages[i][0] + percentages[i][1] + percentages[i][2];
                }
                else if (percentages[i][10])
                {
                    newOverall = percentages[i][3] + percentages[i][4] + percentages[i][5];
                }
                else if (percentages[i][11])
                {
                    newOverall = percentages[i][6] + percentages[i][7] + percentages[i][8];
                }
                else
                {
                    newOverall = 0;
                }

                newOveralls.push(Math.round(100 * newOverall) / 100);
            }

            setOverall(newOveralls);
        }
    };

    const handleSavePercentages = () => {
        const headers = {
            'x-access-token': props.token
        };

        let id = props.location.pathname.substring(10);
        let newPercentages = [];

        for (let i = 0; i < fixtures.games.length; i++)
        {
            let newPercentage = {};
            newPercentage['veikkausPercentages'] = [percentages[i][0], percentages[i][1], percentages[i][2]];
            newPercentage['betfairPercentages'] = [percentages[i][3], percentages[i][4], percentages[i][5]];
            newPercentage['customPercentages'] = [percentages[i][6], percentages[i][7], percentages[i][8]];
            newPercentage['useVeikkausPercentages'] = percentages[i][9];
            newPercentage['useBetfairPercentages'] = percentages[i][10];
            newPercentage['useCustomPercentages'] = percentages[i][11];
            newPercentage['betfairMarketId'] = percentages[i][12];
            newPercentages.push(newPercentage);
        }

        axios.post(C.BASE_URL + '/vakiopercentages' + id, newPercentages, {headers: headers})
            .then(res => setShowUpdateTime(res.body));
    };

    const handleWager = () => {
        const headers = {
            'x-access-token': props.token
        };

        let id = props.location.pathname.substring(10);

        const data = {rows: selectedRows, basePrice: fixtures.basePrice}

        axios.post(C.BASE_URL + '/vakiowager' + id, data, {headers: headers})
            .then(res => console.log(res.body));
    };

    const updateMarketIds = () => {
        if (fixtures)
        {
            let marketIds = [];
            for (let i = 0; i < fixtures.games.length; i++)
            {
                if (percentages[i][12] > 0)
                {
                    marketIds.push(percentages[i][12]);
                }
            }

            setSelectedMarketIds(marketIds);
        }
    };

    const getUpdateTime = (id) => {
        try {
            setInterval(async () => {
                console.log('updateTime', updateTime);
                if (!isLoading)
                {
                    axios.get(C.BASE_URL + "/vakioupdatetime/" + id, {headers: { 'x-access-token': props.token }})
                        .then(response => {
                            console.log('response.data', response.data);
                            const date = new Date(response.data.mtime).getTime();
                            //setShowUpdateTime(new Date(response.data.mtime.toString()));

                            console.log('date', date);
                            console.log('updateTime', updateTime);
                            console.log('date - updateTime', date - updateTime);

                            if (date > updateTime)
                            {
                                console.log('RELOAD ROWS');
                                //getRows();
                            }

                            setUpdateTime(date);
                            console.log('response.data', response.data.mtime);
                        })
                        .catch(function (error) {
                            console.log(error);
                        })
                }
            }, 1000000);
        } catch(e) {
            console.log(e);
        }
    };

    const listRows = () => {
        try {
            if (selectedRows && selectedRows.length > 0)
            {
                return(
                    selectedRows.map((calculatedRow) => (
                        <tr>
                            <td><label>{calculatedRow.row}</label></td>
                            <td><label>{precise(calculatedRow.probability * 100, 3)}</label></td>
                            <td><label>{calculatedRow.eV}</label></td>
                            <td><label>{(calculatedRow.kelly * 100 / kellyDivider).round(5)} %</label></td>
                            <td><label>{calculatedRow.win}</label></td>
                            <td><label>{calculatedRow.numberOfBets}</label></td>
                            <td><label>{((Math.floor((10000 * (calculatedRow.kelly / kellyDivider)) * 100)) / 100).toFixed(2)}</label></td>
                        </tr>
                    )))
            }
            return null;
        } catch(e) {
            console.log(e);
            return null;
        }
    };

    const RadixConverter = (input, from_base, to_base) => {
        let output = "";
        if (input !== undefined) {
            if (to_base >= 2 && to_base <= 36) {
                output = parseInt(input, from_base).toString(to_base);
            }
        }
        return output;
    };

    const GetRowToShow = (rowBase3) => {
        let rowToShow = "";
        if (rowBase3 !== undefined) {
            for (let i = 0; i < rowBase3.length; i++)
            {
                if (rowBase3.charAt(i) === '0')
                {
                    rowToShow += '1';
                }
                else if (rowBase3.charAt(i) === '1')
                {
                    rowToShow += 'X';
                }
                else if (rowBase3.charAt(i) === '2')
                {
                    rowToShow += '2';
                }
            }

            while (rowToShow.length < fixtures.games.length)
            {
                rowToShow = '1' + rowToShow;
            }
        }

        return rowToShow;
    };

    const handleeVInputChange = (event) => {
        seteVlimit(event.target.value);
    };

    const handleKellyInputChange = (event) => {
        setKellyLimit(event.target.value);
    };

    const handleKellyDividerInputChange = (event) => {
        setKellyDivider(event.target.value);
    };

    const handleWinLimitInputChange = (event) => {
        setWinLimit(event.target.value);
    };

    const vakioRowsTable = () => {
        return (<React.Fragment>
            <table className="table table-hover" style={{ marginTop: 20 }} >
                <thead>
                <tr>
                    <th>Kohteet</th>
                    <th>Veikkaus</th>
                    <th>1 %</th>
                    <th>X %</th>
                    <th>2 %</th>
                    <th>Betfair</th>
                    <th>1 %</th>
                    <th>X %</th>
                    <th>2 %</th>
                    <th>Omat</th>
                    <th>1 %</th>
                    <th>X %</th>
                    <th>2 %</th>
                    <th>Yhteensä</th>
                    <th>BetfairId</th>
                </tr>
                </thead>
                <tbody>
                { vakioRowsList() }
                </tbody>
            </table>
        </React.Fragment>);
    };

    const vakioResultRowsTable = () => {
        return (<React.Fragment>
            <table className="table table-hover" style={{ marginTop: 20 }} >
                <thead>
                <tr>
                    <th>Kohteet</th>
                    <th>Oikea merkki</th>
                    <th>1 %</th>
                    <th>X %</th>
                    <th>2 %</th>
                </tr>
                </thead>
                <tbody>
                { vakioResultRowsList() }
                </tbody>
            </table>
        </React.Fragment>);
    };

    const selectedRowsTable = () => {
        if (fixtures && selectedRows.length > 0)
        {
            return (<React.Fragment>
                <label>Rivejä yhteensä: {selectedRows.length} </label>
                <label>, Kokonaishinta: {selectedRows.length * fixtures.basePrice}</label>
                <label>, Kokonaistodennäköisyys: {precise(totalProbability * 100, 3)} %</label>
                <table className="table table-hover" style={{ marginTop: 20 }} >
                    <thead>
                    <tr>
                        <th>Rivi</th>
                        <th>Todennäköisyys %</th>
                        <th>eV</th>
                        <th>Kelly</th>
                        <th>Voittosumma</th>
                        <th>Rivejä</th>
                        <th>Panos</th>
                    </tr>
                    </thead>
                    <tbody>
                    { listRows() }
                    </tbody>
                </table>
            </React.Fragment>);
        }

        return null;
    };

    const wageredRowsTable = () => {
        if (wageredRows && wageredRows.length > 0)
        {
            return (<React.Fragment>
                <label>Rivejä yhteensä: {wageredRows.length} </label>
                <label>, Kokonaishinta: {wageredRows.length * fixtures.basePrice}</label>
                <label>, Kokonaistodennäköisyys: {precise(totalWageredProbability * 100, 3)} %</label>
                <table className="table table-hover" style={{ marginTop: 20 }} >
                    <thead>
                    <tr>
                        <th>Rivi</th>
                        <th>Oikein</th>
                        <th>Todennäköisyys %</th>
                        <th>eV</th>
                        <th>Kelly</th>
                        <th>Voittosumma</th>
                        <th>Rivejä</th>
                        <th>Panos</th>
                    </tr>
                    </thead>
                    <tbody>
                    { listWageredRows() }
                    </tbody>
                </table>
            </React.Fragment>);
        }

        return null;
    };

    const precise = (value, precision) => {
        return Number.parseFloat(value).toPrecision(precision);
    };

    const rowsToShow = () => {
        if (!showWageredRows)
        {
            return (<React.Fragment>
                <Form inline>
                <Form.Label>eV limit</Form.Label>
                <Form.Control inline label="1"
                              name="ev"
                              type="number"
                              step='0.1'
                              value={eVlimit}
                              onChange={handleeVInputChange} />
            <Form.Label>Kelly limit</Form.Label>
            <Form.Control inline label="2"
                          name="kelly"
                          type="number"
                          step='0.00001'
                          value={kellyLimit}
                          onChange={handleKellyInputChange} />

                <Form.Label>Kelly jakaja</Form.Label>
                <Form.Control inline label="3"
                              name="kellyDivider"
                              type="number"
                              step='1'
                              value={kellyDivider}
                              onChange={handleKellyDividerInputChange} />

            <Form.Label>Voittoraja</Form.Label>
            <Form.Control inline label="4"
                          name="winLimit"
                          type="number"
                          step='1000'
                          value={winLimit}
                          onChange={handleWinLimitInputChange} />
                </Form>
                <br/>
                { selectedRowsTable() }
            </React.Fragment>);
        }
        else
        {
            return (<React.Fragment>
                {vakioResultRowsTable()}
                {wageredRowsTable()}
            </React.Fragment>);
        }
    };

    Number.prototype.round = function(places) {
        return +(Math.round(this + "e+" + places)  + "e-" + places);
    };

    String.prototype.replaceAt=function(index, char) {
        var a = this.split("");
        a[index] = char;
        return a.join("");
    };

    return (
        <React.Fragment>
            <label>{showUpdateTime} </label>
            { vakioRowsTable() }
            <br/>
            { eventTypeButtons() }
            <button className="btn btn-primary" onClick={()=>{getBetfair('listEvents')}}>Hae kaikki pelit</button>
            <br/>
            { competitionButtons() }
            <br/>
            { eventButtons() }
            <br/>
            { marketButtons() }
            <br/>
            { betfairMarketIds() }
            <br/>
            <br/>
            <button className="btn btn-primary" onClick={handleSavePercentages}>Tallenna</button>
            <br/>
            <br/>
            <button className="btn btn-primary" onClick={handleWager}>Pelaa rivit</button>
            <br/>
            <br/>
            <button className="btn btn-primary" onClick={getWageredRows}>Hae pelatut rivit</button>
            <br/>
            <Form.Group inline>
                <Form.Check label="Näytä pelatut rivit" checked={showWageredRows} onClick={(event) => setShowWageredRows(event.currentTarget.checked)}/>
            </Form.Group>
            <br/>
            { rowsToShow() }
            <br/>
        </React.Fragment>
    );
};

export default VakioRows;