import React, { useState } from 'react'
import TweetEmbed from 'react-tweet-embed'

const Twitter = (props) => {
  const betStyle = {
    paddingLeft: 5,
    'border-radius': '5px',
    border: 'solid',
    borderWidth: 2,
    marginBottom: 10,
    'background-color': 'DeepSkyBlue'
  }

  let removeText = 'Poista'
  let showRemoveButton = true
  let wagerText = 'Pelattu'
  let shoWagerButton = true

  const [showOriginal, setShowOriginal] = useState(false)
  const date = new Date(props.tweet.date)

  if (!props.showRemoved && (props.removedTwitterMessages && props.removedTwitterMessages.includes(props.tweet.id))){
    return null
  }
  else if (props.showRemoved && (props.removedTwitterMessages && props.removedTwitterMessages.includes(props.tweet.id))){
    betStyle['background-color'] = 'DarkGrey'
    shoWagerButton = false
    removeText = 'Palauta'
  }
  else if (!props.showWagered && (props.wageredTwitterMessages && props.wageredTwitterMessages.includes(props.tweet.id))){
    return null
  }
  else if (props.showWagered && (props.wageredTwitterMessages && props.wageredTwitterMessages.includes(props.tweet.id))){
    betStyle['background-color'] = 'Chartreuse'
    showRemoveButton = false
    wagerText = 'Palauta'
  }
  else if (!props.showHiddenUsers && props.hiddenTwitterUsers && props.hiddenTwitterUsers.length > 0 && props.hiddenTwitterUsers.includes(props.tweet.user)){
    return null
  }

  if (props.notificationTwitterUsers && props.notificationTwitterUsers.length > 0 && props.notificationTwitterUsers.includes(props.tweet.user)){
    betStyle['border-style'] = 'double'
    betStyle['border-width'] = '20px'
  }

  if (new Date().getTime() - date.getTime() > 10 * 24 * 60 * 60 * 1000){
    return null
  }

  const day = date.getDate()
  const month = date.getMonth() + 1
  const year = date.getFullYear()
  const hours = date.getHours()
  let minutes = date.getMinutes()

  if (minutes.toString().length < 2){
    minutes = '0' + minutes.toString();
  }

  let title = ''
  if (props.showTitle){
    title = ' - Twitter'
  }

  let message = props.tweet.message
  const index = message.indexOf("#")
  if (index > 0 && message.includes("#WeAreTheGreatest")){
    message = message.substring(0, index)
  }

  const index2 = message.indexOf("@")
  if (index2 > 0 && message.includes("@FreeBetRetweets")){
    message = message.substring(0, index2)
  }

  return (
    <div style={betStyle} onClick={() => setShowOriginal(!showOriginal)}>
      <div>
        <h4>
          {props.tweet.user} {day}.{month}.{year} {hours}:{minutes} {title}
        </h4>
      </div>
      <pre>{message}</pre>
      <p>
        {showRemoveButton ? <button className="btn btn-outline-secondary btn-sm" id={props.tweet.id} onClick={props.handleTwitterRemove}>{removeText}</button> : null }
        {shoWagerButton ? <button className="btn btn-outline-success btn-sm" id={props.tweet.id} onClick={props.handleTwitterWager}>{wagerText}</button> : null }
      </p>
      {showOriginal ? <TweetEmbed id={props.tweet.id} options={{}}/> : null}
    </div>
  )}

  const NewlineText = rawText => {
    const text = rawText
    const newText = text.split('\n').map(str => <p>{str}</p>)
    
    return newText;
  }

export default Twitter