import React from 'react';
import { connect } from 'react-redux';

import Aux from '../Auxiliary/Auxiliary';
import classes from './Layout.css';
import Toolbar from '../../components/Navigation/Toolbar/Toolbar';

const Layout = props => {
  return (
    <Aux>
      <Toolbar
        isAuth={props.isAuthenticated}
        isAdvanced= {props.isAdvanced}
      />
      <main className={classes.Content}>{props.children}</main>
    </Aux>
  );
};

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.token !== null,
    isAdvanced: state.auth.isAdvanced
  };
};

export default connect(mapStateToProps)(Layout)