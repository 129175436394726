import React, { useState, useEffect, useRef } from 'react'
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import  * as Datetime from 'react-datetime';
import moment from 'moment';
import PropTypes from 'prop-types';
import C from "../../constants";
import '../../../node_modules/react-datetime/css/react-datetime.css';
import axios from "axios";
import {Link} from "react-router-dom";
import { convertBetfairIdsToPinnacleIds, convertVeikkausIdsToPinnacleIds, getNames } from "../../combineSites"
import { getPinnacleBetDetails, getBetfairBetDetails, getVeikkausBetDetails } from "../../betDetails"
import { betfairIds } from '../../betfairIds';
import { Form } from 'react-bootstrap';

const CurrentBets = (props) => {
    const [pinnacleBets, setPinnacleBets] = useState([])
    const [betfairBets, setBetfairBets] = useState([])
    const [veikkausBets, setVeikkausBets] = useState([])
    const [betsToShow, setBetsToShow] = useState([])

    useEffect(() => {
      getCurrentPinnacleBets()
      getCurrentBetfairBets()
      getCurrentVeikkausBets()
    }, [])

    useEffect(() => {
      parseBets()
    }, [pinnacleBets, betfairBets, veikkausBets]);

    let pinnacleStyle = {
      'background-color': 'PowderBlue'
    }

    let betfairBackStyle = {
      'background-color': 'LightSkyBlue'
    }

    let betfairLayStyle = {
      'background-color': 'LavenderBlush'
    }

    let veikkausStyle = {
      'background-color': 'LightCyan'
    }
    
    const getCurrentPinnacleBets = async () => {
      if (props.token !== undefined && props.token !== null)
      {
        const headers = {
            'x-access-token': props.token
        }

        axios({
            method: 'GET',
            url: C.BETFAIR_BASE_URL + '/pinnacle/currentbets',
            headers: headers
        }).then(response => {
          console.log(response.data)
          setPinnacleBets(response.data)
            })
            .catch(error =>{
                console.log(error);
            });
      }
    }

    const getCurrentBetfairBets = async () => {
      if (props.token !== undefined && props.token !== null)
      {
        const headers = {
            'x-access-token': props.token
        }

        axios({
            method: 'GET',
            url: C.BETFAIR_BASE_URL + '/betfair/currentbets',
            headers: headers
        }).then(response => {
          console.log(response.data)
          setBetfairBets(response.data)
            })
            .catch(error =>{
                console.log(error);
            });
      }
    }

    const getCurrentVeikkausBets = async () => {
      if (props.token !== undefined && props.token !== null)
      {
        const headers = {
            'x-access-token': props.token
        }

        axios({
            method: 'GET',
            url: C.BETFAIR_BASE_URL + '/veikkaus/currentbets',
            headers: headers
        }).then(response => {
          console.log(response.data)
          setVeikkausBets(response.data)
            })
            .catch(error =>{
                console.log(error);
            });
      }
    }

    useInterval(() => {
      getCurrentPinnacleBets()
      getCurrentBetfairBets()
      getCurrentVeikkausBets()
    }, 1000000)

    const parseBets = () => {
      try {
        let bets = []
        let combinedPinnacleBets = []
        for (let i = 0; i < pinnacleBets.length; i++){
          const p = pinnacleBets[i]
          const index = combinedPinnacleBets.findIndex(c => c.betType === p.betType && c.eventId === p.eventId &&  c.handicap === p.handicap && c.teamName === p.teamName && c.uniqueRequestId !== p.uniqueRequestId)
          if (index >= 0){

            const newRisk = p.risk + combinedPinnacleBets[index].risk
            console.log(p, combinedPinnacleBets[index], newRisk, p.risk, combinedPinnacleBets[index].risk)
            combinedPinnacleBets[index].price = (p.price * (p.risk / newRisk)) + ((combinedPinnacleBets[index].price * (combinedPinnacleBets[index].risk / newRisk)))
            combinedPinnacleBets[index].risk = newRisk
          }
          else {
            combinedPinnacleBets.push(p)
          }
        }
  
        let combinedBetfairBets = []
        for (let i = 0; i < betfairBets.length; i++){
          const b = betfairBets[i]
          const index = combinedBetfairBets.findIndex(c => c.marketId === b.marketId && c.selectionId === b.selectionId && c.side === b.side)
          if (index >= 0){
            const newRisk = b.sizeMatched + combinedBetfairBets[index].sizeMatched
            combinedBetfairBets[index].averagePriceMatched = (b.averagePriceMatched * (b.sizeMatched / newRisk)) + ((combinedBetfairBets[index].averagePriceMatched * (combinedBetfairBets[index].sizeMatched / newRisk)))
            combinedBetfairBets[index].sizeMatched = newRisk
          }
          else {
            combinedBetfairBets.push(b)
          }
        }

        let combinedVeikkausBets = []
        for (let i = 0; i < veikkausBets.length; i++){
          const v = veikkausBets[i]
          const index = combinedVeikkausBets.findIndex(c => c.event.externalId === v.event.externalId && c.event.id === v.event.id && c.wager.selections[0].rowId === v.wager.selections[0].rowId)
          if (index >= 0){
            const newRisk = v.wager.price + combinedVeikkausBets[index].wager.price
            combinedVeikkausBets[index].wager.selections[0].competitors.mainCompetitors[0] = (v.wager.selections[0].competitors.mainCompetitors[0] * (v.wager.price / newRisk)) + ((combinedVeikkausBets[index].wager.selections[0].competitors.mainCompetitors[0] * (combinedVeikkausBets[index].wager.price / newRisk)))
            combinedVeikkausBets[index].wager.price = newRisk
          }
          else {
            combinedVeikkausBets.push(v)
          }

          //combinedVeikkausBets.push(v)
        }
  
        let date = new Date()
        date.setDate(date.getDate() - 20)
  
        for (let i = 0; i < combinedPinnacleBets.length; i++){
          if (new Date(combinedPinnacleBets[i].eventStartTime).getTime() > date.getTime())
          {
            const names = getNames(combinedPinnacleBets[i].sportId, combinedPinnacleBets[i].sportName, combinedPinnacleBets[i].leagueId, combinedPinnacleBets[i].leagueName)
            const betDetails = getPinnacleBetDetails(combinedPinnacleBets[i])
            const bet = {
              date: combinedPinnacleBets[i].eventStartTime,
              sportName: names.sportName,
              leagueName: names.leagueName,
              home: betDetails.home,
              away: betDetails.away,
              betType: betDetails.betType,
              handicap: betDetails.handicap,
              bet: betDetails.bet,
              odds: (combinedPinnacleBets[i].price).toFixed(3),
              stake: (combinedPinnacleBets[i].risk).toFixed(2),
              side: "BACK",
              site: "Pinnacle",
              style: pinnacleStyle
            }
    
            bets.push(bet)
          }
        }
  
        for (let i = 0; i < combinedBetfairBets.length; i++){
          if (new Date(combinedBetfairBets[i].openDate).getTime() > date.getTime()){
            const ids = convertBetfairIdsToPinnacleIds(parseInt(combinedBetfairBets[i].eventTypeId), parseInt(combinedBetfairBets[i].competitionId))
            const names = getNames(ids.sportId, combinedBetfairBets[i].eventTypeName, ids.leagueId, combinedBetfairBets[i].competitionName)
            const betDetails = getBetfairBetDetails(combinedBetfairBets[i])
    
            let bet = {
              date: combinedBetfairBets[i].openDate,
              sportName: names.sportName,
              leagueName: names.leagueName,
              home: betDetails.home,
              away: betDetails.away,
              betType: betDetails.betType,
              handicap: betDetails.handicap,
              bet: betDetails.bet,
              odds: betDetails.odds.toFixed(3),
              stake: betDetails.stake.toFixed(2),
              side: combinedBetfairBets[i].side,
              site: "Betfair",
              style: combinedBetfairBets[i].side === "BACK" ? betfairBackStyle : betfairLayStyle
            }
    
            bets.push(bet)
          }
        }

        for (let i = 0; i < combinedVeikkausBets.length; i++){
          //if (new Date(combinedVeikkausBets[i].draw.resultsAvailableTime).getTime() > date.getTime()){
            let name = combinedVeikkausBets[i].draw.rows[0].detailedDescription
            if (name.length === 0){
              name = combinedVeikkausBets[i].draw.rows[0].name
            }

            const ids = convertVeikkausIdsToPinnacleIds(parseInt(combinedVeikkausBets[i].event.sportId), parseInt(combinedVeikkausBets[i].event.categoryId), parseInt(combinedVeikkausBets[i].event.tournamentId))
            const names = getNames(ids.sportId, combinedVeikkausBets[i].event.sportName, ids.leagueId, combinedVeikkausBets[i].event.categoryName, combinedVeikkausBets[i].event.tournamentName, name)
            const betDetails = getVeikkausBetDetails(combinedVeikkausBets[i])

            let odds = parseInt(combinedVeikkausBets[i].wager.selections[0].competitors.spare[0]) / 100
            let stake = parseInt(combinedVeikkausBets[i].wager.price) / 100
    
            let bet = {
              date: combinedVeikkausBets[i].draw.drawTime,
              sportName: names.sportName,
              leagueName: names.leagueName,
              home: betDetails.home,
              away: betDetails.away,
              betType: betDetails.betType,
              handicap: betDetails.handicap,
              bet: betDetails.bet,
              odds: odds.toFixed(3),
              stake: stake.toFixed(2),
              side: "BACK",
              site: "Veikkaus",
              style: veikkausStyle
            }
    
            bets.push(bet)
          //}
        }
  
        bets.sort((a, b) => {
          const c = new Date(a.date)
          const d = new Date(b.date)
          return c-d
        })
  
        setBetsToShow(bets)
      } catch (error) {
        console.log(error)
      }
    }

    const showBets = () => {
      try {
        return (<React.Fragment>
          <table className="table table-hover" style={{ marginTop: 20 }} >
          <thead>
          <tr>
              <th>Aika</th>
              <th>Laji</th>
              <th>Liiga</th>
              <th>Koti</th>
              <th>Vieras</th>
              <th>Vetotyyppi</th>
              <th>Tasoitus</th>
              <th>Veto</th>
              <th>Kerroin</th>
              <th>Panos</th>
              <th>Vedonlyöntiyhtiö</th>
          </tr>
          </thead>
          <tbody>
          { listBets() }
          </tbody>
        </table>
        </React.Fragment>)
      } catch (error) {
        console.log(error)
      }
    }

    const listBets = () => {
      try {
        if (betsToShow && betsToShow.length > 0)
        {
          return(
            betsToShow.map(bet => 
              (
              <tr style={bet.style}>
                <td><label>{new Date(bet.date).toLocaleString()}</label></td>
                <td><label>{bet.sportName}</label></td>
                <td><label>{bet.leagueName}</label></td>
                <td><label>{bet.home}</label></td>
                <td><label>{bet.away}</label></td>
                <td><label>{bet.betType}</label></td>
                <td><label>{bet.handicap}</label></td>
                <td><label>{bet.bet}</label></td>
                <td><label>{bet.odds}</label></td>
                <td><label>{bet.stake}</label></td>
                <td><label>{bet.site}</label></td>
              </tr>
            )))
        }
        return null;
    } catch(e) {
        console.log(e);
        return null;
    }
    }

    return (
        <React.Fragment>
            { showBets() }
        </React.Fragment>
    );
};

const useInterval = (callback, delay) => {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export default CurrentBets;