import React, { useState, useEffect } from 'react'
import tipsService from './service'
import Notification from '../Notification'
import './UserSettings.css'
import usePushNotifications from "../../usePushNotifications";

const telegramUsers = [
  "Abay",
  "Cohen",
  "Inter",
  "MnKpks",
  "Trapper",
  "Andrea",
  "Daniel Rantanen",
  "Juuso Tikanoja",
  "ValueWolves"
]

const twitterUsers = [
  "Aapo Mutanen",
  "Aki Hiltunen",
  "Arto Torvinen",
  "Coolbet Suomi",
  "Daniel Rantanen",
  "Daniel Wiström",
  "Esa Kostet",
  "Iiro Valkeejärvi",
  "Jani Pellinen",
  "Janne Hakulinen",
  "Janne Kostamovaara",
  "Joonatan Vuorela",
  "Juha Koivula",
  "Jukka Ritvanen",
  "Jumpankulma",
  "Jussi Hietalahti",
  "Juuso Tikanoja",
  "Kahva",
  "Kari Rajainmäki",
  "Lawelly Betting",
  "Marko Virtanen",
  "Mika Kuronen",
  "Miko Punto",
  "Mikko Koskenranta",
  "Mikko YK",
  "Nifty",
  "Niko Hynynen",
  "Olli Koski",
  "Samuli Saukko",
  "Simon Persson",
  "Tatu Kallio",
  "Tommi Kukila",
  "Toni",
  "Toni Wilén",
  "Waari",
  "#TeamAbay"
]

const ylikerroinUsers = [
  "abel",
  "alejandro",
  "Apina286",
  "bethog",
  "cordoba",
  "credit",
  "Daiki Ito",
  "Dennis",
  "E.Clipperton",
  "eliask",
  "EPO",
  "eTips",
  "fouba",
  "Gambler",
  "guessingames",
  "GWS",
  "hcnimi",
  "Henkka",
  "HR Juz",
  "J-Math",
  "JaAh",
  "Jage",
  "Jagr68",
  "Jallari",
  "Jantsa_",
  "Jarna",
  "jayson",
  "Jii",
  "JMT",
  "juha96",
  "JussiQ",
  "JuuH",
  "K1m",
  "Kahva",
  "KoMi",
  "maibou",
  "makezcr",
  "Manny Big Money",
  "Manny Hockey",
  "Mansen Pukki",
  "Marksman",
  "Massimo",
  "matu14",
  "McJesus",
  "miigs",
  "Mr.Brightside",
  "Mungo",
  "mutanen_aapo",
  "Nbb",
  "NewKidOnTheBlock",
  "Nils Holgersson",
  "NM",
  "Numerokeno_",
  "Nöörthuug",
  "odds",
  "Onnenpeli",
  "Peesaaja",
  "putkinen",
  "rakastaja",
  "randeL",
  "rase",
  "realDonaldTrump",
  "RightinHendrix",
  "ron",
  "Råbe",
  "sage123",
  "SandyMoger",
  "Setämies",
  "SirAlex",
  "sotamayor",
  "Snooker-Salming",
  "S.Sukkela",
  "Sählääjä",
  "Taika",
  "tesiis",
  "Tenkrat",
  "Tipsaaja",
  "toimitusjohtaja",
  "Tokio2030",
  "TommiK",
  "tophias",
  "Tornado",
  "TTiainen",
  "urheilupoika",
  "Valgro",
  "valiovihje.com"
]

const UserSettings = (props) => {
  const [userSettings, setUserSettings] = useState([])
  const [hideNordicbet, setHideNordicbet] = useState(false)
  const [hiddenTelegramUsers, setHiddenTelegramUsers] = useState({})
  const [notificationTelegramUsers, setNotificationTelegramUsers] = useState({})
  const [hiddenTwitterUsers, setHiddenTwitterUsers] = useState({})
  const [notificationTwitterUsers, setNotificationTwitterUsers] = useState({})
  const [hiddenYlikerroinUsers, setHiddenYlikerroinUsers] = useState({})
  const [notificationYlikerroinUsers, setNotificationYlikerroinUsers] = useState({})
  const [className, setClassName] = useState(null)
  const [notificationMessage, setNotificationMessage] = useState(null)

  const {
    userConsent,
    pushNotificationSupported,
    userSubscription,
    onClickAskUserPermission,
    onClickSusbribeToPushNotification,
    onClickSendTipsSubscriptionToPushServer,
    pushServerSubscriptionId,
    onClickSendTipsNotification,
    error,
    loading
  } = usePushNotifications({token: props.token});

  useEffect(() => {
    tipsService.getUserSettings(props.token).then(response => {
      console.log('UserSettings', response)

      let tempHiddenTelegramUsers = {}
      for(let u = 0; u < response.hiddenTelegramUsers.length; u++){
        tempHiddenTelegramUsers[response.hiddenTelegramUsers[u]] = true
      }

      setHiddenTelegramUsers(tempHiddenTelegramUsers)

      let tempNotificationTelegramUsers = {}
      for(let u = 0; u < response.notificationTelegramUsers.length; u++){
        tempNotificationTelegramUsers[response.notificationTelegramUsers[u]] = true
      }

      setNotificationTelegramUsers(tempNotificationTelegramUsers)

      let tempHiddenTwitterUsers = {}
      for(let u = 0; u < response.hiddenTwitterUsers.length; u++){
        tempHiddenTwitterUsers[response.hiddenTwitterUsers[u]] = true
      }

      setHiddenTwitterUsers(tempHiddenTwitterUsers)

      let tempNotificationTwitterUsers = {}
      for(let u = 0; u < response.notificationTwitterUsers.length; u++){
        tempNotificationTwitterUsers[response.notificationTwitterUsers[u]] = true
      }

      setNotificationTwitterUsers(tempNotificationTwitterUsers)

      let tempHiddenYlikerroinUsers = {}
      for(let u = 0; u < response.hiddenYlikerroinUsers.length; u++){
        tempHiddenYlikerroinUsers[response.hiddenYlikerroinUsers[u]] = true
      }

      setHiddenYlikerroinUsers(tempHiddenYlikerroinUsers)

      let tempNotificationYlikerroinUsers = {}
      for(let u = 0; u < response.notificationYlikerroinUsers.length; u++){
        tempNotificationYlikerroinUsers[response.notificationYlikerroinUsers[u]] = true
      }

      setNotificationYlikerroinUsers(tempNotificationYlikerroinUsers)

      if (response.hiddenNordicbetUsers.length > 0){
        setHideNordicbet(true)
      }
      else {
        setHideNordicbet(false)
      }

      setUserSettings(response)
    })
  }, [])

  const handleSaveUserSettings = () => {
    try {
      let tempHiddenTelegramUsers = []
      for (const key in hiddenTelegramUsers) {
        if (hiddenTelegramUsers.hasOwnProperty(key)) {
            console.log(`${key}: ${hiddenTelegramUsers[key]}`);
            if (hiddenTelegramUsers[key]){
              tempHiddenTelegramUsers.push(key)
              //console.log(`Save ${key}: ${hiddenTelegramUsers[key]}`);
            }
        }
      }

      let tempNotificationTelegramUsers = []
      for (const key in notificationTelegramUsers) {
        if (notificationTelegramUsers.hasOwnProperty(key)) {
            console.log(`${key}: ${notificationTelegramUsers[key]}`);
            if (notificationTelegramUsers[key]){
              tempNotificationTelegramUsers.push(key)
              //console.log(`Save ${key}: ${notificationTelegramUsers[key]}`);
            }
        }
      }

      let tempHiddenTwitterUsers = []
      for (const key in hiddenTwitterUsers) {
        if (hiddenTwitterUsers.hasOwnProperty(key)) {
            console.log(`${key}: ${hiddenTwitterUsers[key]}`);
            if (hiddenTwitterUsers[key]){
              tempHiddenTwitterUsers.push(key)
              //console.log(`Save ${key}: ${hiddenTwitterUsers[key]}`);
            }
        }
      }

      let tempNotificationTwitterUsers = []
      for (const key in notificationTwitterUsers) {
        if (notificationTwitterUsers.hasOwnProperty(key)) {
            console.log(`${key}: ${notificationTwitterUsers[key]}`);
            if (notificationTwitterUsers[key]){
              tempNotificationTwitterUsers.push(key)
              //console.log(`Save ${key}: ${notificationTwitterUsers[key]}`);
            }
        }
      }

      let tempHiddenYlikerroinUsers = []
      for (const key in hiddenYlikerroinUsers) {
        if (hiddenYlikerroinUsers.hasOwnProperty(key)) {
            console.log(`${key}: ${hiddenYlikerroinUsers[key]}`);
            if (hiddenYlikerroinUsers[key]){
              tempHiddenYlikerroinUsers.push(key)
              //console.log(`Save ${key}: ${hiddenYlikerroinUsers[key]}`);
            }
        }
      }

      let tempNotificationYlikerroinUsers = []
      for (const key in notificationYlikerroinUsers) {
        if (notificationYlikerroinUsers.hasOwnProperty(key)) {
            console.log(`${key}: ${notificationYlikerroinUsers[key]}`);
            if (notificationYlikerroinUsers[key]){
              tempNotificationYlikerroinUsers.push(key)
              //console.log(`Save ${key}: ${notificationYlikerroinUsers[key]}`);
            }
        }
      }
  
      let newUserSettings = userSettings
      newUserSettings.hiddenTelegramUsers = tempHiddenTelegramUsers
      newUserSettings.notificationTelegramUsers = tempNotificationTelegramUsers
      newUserSettings.hiddenTwitterUsers = tempHiddenTwitterUsers
      newUserSettings.notificationTwitterUsers = tempNotificationTwitterUsers
      newUserSettings.hiddenYlikerroinUsers = tempHiddenYlikerroinUsers
      newUserSettings.notificationYlikerroinUsers = tempNotificationYlikerroinUsers
  
      if (hideNordicbet){
        newUserSettings.hiddenNordicbetUsers = ['all']
      }
      else {
        newUserSettings.hiddenNordicbetUsers = []
      }
  
      console.log('Save user settings', newUserSettings)
      tipsService.setUserSettings(newUserSettings, props.token).then(response => {
        console.log('Saved user settings', response)
        setClassName('notification')
        setNotificationMessage(`Asetukset tallennettu`)
        setTimeout(() => {
          setClassName(null)
          setNotificationMessage(null)
        }, 5000)
      })
    } catch (exception) {
      console.log('Falied to save user settings', exception)
      setClassName('error')
      setNotificationMessage('Asetusten tallentaminen epäonnistui')
      setTimeout(() => {
        setClassName(null)
        setNotificationMessage(null)
      }, 5000)
    }
  }

  const handleHiddenTelegramChange = (key, value) => {
    let tempHiddenTelegramUsers = hiddenTelegramUsers
    tempHiddenTelegramUsers[key] = value
    setHiddenTelegramUsers(tempHiddenTelegramUsers)
  }

  const handleNotificationTelegramChange = (key, value) => {
    let tempNotificationTelegramUsers = notificationTelegramUsers
    tempNotificationTelegramUsers[key] = value
    setNotificationTelegramUsers(tempNotificationTelegramUsers)
  }

  const handleHiddenTwitterChange = (key, value) => {
    let tempHiddenTwitterUsers = hiddenTwitterUsers
    tempHiddenTwitterUsers[key] = value
    setHiddenTwitterUsers(tempHiddenTwitterUsers)
  }

  const handleNotificationTwitterChange = (key, value) => {
    let tempNotificationTwitterUsers = notificationTwitterUsers
    tempNotificationTwitterUsers[key] = value
    setNotificationTwitterUsers(tempNotificationTwitterUsers)
  }

  const handleHiddenYlikerroinChange = (key, value) => {
    let tempHiddenYlikerroinUsers = hiddenYlikerroinUsers
    tempHiddenYlikerroinUsers[key] = value
    setHiddenYlikerroinUsers(tempHiddenYlikerroinUsers)
  }

  const handleNotificationYlikerroinChange = (key, value) => {
    let tempNotificationYlikerroinUsers = notificationYlikerroinUsers
    tempNotificationYlikerroinUsers[key] = value
    setNotificationYlikerroinUsers(tempNotificationYlikerroinUsers)
  }

  const showHiddenTelegramUsers = () => {
    let users = []
    for(let u = 0; u < telegramUsers.length; u++){
      users.push(<div>
        <input
            key={telegramUsers[u]}
            type="checkbox"
            onChange={(event) => handleHiddenTelegramChange(telegramUsers[u], event.currentTarget.checked)}
            checked={hiddenTelegramUsers[telegramUsers[u]]}
        />
        <label>{telegramUsers[u]}</label>
      </div>)
    }

    return users
  }

  const showNotificationTelegramUsers = () => {
    let users = []
    for(let u = 0; u < telegramUsers.length; u++){
      users.push(<div>
        <input
            key={telegramUsers[u]}
            type="checkbox"
            onChange={(event) => handleNotificationTelegramChange(telegramUsers[u], event.currentTarget.checked)}
            checked={notificationTelegramUsers[telegramUsers[u]]}
        />
        <label>{telegramUsers[u]}</label>
      </div>)
    }

    return users
  }

  const showHiddenTwitterUsers = () => {
    let users = []
    for(let u = 0; u < twitterUsers.length; u++){
      users.push(<div>
        <input
            key={twitterUsers[u]}
            type="checkbox"
            onChange={(event) => handleHiddenTwitterChange(twitterUsers[u], event.currentTarget.checked)}
            checked={hiddenTwitterUsers[twitterUsers[u]]}
        />
        <label>{twitterUsers[u]}</label>
      </div>)
    }

    return users
  }

  const showNotificationTwitterUsers = () => {
    let users = []
    for(let u = 0; u < twitterUsers.length; u++){
      users.push(<div>
        <input
            key={twitterUsers[u]}
            type="checkbox"
            onChange={(event) => handleNotificationTwitterChange(twitterUsers[u], event.currentTarget.checked)}
            checked={notificationTwitterUsers[twitterUsers[u]]}
        />
        <label>{twitterUsers[u]}</label>
      </div>)
    }

    return users
  }

  const showHiddenYlikerroinUsers = () => {
    let users = []
    for(let u = 0; u < ylikerroinUsers.length; u++){
      users.push(<div>
        <input
            key={ylikerroinUsers[u]}
            type="checkbox"
            onChange={(event) => handleHiddenYlikerroinChange(ylikerroinUsers[u], event.currentTarget.checked)}
            checked={hiddenYlikerroinUsers[ylikerroinUsers[u]]}
        />
        <label>{ylikerroinUsers[u]}</label>
      </div>)
    }

    return users
  }

  const showNotificationYlikerroinUsers = () => {
    let users = []
    for(let u = 0; u < ylikerroinUsers.length; u++){
      users.push(<div>
        <input
            key={ylikerroinUsers[u]}
            type="checkbox"
            onChange={(event) => handleNotificationYlikerroinChange(ylikerroinUsers[u], event.currentTarget.checked)}
            checked={notificationYlikerroinUsers[ylikerroinUsers[u]]}
        />
        <label>{ylikerroinUsers[u]}</label>
      </div>)
    }

    return users
  }

  const Loading = ({ loading }) => (loading ? <div className="app-loader">Please wait, we are loading something...</div> : null);
    const Error = ({ error }) =>
        error ? (
            <section className="app-error">
                <h2>{error.name}</h2>
                <p>Error message : {error.message}</p>
                <p>Error code : {error.code}</p>
            </section>
        ) : null;

    const isConsentGranted = userConsent === "granted";

  return (
    <div>
      <button className="btn btn-primary" onClick={handleSaveUserSettings}>Tallenna asetukset</button>
      <Notification message={notificationMessage} className = {className} />
      <br/>
      <input
        type="checkbox"
        onChange={(event) => setHideNordicbet(event.currentTarget.checked)}
        checked={hideNordicbet}
      />
      <label>Piilota Nordicbet</label>
      <br/>
      <h4>Piilota Telegramin käyttäjä</h4>
      {showHiddenTelegramUsers()}
      <br/>
      <h4>Tilaa Telegramin käyttäjän hälytys</h4>
      {showNotificationTelegramUsers()}
      <br/>
      <h4>Piilota Twitterin käyttäjä</h4>
      {showHiddenTwitterUsers()}
      <br/>
      <h4>Tilaa Twitterin käyttäjän hälytys</h4>
      {showNotificationTwitterUsers()}
      <br/>
      <h4>Piilota Ylikertoimen käyttäjä</h4>
      {showHiddenYlikerroinUsers()}
      <br/>
      <h4>Tilaa Ylikertoimen käyttäjän hälytys</h4>
      {showNotificationYlikerroinUsers()}
      <br/>

      <Loading loading={loading} />

      <p>Push notification are {!pushNotificationSupported && "NOT"} supported by your device.</p>

      <p>
          User consent to recevie push notificaitons is <strong>{userConsent}</strong>.
      </p>

      <Error error={error} />

      <button disabled={!pushNotificationSupported || isConsentGranted} onClick={onClickAskUserPermission}>
          {isConsentGranted ? "Consent granted" : " Ask user permission"}
      </button>

      <button disabled={!pushNotificationSupported || !isConsentGranted || userSubscription} onClick={onClickSusbribeToPushNotification}>
          {userSubscription ? "Push subscription created" : "Create Notification subscription"}
      </button>

      <button disabled={!userSubscription || pushServerSubscriptionId} onClick={onClickSendTipsSubscriptionToPushServer}>
          {pushServerSubscriptionId ? "Subscrption sent to the server" : "Send subscription to push server"}
      </button>

      {pushServerSubscriptionId && (
          <div>
              <p>The server accepted the push subscrption!</p>
              <button onClick={onClickSendTipsNotification}>Send a notification</button>
          </div>
      )}
      <section>
        <h4>Your notification subscription details</h4>
        <pre>
            <code>{JSON.stringify(userSubscription, null, " ")}</code>
        </pre>
    </section>
    </div>
  )
}

export default UserSettings