import React, { useState } from 'react'
import PropTypes from 'prop-types'

const YlikerroinBet = (props) => {
  let betStyle = {
    paddingTop: 10,
    paddingLeft: 8,
    paddingRight: 15,
    'border-radius': '5px',
    border: 'solid',
    borderWidth: 1,
    marginRight: 5,
    marginBottom: 10,
    'background-color': '#f3f3f7'
  }

  const date = new Date(props.bet.eventDate)
  const day = date.getDate()
  const month = date.getMonth() + 1
  const year = date.getFullYear()
  const hours = date.getHours()
  let minutes = date.getMinutes()

  if (minutes.toString().length < 2){
    minutes = '0' + minutes.toString()
  }

  const betString = getBetString(props.bet)
  
  let quoteString = ""
  if (props.bet.isQuote){
    quoteString = "  (lainattu viesti)"
    betStyle['background-color'] = '#ebeadd'
  }

  let removeText = 'Poista'
  let showRemoveButton = true
  let wagerText = 'Pelattu'
  let shoWagerButton = true

  if (!props.showRemoved && (props.removedYlikerroinBets && props.removedYlikerroinBets.includes(betString))){
    return null
  }
  else if (props.showRemoved && (props.removedYlikerroinBets && props.removedYlikerroinBets.includes(betString))){
    betStyle['background-color'] = 'LightGrey'
    shoWagerButton = false
    removeText = 'Palauta'
  }
  else if (!props.showWagered && (props.wageredYlikerroinBets && props.wageredYlikerroinBets.includes(betString))){
    return null
  }
  else if (props.showWagered && (props.wageredYlikerroinBets && props.wageredYlikerroinBets.includes(betString))){
    betStyle['background-color'] = 'Lime'
    showRemoveButton = false
    wagerText = 'Palauta'
  }

  if (true){ // (day >= today){
    return (
      <div style={betStyle}>
        <div>{day}.{month}.{year} {hours}:{minutes} <b>{quoteString}</b></div>
        <div>{betString}</div>
        {props.bet.content.length > 0 ? <div>{props.bet.content}</div> : null }
        <p>
          {showRemoveButton ? <button className="btn btn-outline-secondary btn-sm" id={betString} onClick={props.handleYlikerroinBetRemove}>{removeText}</button> : null }
          {shoWagerButton ? <button className="btn btn-outline-success btn-sm" id={betString} onClick={props.handleYlikerroinBetWager}>{wagerText}</button> : null }
        </p>
      </div>
    )
  }
  else {
    console.log("SKIPPED")
    return null
  }
}

YlikerroinBet.propTypes = {
  //blog: PropTypes.object.isRequired,
  //user: PropTypes.object,
  //addLike: PropTypes.func,
  //removeBlog: PropTypes.func
}

const getBetString = (bet) => {
  let betString = ""
  if (bet.isSuccessful && bet.hasOwnProperty('home') && bet.hasOwnProperty('away')){
      let veto = ""

      if (bet.hasOwnProperty('over')){
          veto = "over " + bet.over
      }
      else if (bet.hasOwnProperty('under')){
          veto = "under " + bet.under
      }
      else if (bet.hasOwnProperty('homeAway') && bet.hasOwnProperty('spread')){
          let spread = bet.spread
          if (bet.spread >= 0 && !bet.spread.includes('+')){
            spread = '+' + bet.spread.toString()
          }

          veto = bet.homeAway + "  " + spread
      }
      else if (bet.hasOwnProperty('homeAway')){
          veto = bet.homeAway
      }
      else{
          veto = bet.veto
      }

      betString = `${bet.home} – ${bet.away} ${veto} kerroin: ${bet.kerroin.toFixed(3).toString()} panos: ${bet.panos.toString()} ${bet.bookie}`
      
  }
  else{
    betString = `${bet.kohde} ${bet.veto} kerroin: ${bet.kerroin.toFixed(3).toString()} panos: ${bet.panos.toString()} ${bet.bookie}`
  }

  return betString
}

export default YlikerroinBet