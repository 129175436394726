import React, { useEffect, Suspense } from 'react';
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import Modal from 'react-modal';
import { connect, useDispatch } from 'react-redux';

import "bootstrap/dist/css/bootstrap.min.css";
import 'react-vis/dist/style.css';

import Layout from './hoc/Layout/Layout';
import Logout from './containers/Auth/Logout/Logout';
import * as actions from './store/actions/index';
import names from "./components/names";
import Tips from "./components/Tips/Tips";
import UserSettings from "./components/Tips/UserSettings";
import BetList from "./components/Accountancy/BetList";
import EditBet from "./components/Accountancy/EditBet";
import DeleteBet from "./components/Accountancy/DeleteBet";
import AddBet from "./components/Accountancy/AddBet";
import ViewResults from "./components/Accountancy/ViewResults";
import VeikkausEbet from "./components/Accountancy/VeikkausEbet";
import Moniveto from "./components/Accountancy/Moniveto";
import MonivetoRows from "./components/Accountancy/MonivetoRows";
import Vakio from "./components/Accountancy/Vakio";
import VakioRows from "./components/Accountancy/VakioRows";
import Betfair from "./components/Accountancy/Betfair";
import CurrentBets from "./components/Accountancy/CurrentBets";
import Odds from "./components/Odds/Odds";

let styles = {
    'background-color':'black'
}

const Auth = React.lazy(() => {
    return import('./containers/Auth/Auth');
});

Modal.setAppElement('#root');

const App = props =>  {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(actions.authCheckState())
    },[dispatch]);

    let routes = (
        <Switch>
            <Route path="/auth" render={props => <Auth {...props} />} />
            <Redirect from="*" to="/" />
        </Switch>
    );

    if (props.isAuthenticated && !props.isAdvanced) {
        routes = (
            <Switch>
                <Route path="/" exact component={() => <Tips token = {props.token}/>}/>
                <Route path="/usersettings" exact component={() => <UserSettings token = {props.token}/>}/>
                <Route path="/auth" render={props => <Auth {...props} />} />
                <Route path="/logout" component={Logout} />
                <Redirect from="*" to="/" />
            </Switch>
        )}

    if (props.isAuthenticated && props.isAdvanced) {
        routes = (
            <Switch>
                <Route path="/" exact component={() => <Tips token = {props.token}/>}/>
                <Route path="/usersettings" component={() => <UserSettings token = {props.token}/>}/>
                <Route path="/edit/:id" component={() => <EditBet {...props}/>} />
                <Route path="/delete/:id" component={() => <DeleteBet {...props}/>} />
                <Route path="/addbet" component={() => <AddBet names={names()} {...props}/>} />
                <Route path="/results" component={() => <ViewResults names={names()} {...props}/>} />
                <Route path="/veikkausebet" component={() => <VeikkausEbet {...props}/>} />
                <Route path="/moniveto/" component={() => <Moniveto {...props}/>} />
                <Route path="/monivetorows/:id" component={() => <MonivetoRows {...props}/>} />
                <Route path="/vakio" component={() => <Vakio {...props}/>} />
                <Route path="/vakiorows/:id" component={() => <VakioRows {...props}/>} />
                <Route path="/betfair" component={() => <Betfair {...props}/>} />
                <Route path="/currentbets" component={() => <CurrentBets {...props}/>} />
                <Route path="/odds" component={() => <Odds {...props}/>} />
                <Route path="/auth" render={props => <Auth {...props} />} />
                <Route path="/logout" component={Logout} />
                <Route path="/betlist" exact component={() => <BetList names={names()} {...props}/>} />
                <Redirect from="*" to="/" />
            </Switch>
        )}

    return (
        <div>
            <Layout>
                <Suspense fallback={<p>Loading...</p>}>{routes}</Suspense>
            </Layout>
        </div>
    );
};

const mapStateToProps = state => {
    //console.log("App", "state.auth", state.auth)
    return {
        isAuthenticated: state.auth.token !== null,
        isAdvanced: state.auth.isAdvanced,
        token: state.auth.token
};
};

export default withRouter(
    connect(
        mapStateToProps,
        null
    )(App)
);