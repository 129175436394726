import React, { useState } from 'react'
import PropTypes from 'prop-types'

const Nordicbet = (props) => {
  let betStyle = {
    paddingTop: 10,
    paddingLeft: 2,
    'border-radius': '3px',
    border: 'solid',
    borderWidth: 2,
    marginBottom: 10,
    'background-color': 'AliceBlue'
  }

  const [render, setRender] = useState(false)

  if (!props.showRemoved && (props.removedNordicbetMessages && props.removedNordicbetMessages.includes(props.bet.url))){
    return null
  }
  else if (props.showRemoved && (props.removedNordicbetMessages && props.removedNordicbetMessages.includes(props.bet.url))){
    betStyle['background-color'] = 'DarkGrey'
  }
  else if (!props.showWagered && (props.wageredNordicbetMessages && props.wageredNordicbetMessages.includes(props.bet.url))){
    return null
  }
  else if (props.showWagered && (props.wageredNordicbetMessages && props.wageredNordicbetMessages.includes(props.bet.url))){
    betStyle['background-color'] = 'Chartreuse'
  }
  else if (!props.showHiddenUsers && props.hiddenNordicbetUsers && props.hiddenNordicbetUsers.length > 0){
    return null
  }

  const date = new Date(props.bet.eventDate)

  if (new Date().getTime() - date.getTime() > (24*60*60*1000)){
    return null
  }

  const day = date.getDate()
  const month = date.getMonth() + 1
  const year = date.getFullYear()

  let title = ''
  if (props.showTitle){
    title = ' - Nordicbet'
  }

  return (
    <div style={betStyle} onClick={() => setRender(!render)}>
       <h4><a href={props.bet.url} target="_blank">{day}.{month}.{year} {props.bet.league} {props.bet.game} {title}</a></h4>
       <p>
        <button className="btn btn-outline-secondary btn-sm" id={props.bet.url} onClick={props.handleNordicbetRemove}>Poista</button>
        <button className="btn btn-outline-success btn-sm" id={props.bet.url} onClick={props.handleNordicbetWager}>Pelattu</button>
       </p>
    </div>
  )}

Nordicbet.propTypes = {
  //blog: PropTypes.object.isRequired,
  //user: PropTypes.object,
  //addLike: PropTypes.func,
  //removeBlog: PropTypes.func
}

export default Nordicbet