import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import  * as Datetime from 'react-datetime';
import moment from 'moment';
import PropTypes from 'prop-types';
import C from "../../constants";
import '../../../node_modules/react-datetime/css/react-datetime.css';
import axios from "axios";
import {Link} from "react-router-dom";

const Moniveto = (props) => {
    const dateNow = new Date().getTime();
    const m = moment();
    const roundTimeUp = m.minute() || m.second() || m.millisecond() ? m.add(2, 'hour').startOf('hour') : m.startOf('hour');
    const [dateTime, setDateTime] = useState(roundTimeUp);
    const [monivetoEvents, setmMonivetoEvents] = useState([]);

    const getFixtures = async () => {
        if (props.token !== undefined && props.token !== null)
        {
            axios.get(C.BASE_URL + '/moniveto', {headers: { 'x-access-token': props.token }})
                .then(response => {
                    setmMonivetoEvents(response.data);
                })
                .catch(function (error){
                    console.log(error);
                });
        }
    };

    useEffect(() => {
        if (props.token !== undefined && props.token !== null)
        {
            getFixtures();
        }
    }, []);

    const monivetoList = () => {
        return monivetoEvents.map(function(current, i){
            return <MonivetoItem monivetoItem={current} key={i} />;
        })
    };

    const MonivetoItem = props => (
        <tr>
            <td>
                <Link to={"/monivetorows/"+props.monivetoItem.id}>{props.monivetoItem.id}</Link>
            </td>
            <td>
                <Link to={"/monivetorows/"+props.monivetoItem.id}>{props.monivetoItem.brandId}</Link>
            </td>
            <td>
                <Link to={"/monivetorows/"+props.monivetoItem.id}>{props.monivetoItem.sport}</Link>
            </td>
            <td>
                <Link to={"/monivetorows/"+props.monivetoItem.id}>{props.monivetoItem.games[0]}</Link>
            </td>
            <td>
                <Link to={"/monivetorows/"+props.monivetoItem.id}>{props.monivetoItem.games[1]}</Link>
            </td>
            <td>
                <Link to={"/monivetorows/"+props.monivetoItem.id}>{props.monivetoItem.games[2]}</Link>
            </td>
            <td>
                <Link to={"/monivetorows/"+props.monivetoItem.id}>{props.monivetoItem.games[3]}</Link>
            </td>
            <td>{props.monivetoItem.jackpots.length > 0 ? props.monivetoItem.jackpots[props.monivetoItem.jackpots.length - 1].description : null}</td>
            <td>{moment(props.monivetoItem.closeTime).format(C.DATE_FORMAT + ' ' + C.TIME_FORMAT)}</td>
        </tr>
    );

    return (
        <React.Fragment>
            <table className="table table-striped" style={{ marginTop: 20 }} >
                <thead>
                <tr>
                    <th>Id</th>
                    <th>Kohde</th>
                    <th>Laji</th>
                    <th>Peli 1</th>
                    <th>Peli 2</th>
                    <th>Peli 3</th>
                    <th>Peli 4</th>
                    <th>Bonus</th>
                    <th>Sulkeutuu</th>
                </tr>
                </thead>
                <tbody>
                { monivetoList() }
                </tbody>
            </table>
        </React.Fragment>
    );
};

export default Moniveto;