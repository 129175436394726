import { pinnacleIds } from "./pinnacleIds"
import { betfairIds } from "./betfairIds"
import { veikkausIds } from "./veikkausIds"

const convertBetfairIdsToPinnacleIds = (eventTypeId, competitionId) => {
  let sportId = 0
  let leagueId = 0
  try {
        switch (eventTypeId) {
          case betfairIds.BASEBALL_SPORT_ID:
            sportId = pinnacleIds.BASEBALL_SPORT_ID
            break;
          case betfairIds.BASKETBALL_SPORT_ID:
            sportId = pinnacleIds.BASKETBALL_SPORT_ID
            break;
          case betfairIds.DARTS_SPORT_ID:
            sportId = pinnacleIds.DARTS_SPORT_ID
            break;
          case betfairIds.ESPORTS_SPORT_ID:
            sportId = pinnacleIds.ESPORTS_SPORT_ID
            break;
          case betfairIds.AMERICAN_FOOTBALL_SPORT_ID:
            sportId = pinnacleIds.AMERICAN_FOOTBALL_SPORT_ID
            break;
          case betfairIds.GOLF_SPORT_ID:
            sportId = pinnacleIds.GOLF_SPORT_ID
            break;
          case betfairIds.HANDBALL_SPORT_ID:
            sportId = pinnacleIds.HANDBALL_SPORT_ID
            break;
          case betfairIds.ICE_HOCKEY_SPORT_ID:
            sportId = pinnacleIds.ICE_HOCKEY_SPORT_ID
            break;
          case betfairIds.MIXED_MARTIAL_ARTS_SPORT_ID:
            sportId = pinnacleIds.MIXED_MARTIAL_ARTS_SPORT_ID
            break;
          case betfairIds.SNOOKER_SPORT_ID:
            sportId = pinnacleIds.SNOOKER_SPORT_ID
            break;
          case betfairIds.SOCCER_SPORT_ID:
            sportId = pinnacleIds.SOCCER_SPORT_ID
            break;
          case betfairIds.TENNIS_SPORT_ID:
            sportId = pinnacleIds.TENNIS_SPORT_ID
            break;
          case betfairIds.VOLLEYBALL_SPORT_ID:
            sportId = pinnacleIds.VOLLEYBALL_SPORT_ID
            break;
          case betfairIds.MOTOR_SPORT_ID:
            sportId = pinnacleIds.MOTOR_SPORT_ID
            break;
          default:
            sportId = 0
            break;
        }

        switch (competitionId) {
          case betfairIds.MLB_LEAGUE_ID:
            leagueId = pinnacleIds.MLB_LEAGUE_ID
            break;
          case betfairIds.NBA_LEAGUE_ID:
            leagueId = pinnacleIds.NBA_LEAGUE_ID
            break;
          case betfairIds.NFL_LEAGUE_ID:
            leagueId = pinnacleIds.NFL_LEAGUE_ID
            break;
          case betfairIds.CZECH_EXTRA_LIGA_LEAGUE_ID:
            leagueId = pinnacleIds.CZECH_EXTRA_LIGA_LEAGUE_ID
            break;
          case betfairIds.SM_LIIGA_LEAGUE_ID:
            leagueId = pinnacleIds.SM_LIIGA_LEAGUE_ID
            break;
          case betfairIds.DEL_LEAGUE_ID:
            leagueId = pinnacleIds.DEL_LEAGUE_ID
            break;
          case betfairIds.NHL_LEAGUE_ID:
            leagueId = pinnacleIds.NHL_LEAGUE_ID
            break;
          case betfairIds.KHL_LEAGUE_ID:
            leagueId = pinnacleIds.KHL_LEAGUE_ID
            break;
          case betfairIds.HOCKEY_ALLSVENSKAN_LEAGUE_ID:
            leagueId = pinnacleIds.HOCKEY_ALLSVENSKAN_LEAGUE_ID
            break;
          case betfairIds.SHL_LEAGUE_ID:
            leagueId = pinnacleIds.SHL_LEAGUE_ID
            break;
          case betfairIds.NLA_LEAGUE_ID:
            leagueId = pinnacleIds.NLA_LEAGUE_ID
            break;
          case betfairIds.UCF_LEAGUE_ID:
            leagueId = pinnacleIds.UCF_LEAGUE_ID
            break;
          case betfairIds.SUOMEN_CUP_LEAGUE_ID:
            leagueId = pinnacleIds.SUOMEN_CUP_LEAGUE_ID
            break;
          case betfairIds.ENGLAND_CHAMPIONSHIP_LEAGUE_ID:
            leagueId = pinnacleIds.ENGLAND_CHAMPIONSHIP_LEAGUE_ID
            break;
          case betfairIds.ENGLAND_FA_CUP_LEAGUE_ID:
            leagueId = pinnacleIds.ENGLAND_FA_CUP_LEAGUE_ID
            break;
          case betfairIds.ENGLAND_LEAGUE_1_LEAGUE_ID:
            leagueId = pinnacleIds.ENGLAND_LEAGUE_1_LEAGUE_ID
            break;
          case betfairIds.ENGLAND_LEAGUE_2_LEAGUE_ID:
            leagueId = pinnacleIds.ENGLAND_LEAGUE_2_LEAGUE_ID
            break;
          case betfairIds.ENGLAND_LEAGUE_CUP_LEAGUE_ID:
            leagueId = pinnacleIds.ENGLAND_LEAGUE_CUP_LEAGUE_ID
            break;
          case betfairIds.ENGLAND_PREMIER_LEAGUE_ID:
            leagueId = pinnacleIds.ENGLAND_PREMIER_LEAGUE_ID
            break;
          case betfairIds.LIGUE_1_LEAGUE_ID:
            leagueId = pinnacleIds.LIGUE_1_LEAGUE_ID
            break;
          case betfairIds.SERIE_A_LEAGUE_ID:
            leagueId = pinnacleIds.SERIE_A_LEAGUE_ID
            break;
          case betfairIds.COPPA_ITALIA_LEAGUE_ID:
            leagueId = pinnacleIds.COPPA_ITALIA_LEAGUE_ID
            break;
          case betfairIds.LA_LIGA_LEAGUE_ID:
            leagueId = pinnacleIds.LA_LIGA_LEAGUE_ID
            break;
          case betfairIds.SEQUNDA_DIVISION_LEAGUE_ID:
            leagueId = pinnacleIds.SEQUNDA_DIVISION_LEAGUE_ID
            break;
          case betfairIds.SPAIN_CUP_LEAGUE_ID:
            leagueId = pinnacleIds.SPAIN_CUP_LEAGUE_ID
            break;
          case betfairIds.UEFA_CHAMPIONS_LEAGUE_ID:
            leagueId = pinnacleIds.UEFA_CHAMPIONS_LEAGUE_ID
            break;
          case betfairIds.UEFA_EUROPA_LEAGUE_ID:
            leagueId = pinnacleIds.UEFA_EUROPA_LEAGUE_ID
            break;
          case betfairIds.IRAN_AZADEGAN_LEAGUE_ID:
            leagueId = pinnacleIds.IRAN_AZADEGAN_LEAGUE_ID
            break;
          case betfairIds.QATAR_STARS_LEAGUE_ID:
            leagueId = pinnacleIds.QATAR_STARS_LEAGUE_ID
            break;
          case betfairIds.SAUDI_ARABIA_PRO_LEAGUE_ID:
            leagueId = pinnacleIds.SAUDI_ARABIA_PRO_LEAGUE_ID
            break;
          default:
            leagueId = 0
            break;
        }

        return {
          sportId: sportId,
          leagueId: leagueId
        }

  } catch (error) {
    console.log(error)
    return {
      sportId: sportId,
      leagueId: leagueId
    }
  }
}

const convertVeikkausIdsToPinnacleIds = (veikkausSportId, categoryId, tournamentId) => {
  let sportId = 0
  let leagueId = 0
  try {
        switch (veikkausSportId) {
          case veikkausIds.BANDY_SPORT_ID:
            sportId = pinnacleIds.BANDY_SPORT_ID
            break;

          case veikkausIds.BASKETBALL_SPORT_ID:
            sportId = pinnacleIds.BASKETBALL_SPORT_ID
            switch (categoryId) {
              case veikkausIds.BASKETBALL_FINLAND_CATEGORY_ID:
                if (tournamentId === veikkausIds.KORISLIIGA_TOURNAMENT_ID){
                  leagueId = pinnacleIds.KORISLIIGA_LEAGUE_ID
                }
    
                break;
              case veikkausIds.BASKETBALL_USA_CATEGORY_ID:
                if (tournamentId === veikkausIds.NBA_TOURNAMENT_ID){
                  leagueId = pinnacleIds.NBA_LEAGUE_ID
                }
    
                break;
              }
            break;

          case veikkausIds.ESPORTS_CSGO_SPORT_ID:
            sportId = pinnacleIds.ESPORTS_SPORT_ID
            break;
          case veikkausIds.ESPORTS_LOL_SPORT_ID:
            sportId = pinnacleIds.ESPORTS_SPORT_ID
            break;
          case veikkausIds.ESPORTS_DOTA2_SPORT_ID:
            sportId = pinnacleIds.ESPORTS_SPORT_ID
            break;
          case veikkausIds.ESPORTS_COD_SPORT_ID:
            sportId = pinnacleIds.ESPORTS_SPORT_ID
            break;
          case veikkausIds.ESPORTS_ROCKET_LEAGUE_SPORT_ID:
            sportId = pinnacleIds.ESPORTS_SPORT_ID
            break;
          //case veikkausIds.AMERICAN_FOOTBALL_SPORT_ID:
            //sportId = pinnacleIds.AMERICAN_FOOTBALL_SPORT_ID
            //break;
          case veikkausIds.GOLF_SPORT_ID:
            sportId = pinnacleIds.GOLF_SPORT_ID
            break;
          case veikkausIds.HANDBALL_SPORT_ID:
            sportId = pinnacleIds.HANDBALL_SPORT_ID
            break;
          case veikkausIds.ICE_HOCKEY_SPORT_ID:
            sportId = pinnacleIds.ICE_HOCKEY_SPORT_ID
            switch (categoryId) {
              case veikkausIds.ICE_HOCKEY_FINLAND_CATEGORY_ID:
                if (tournamentId === veikkausIds.SM_LIIGA_TOURNAMENT_ID){
                  leagueId = pinnacleIds.SM_LIIGA_LEAGUE_ID
                }
                else if (tournamentId === veikkausIds.MESTIS_TOURNAMENT_ID){
                  leagueId = pinnacleIds.MESTIS_LEAGUE_ID
                }
    
                break;
              case veikkausIds.ICE_HOCKEY_USA_CATEGORY_ID:
                if (tournamentId === veikkausIds.NHL_TOURNAMENT_ID){
                  leagueId = pinnacleIds.NHL_LEAGUE_ID
                }
                
                break;
              case veikkausIds.ICE_HOCKEY_SWEDEN_CATEGORY_ID:
                if (tournamentId === veikkausIds.SHL_TOURNAMENT_ID){
                  leagueId = pinnacleIds.SHL_LEAGUE_ID
                }
                else if (tournamentId === veikkausIds.HOCKEY_ALLSVENSKAN_TOURNAMENT_ID){
                  leagueId = pinnacleIds.HOCKEY_ALLSVENSKAN_LEAGUE_ID
                }
                
                break;
              case veikkausIds.ICE_HOCKEY_SWITZERLAND_CATEGORY_ID:
                if (tournamentId === veikkausIds.NLA_TOURNAMENT_ID){
                  leagueId = pinnacleIds.NLA_LEAGUE_ID
                }
                else if (tournamentId === veikkausIds.SWISS_LEAGUE_TOURNAMENT_ID){
                  leagueId = pinnacleIds.SWISS_LEAGUE_ID
                }
                
                break;
              case veikkausIds.ICE_HOCKEY_GERMANY_CATEGORY_ID:
                if (tournamentId === veikkausIds.DEL_TOURNAMENT_ID){
                  leagueId = pinnacleIds.DEL_LEAGUE_ID
                }
                
                break;
              case veikkausIds.ICE_HOCKEY_RUSSIA_CATEGORY_ID:
                if (tournamentId === veikkausIds.KHL_TOURNAMENT_ID){
                  leagueId = pinnacleIds.KHL_LEAGUE_ID
                }
                
                break;
              case veikkausIds.ICE_HOCKEY_CZECH_CATEGORY_ID:
                if (tournamentId === veikkausIds.CZECH_EXTRALIIGA_TOURNAMENT_ID){
                  leagueId = pinnacleIds.CZECH_EXTRA_LIGA_LEAGUE_ID
                }
                
                break;
              case veikkausIds.ICE_HOCKEY_DENMARK_CATEGORY_ID:
                if (tournamentId === veikkausIds.METAL_TOURNAMENT_ID){
                  leagueId = pinnacleIds.METAL_LIGAEN_LEAGUE_ID
                }
                
                break;
              case veikkausIds.ICE_HOCKEY_SLOVAKIA_CATEGORY_ID:
                if (tournamentId === veikkausIds.SLOVAKIA_EXTRALIIGA_TOURNAMENT_ID){
                  leagueId = pinnacleIds.SLOVAKIA_EXTRA_LIGA_LEAGUE_ID
                }
                
                break;
              //case veikkausIds.ICE_HOCKEY_NORWAY_CATEGORY_ID:
                //if (tournamentId === veikkausIds.NBA_TOURNAMENT_ID){
                  //leagueId = pinnacleIds.NBA_LEAGUE_ID
                //}
                
                //break;
              //case veikkausIds.ICE_HOCKEY_AUSTRIA_CATEGORY_ID:
                //if (tournamentId === veikkausIds.NBA_TOURNAMENT_ID){
                  //leagueId = pinnacleIds.NBA_LEAGUE_ID
                //}
                
                //break;
              //case veikkausIds.ICE_HOCKEY_FRANCE_CATEGORY_ID:
                //if (tournamentId === veikkausIds.NBA_TOURNAMENT_ID){
                  //leagueId = pinnacleIds.NBA_LEAGUE_ID
                //}
                
                //break;
              }
            break;

          case veikkausIds.MIXED_MARTIAL_ARTS_SPORT_ID:
            sportId = pinnacleIds.MIXED_MARTIAL_ARTS_SPORT_ID
            switch (categoryId) {
              case veikkausIds.UCF_CATEGORY_ID:
                leagueId = pinnacleIds.UCF_LEAGUE_ID
                
                break;
            }

            break;

          case veikkausIds.SNOOKER_SPORT_ID:
            sportId = pinnacleIds.SNOOKER_SPORT_ID
            break;
          case veikkausIds.SOCCER_SPORT_ID:
            sportId = pinnacleIds.SOCCER_SPORT_ID
            switch (categoryId) {
              case veikkausIds.SOCCER_FINLAND_CATEGORY_ID:
                if (tournamentId === veikkausIds.SUOMEN_CUP_TOURNAMENT_ID){
                  leagueId = pinnacleIds.SUOMEN_CUP_LEAGUE_ID
                }
    
                break;
              case veikkausIds.SOCCER_ENGLAND_CATEGORY_ID:
                if (tournamentId === veikkausIds.ENGLAND_PREMIER_LEAGUE_TOURNAMENT_ID){
                  leagueId = pinnacleIds.ENGLAND_PREMIER_LEAGUE_ID
                }
                else if (tournamentId === veikkausIds.ENGLAND_CHAMPIONSHIP_TOURNAMENT_ID){
                  leagueId = pinnacleIds.ENGLAND_CHAMPIONSHIP_LEAGUE_ID
                }
                else if (tournamentId === veikkausIds.ENGLAND_LEAGUE_1_TOURNAMENT_ID){
                  leagueId = pinnacleIds.ENGLAND_LEAGUE_1_LEAGUE_ID
                }
                else if (tournamentId === veikkausIds.ENGLAND_LEAGUE_2_TOURNAMENT_ID){
                  leagueId = pinnacleIds.ENGLAND_LEAGUE_2_LEAGUE_ID
                }
                
                break;
              case veikkausIds.SOCCER_ITALY_CATEGORY_ID:
                if (tournamentId === veikkausIds.SERIE_A_TOURNAMENT_ID){
                  leagueId = pinnacleIds.SERIE_A_LEAGUE_ID
                }
                
                break;
              case veikkausIds.SOCCER_GERMANY_CATEGORY_ID:
                if (tournamentId === veikkausIds.BUNDESLIGA_TOURNAMENT_ID){
                  leagueId = 0
                }
                else if (tournamentId === veikkausIds.BUNDESLIGA2_TOURNAMENT_ID){
                  leagueId = 0
                }
                
                break;
              case veikkausIds.SOCCER_SPAIN_CATEGORY_ID:
                if (tournamentId === veikkausIds.LA_LIGA_TOURNAMENT_ID){
                  leagueId = pinnacleIds.LA_LIGA_LEAGUE_ID
                }
                else if (tournamentId === veikkausIds.SEQUNDA_LIGA_TOURNAMENT_ID){
                  leagueId = pinnacleIds.SEQUNDA_DIVISION_LEAGUE_ID
                }
                
                break;
              case veikkausIds.SOCCER_HOLLAND_CATEGORY_ID:
                if (tournamentId === veikkausIds.SLOVAKIA_EXTRALIIGA_TOURNAMENT_ID){
                  leagueId = pinnacleIds.SLOVAKIA_EXTRA_LIGA_LEAGUE_ID
                }
                
                break;
              case veikkausIds.SOCCER_FRANCE_CATEGORY_ID:
                if (tournamentId === veikkausIds.LIGUE1_TOURNAMENT_ID){
                  leagueId = pinnacleIds.LIGUE_1_LEAGUE_ID
                }
                
                break;
              case veikkausIds.SOCCER_SCOTLAND_CATEGORY_ID:
                if (tournamentId === veikkausIds.SLOVAKIA_EXTRALIIGA_TOURNAMENT_ID){
                  leagueId = pinnacleIds.SLOVAKIA_EXTRA_LIGA_LEAGUE_ID
                }
                
                break;
              case veikkausIds.SOCCER_TURKEY_CATEGORY_ID:
                if (tournamentId === veikkausIds.SLOVAKIA_EXTRALIIGA_TOURNAMENT_ID){
                  leagueId = pinnacleIds.SLOVAKIA_EXTRA_LIGA_LEAGUE_ID
                }
                
                break;
              case veikkausIds.SOCCER_PORTUGAL_CATEGORY_ID:
                if (tournamentId === veikkausIds.SLOVAKIA_EXTRALIIGA_TOURNAMENT_ID){
                  leagueId = pinnacleIds.SLOVAKIA_EXTRA_LIGA_LEAGUE_ID
                }
                
                break;
              case veikkausIds.SOCCER_SWEDEN_CATEGORY_ID:
                if (tournamentId === veikkausIds.SLOVAKIA_EXTRALIIGA_TOURNAMENT_ID){
                  leagueId = pinnacleIds.SLOVAKIA_EXTRA_LIGA_LEAGUE_ID
                }
                
                break;
              case veikkausIds.SOCCER_INTERNATIONAL_CATEGORY_ID:
                if (tournamentId === veikkausIds.SLOVAKIA_EXTRALIIGA_TOURNAMENT_ID){
                  leagueId = pinnacleIds.SLOVAKIA_EXTRA_LIGA_LEAGUE_ID
                }
                
                break;
              case veikkausIds.SOCCER_DENMARK_CATEGORY_ID:
                if (tournamentId === veikkausIds.SLOVAKIA_EXTRALIIGA_TOURNAMENT_ID){
                  leagueId = pinnacleIds.SLOVAKIA_EXTRA_LIGA_LEAGUE_ID
                }
                
                break;
              case veikkausIds.SOCCER_INTERNATIONAL_CLUB_CATEGORY_ID:
                if (tournamentId === veikkausIds.SLOVAKIA_EXTRALIIGA_TOURNAMENT_ID){
                  leagueId = pinnacleIds.SLOVAKIA_EXTRA_LIGA_LEAGUE_ID
                }
                
                break;
              case veikkausIds.SOCCER_AUSTRIA_CATEGORY_ID:
                if (tournamentId === 1){
                  leagueId = pinnacleIds.SLOVAKIA_EXTRA_LIGA_LEAGUE_ID
                }
                
                break;
              case veikkausIds.SOCCER_SWITZERLAND_CATEGORY_ID:
                if (tournamentId === 1){
                  leagueId = pinnacleIds.SLOVAKIA_EXTRA_LIGA_LEAGUE_ID
                }
                
                break;
              case veikkausIds.SOCCER_GREECE_CATEGORY_ID:
                if (tournamentId === 1){
                  leagueId = pinnacleIds.SLOVAKIA_EXTRA_LIGA_LEAGUE_ID
                }
                
                break;
              case veikkausIds.SOCCER_ICELAND_CATEGORY_ID:
                if (tournamentId === 1){
                  leagueId = pinnacleIds.SLOVAKIA_EXTRA_LIGA_LEAGUE_ID
                }
                
                break;
              case veikkausIds.SOCCER_RUSSIA_CATEGORY_ID:
                if (tournamentId === 1){
                  leagueId = pinnacleIds.SLOVAKIA_EXTRA_LIGA_LEAGUE_ID
                }
                
                break;
              case veikkausIds.SOCCER_BRAZIL_CATEGORY_ID:
                if (tournamentId === 1){
                  leagueId = pinnacleIds.SLOVAKIA_EXTRA_LIGA_LEAGUE_ID
                }
                
                break;
              case veikkausIds.SOCCER_BELGIUM_CATEGORY_ID:
                if (tournamentId === 1){
                  leagueId = pinnacleIds.SLOVAKIA_EXTRA_LIGA_LEAGUE_ID
                }
                
                break;
              case veikkausIds.SOCCER_AUSTRALIA_CATEGORY_ID:
                if (tournamentId === 1){
                  leagueId = pinnacleIds.SLOVAKIA_EXTRA_LIGA_LEAGUE_ID
                }
                
                break;
              case veikkausIds.SOCCER_POLAND_CATEGORY_ID:
                if (tournamentId === 1){
                  leagueId = pinnacleIds.SLOVAKIA_EXTRA_LIGA_LEAGUE_ID
                }
                
                break;
              case veikkausIds.SOCCER_ARGENTINA_CATEGORY_ID:
                if (tournamentId === 1){
                  leagueId = pinnacleIds.SLOVAKIA_EXTRA_LIGA_LEAGUE_ID
                }
                
                break;
              case veikkausIds.SOCCER_CZECH_CATEGORY_ID:
                if (tournamentId === 1){
                  leagueId = pinnacleIds.SLOVAKIA_EXTRA_LIGA_LEAGUE_ID
                }
                
                break;
              case veikkausIds.SOCCER_HUNGARY_CATEGORY_ID:
                if (tournamentId === 1){
                  leagueId = pinnacleIds.SLOVAKIA_EXTRA_LIGA_LEAGUE_ID
                }
                
                break;
              case veikkausIds.SOCCER_MEXICO_CATEGORY_ID:
                if (tournamentId === 1){
                  leagueId = pinnacleIds.SLOVAKIA_EXTRA_LIGA_LEAGUE_ID
                }
                
                break;
              case veikkausIds.SOCCER_INDIA_CATEGORY_ID:
                if (tournamentId === 1){
                  leagueId = pinnacleIds.SLOVAKIA_EXTRA_LIGA_LEAGUE_ID
                }
                
                break;
              case veikkausIds.SOCCER_URUGUAY_CATEGORY_ID:
                if (tournamentId === 1){
                  leagueId = pinnacleIds.SLOVAKIA_EXTRA_LIGA_LEAGUE_ID
                }
                
                break;
              case veikkausIds.SOCCER_SLOVAKIA_CATEGORY_ID:
                if (tournamentId === 1){
                  leagueId = pinnacleIds.SLOVAKIA_EXTRA_LIGA_LEAGUE_ID
                }
                
                break;
              case veikkausIds.SOCCER_NORTHERN_IRELAND_CATEGORY_ID:
                if (tournamentId === 1){
                  leagueId = pinnacleIds.SLOVAKIA_EXTRA_LIGA_LEAGUE_ID
                }
                
                break;
              case veikkausIds.SOCCER_ISRAEL_CATEGORY_ID:
                if (tournamentId === 1){
                  leagueId = pinnacleIds.SLOVAKIA_EXTRA_LIGA_LEAGUE_ID
                }
                
                break;
              case veikkausIds.SOCCER_SOUTH_AFRICA_CATEGORY_ID:
                if (tournamentId === 1){
                  leagueId = pinnacleIds.SLOVAKIA_EXTRA_LIGA_LEAGUE_ID
                }
                
                break;
              case veikkausIds.SOCCER_ECUADOR_CATEGORY_ID:
                if (tournamentId === 1){
                  leagueId = pinnacleIds.SLOVAKIA_EXTRA_LIGA_LEAGUE_ID
                }
                
                break;
              }
            break;
          case veikkausIds.TENNIS_SPORT_ID:
            sportId = pinnacleIds.TENNIS_SPORT_ID
            break;
          case veikkausIds.VOLLEYBALL_SPORT_ID:
            sportId = pinnacleIds.VOLLEYBALL_SPORT_ID
            break;
          //case veikkausIds.MOTOR_SPORT_ID:
            //sportId = pinnacleIds.MOTOR_SPORT_ID
            //break;
          default:
            sportId = 0
            break;
        }

        return {
          sportId: sportId,
          leagueId: leagueId
        }

  } catch (error) {
    console.log(error)
    return {
      sportId: sportId,
      leagueId: leagueId
    }
  }
}

const getNames = (sportId, originalSportName, leagueId, originalLeagueName, veikkausTournament, veikkausName) => {
  let sportName = originalSportName
  let leagueName = originalLeagueName
  try {
        switch (sportId) {
          case pinnacleIds.BASEBALL_SPORT_ID:
            sportName = "BASEBALL"
            break;
          case pinnacleIds.BASKETBALL_SPORT_ID:
            sportName = "KORIPALLO"
            break;
          case pinnacleIds.DARTS_SPORT_ID:
            sportName = "DARTS"
            break;
          case pinnacleIds.ESPORTS_SPORT_ID:
            sportName = "E-SPORTS"
            break;
          case pinnacleIds.AMERICAN_FOOTBALL_SPORT_ID:
            sportName = "AMERICAN FOOTBALL"
            break;
          case pinnacleIds.GOLF_SPORT_ID:
            sportName = "GOLF"
            break;
          case pinnacleIds.HANDBALL_SPORT_ID:
            sportName = "KÄSIPALLO"
            break;
          case pinnacleIds.ICE_HOCKEY_SPORT_ID:
            sportName = "JÄÄKIEKKO"
            break;
          case pinnacleIds.MIXED_MARTIAL_ARTS_SPORT_ID:
            sportName = "MMA"
            break;
          case pinnacleIds.SNOOKER_SPORT_ID:
            sportName = "SNOOKER"
            break;
          case pinnacleIds.SOCCER_SPORT_ID:
            sportName = "JALKAPALLO"
            break;
          case pinnacleIds.TENNIS_SPORT_ID:
            sportName = "TENNIS"
            break;
          case pinnacleIds.VOLLEYBALL_SPORT_ID:
            sportName = "KÄSIPALLO"
            break;
          case pinnacleIds.BIATHLON_SPORT_ID:
            sportName = "AMPUMAHIIHTO"
            break;
          case pinnacleIds.SKI_JUMPING_SPORT_ID:
            sportName = "MÄKIHYPPY"
            break;
          case pinnacleIds.CROSS_COUNTRY_SPORT_ID:
            sportName = "HIIHTO"
            break;
          case pinnacleIds.FORMULA1_SPORT_ID:
            sportName = "FORMULA 1"
            break;
          case pinnacleIds.CYCLING_SPORT_ID:
            sportName = "PYÖRÄILY"
            break;
          case pinnacleIds.NORDIC_COMBINED_SPORT_ID:
            sportName = "YHDISTETTY"
            break;
          //case pinnacleIds.MOTOR_SPORT_ID:
            //sportName = "MOOTTORIURHEILU"
            //break;
          default:
            sportName = originalSportName
            break;
        }

        switch (leagueId) {
          case pinnacleIds.MLB_LEAGUE_ID:
            leagueName = "MLB"
            break;
          case pinnacleIds.NBA_LEAGUE_ID:
            leagueName = "NBA"
            break;
          case pinnacleIds.NFL_LEAGUE_ID:
            leagueName = "NFL"
            break;
          case pinnacleIds.CZECH_EXTRA_LIGA_LEAGUE_ID:
            leagueName = "Tsekin Extraliiga"
            break;
          case pinnacleIds.SM_LIIGA_LEAGUE_ID:
            leagueName = "Liiga"
            break;
          case pinnacleIds.DEL_LEAGUE_ID:
            leagueName = "DEL"
            break;
          case pinnacleIds.NHL_LEAGUE_ID:
            leagueName = "NHL"
            break;
          case pinnacleIds.KHL_LEAGUE_ID:
            leagueName = "KHL"
            break;
          case pinnacleIds.HOCKEY_ALLSVENSKAN_LEAGUE_ID:
            leagueName = "Allsvenskan"
            break;
          case pinnacleIds.SHL_LEAGUE_ID:
            leagueName = "SHL"
            break;
          case pinnacleIds.NLA_LEAGUE_ID:
            leagueName = "NLA"
            break;
          case pinnacleIds.UCF_LEAGUE_ID:
            leagueName = "UFC"
            break;
          case pinnacleIds.SUOMEN_CUP_LEAGUE_ID:
            leagueName = "Suomen Cup"
            break;
          case pinnacleIds.ENGLAND_CHAMPIONSHIP_LEAGUE_ID:
            leagueName = "Championship"
            break;
          case pinnacleIds.ENGLAND_FA_CUP_LEAGUE_ID:
            leagueName = "FA Cup"
            break;
          case pinnacleIds.ENGLAND_LEAGUE_1_LEAGUE_ID:
            leagueName = "League 1"
            break;
          case pinnacleIds.ENGLAND_LEAGUE_2_LEAGUE_ID:
            leagueName = "League 2"
            break;
          case pinnacleIds.ENGLAND_LEAGUE_CUP_LEAGUE_ID:
            leagueName = "Liigacup"
            break;
          case pinnacleIds.ENGLAND_PREMIER_LEAGUE_ID:
            leagueName = "Valioliiga"
            break;
          case pinnacleIds.LIGUE_1_LEAGUE_ID:
            leagueName = "Ligue 1"
            break;
          case pinnacleIds.SERIE_A_LEAGUE_ID:
            leagueName = "Serie A"
            break;
          case pinnacleIds.COPPA_ITALIA_LEAGUE_ID:
            leagueName = "Coppa Italia"
            break;
          case pinnacleIds.LA_LIGA_LEAGUE_ID:
            leagueName = "La Liga"
            break;
          case pinnacleIds.SEQUNDA_DIVISION_LEAGUE_ID:
            leagueName = "Sequnda Division"
            break;
          case pinnacleIds.SPAIN_CUP_LEAGUE_ID:
            leagueName = "Copa del Rey"
            break;
          case pinnacleIds.UEFA_CHAMPIONS_LEAGUE_ID:
            leagueName = "Mestarien Liiga"
            break;
          case pinnacleIds.UEFA_EUROPA_LEAGUE_ID:
            leagueName = "Eurooppaliiga"
            break;
          case pinnacleIds.IRAN_AZADEGAN_LEAGUE_ID:
            leagueName = "Azadegan"
            break;
          case pinnacleIds.QATAR_STARS_LEAGUE_ID:
            leagueName = "Qatar Stars League"
            break;
          case pinnacleIds.SAUDI_ARABIA_PRO_LEAGUE_ID:
            leagueName = "Saudi-Arabia Pro League"
            break;
          default:
            if (originalSportName === "Hevosurheilu"){
              sportName = "RAVIT"
              leagueName = veikkausName
            }
            else if (originalSportName === "Talviurheilu"){
              if (originalLeagueName.includes('Hiihto')){
                sportName = "HIIHTO"
              }
              else {
                sportName = originalLeagueName
              }
              
              leagueName = veikkausName
            }
            else {
              leagueName = originalLeagueName
            }
            
            break;
        }

        return {
          sportName: sportName,
          leagueName: leagueName
        }
  } catch (error) {
    console.log(error)
    return {
      sportName: sportName,
      leagueName: leagueName
    }
  }
}

export {
  convertBetfairIdsToPinnacleIds,
  convertVeikkausIdsToPinnacleIds,
  getNames
}