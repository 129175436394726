import React, { useState, useEffect  } from 'react';
import axios from 'axios';
import moment from 'moment';
import C from "../../constants";
import CreatableSelect from "react-select/creatable/dist/react-select.esm";
import * as Datetime from "react-datetime";
import Select from "react-select";
import PropTypes from "prop-types";

const resultList = [
    { value: '0', label: C.PENDING },
    { value: '1', label: C.WIN },
    { value: '2', label: C.LOSE },
    { value: '3', label: C.HALFWIN },
    { value: '4', label: C.HALFLOSE },
    { value: '5', label: C.PUSH },
    { value: '6', label: C.CANCELLED }
];

const EditBet = (props) => {
    const [currentBet, setCurrentBet] = useState('');
    const [gameFormat, setGameFormat] = useState('');
    const [selection, setSelection] = useState('');
    const [booker, setBooker] = useState('');
    const [odds, setOdds] = useState('');
    const [closingOdds, setClosingOdds] = useState('');
    const [originalOdds, setOriginalOdds] = useState('');
    const [bet, setBet] = useState('');
    const [win, setWin] = useState('');
    const [live, setLive] = useState(false);
    const [halfTime, setHalfTime] = useState(false);
    const [women, setWomen] = useState(false);
    const [result, setResult] = useState({value: 0, label: resultList[0].label});
    const [useMatchbookWithCommission, setUseMatchbookWithCommission] = useState(true);
    const [matchbookCommission, setMatchbookCommission] = useState(2.0);
    const [useBetfairWithCommission, setUseBetfairWithCommission] = useState(true);
    const [betfairCommission, setBetfairCommission] = useState(2.0);

    const handleGameFormatChange = (event) => {
        setGameFormat(event.target.value);
    };

    const handleSelectionChange = (event) => {
        setSelection(event.target.value);
    };

    const handleBookerChange = (event) => {
        setBooker(event.target.value);
    };

    const handleOddsInputChange = (event) => {
        setOdds(event.target.value);
        setOriginalOdds(event.target.value);
    };

    const handleClosingOddsInputChange = (event) => {
        setClosingOdds(event.target.value);
    };

    const handleInputClick = (event) => {
        if (event.target.value === '')
        {
            switch(event.target.name) {
                case 'odds':
                    setOdds(2.0);
                    setOriginalOdds(2.0);
                    break;
                case 'closingOdds':
                    if (odds > 1)
                    {
                        setClosingOdds(odds);
                    }
                    break;
                case 'bet':
                    setBet(100);
                    break;
                case 'win':
                    break;
                default:
                    break;
            }
        }
    };

    const calculateWin = () => {
        if (odds > 1 && bet > 0)
        {
            if (booker.label === 'Matchbook' && useMatchbookWithCommission)
            {
                const netOdds = (originalOdds - 1) * (1 - (matchbookCommission / 100)) + 1;
                const netWin = (netOdds * bet) - bet;
                setOdds(netOdds);
                setWin(netWin);
            }
            else if(booker.label === 'Betfair Exchange' && useBetfairWithCommission)
            {
                const netOdds = (originalOdds - 1) * (1 - (betfairCommission / 100)) + 1;
                const netWin = (netOdds * bet) - bet;
                setOdds(netOdds);
                setWin(netWin);
            }
            else
            {
                setWin((originalOdds * bet) - bet);
            }
        }
    };

    const handleBetInputChange = (event) => {
        setBet(event.target.value);
    };

    const handleWinInputChange = (event) => {
        setWin(event.target.value);
    };

    const handleClearValues = () => {
        setOdds('');
        setClosingOdds('');
        setOriginalOdds('');
        setBet('');
        setWin('');
    };

    const handleResultChange = (selectedOption) => {
        setResult(selectedOption);
    };

    const handleEditBet = () => {
        const data = {
            accounting_home: currentBet.accounting_home,
            accounting_home_id: currentBet.accounting_home_id,
            accounting_visitor: currentBet.accounting_visitor,
            accounting_visitor_id: currentBet.accounting_visitor_id,
            accounting_sport: currentBet.accounting_sport,
            accounting_sport_id: currentBet.accounting_sport_id,
            accounting_series: currentBet.accounting_series,
            accounting_dateTime: currentBet.accounting_dateTime,
            accounting_gameFormat: gameFormat,
            accounting_selection: selection,
            accounting_booker: booker,
            accounting_odds: odds,
            accounting_closing_odds: closingOdds,
            accounting_bet: bet,
            accounting_win: win,
            accounting_live: live,
            accounting_halfTime: halfTime,
            accounting_women: women,
            accounting_result: result.value,
            accounting_fixtureId: currentBet.accounting_fixtureId
        };

        let id = props.location.pathname.substring(6);

        const headers = {
            'x-access-token': props.token
        };

        axios.post(C.BASE_URL + '/update/'+id, data, {headers: headers})
            .then(res => {
                setTimeout(props.history.push('/'), 100);
                console.log(res.data);
            });
    };

    const BookerSpecialCase = () => {
        if (booker.label === 'Matchbook')
        {
            return(
                <React.Fragment>
                    <br/>
                    <input
                        type="checkbox"
                        onChange={(event) => setUseMatchbookWithCommission(event.currentTarget.checked)}
                        checked={useMatchbookWithCommission}
                    />
                    <label>Huomioi Matchbookin </label>
                    <input
                        type="number"
                        step='0.1'
                        onChange={(event) => { setMatchbookCommission(event.target.value)}}
                        value={matchbookCommission}
                    />
                    <label> % komissio nettovoitosta</label>
                    <br/>
                </React.Fragment>);
        }
        else if(booker.label === 'Betfair Exchange')
        {
            return(
                <React.Fragment>
                    <br/>
                    <input
                        type="checkbox"
                        onChange={(event) => setUseBetfairWithCommission(event.currentTarget.checked)}
                        checked={useBetfairWithCommission}
                    />
                    <label>Huomioi Betfairin </label>
                    <input
                        type="number"
                        step='0.1'
                        onChange={(event) => { setBetfairCommission(event.target.value)}}
                        value={betfairCommission}
                    />
                    <label> % komissio nettovoitosta</label>
                    <br/>
                </React.Fragment>);
        }
        else
        {
            return null;
        }
    };

    useEffect(() => {
        calculateWin();
    }, [useMatchbookWithCommission, matchbookCommission, useBetfairWithCommission, betfairCommission]);

    useEffect(() => {
        getBet();
    }, []);

    const getBet = () => {
        let id = props.location.pathname.substring(5);
        axios.get(C.BASE_URL +id, {headers: { 'x-access-token': props.token }})
            .then(response => {
                let live = response.data.accounting_live;
                if (live === '' || live === undefined)
                {
                    live = false;
                }

                let halfTime = response.data.accounting_halfTime;
                if (halfTime === '' || halfTime === undefined)
                {
                    halfTime = false;
                }

                let women = response.data.accounting_women;
                if (women === '' || women === undefined)
                {
                    women = false;
                }

                setCurrentBet(response.data);
                setGameFormat(response.data.accounting_gameFormat);
                setSelection(response.data.accounting_selection);
                setBooker(response.data.accounting_booker);
                setOdds(response.data.accounting_odds);
                setBet(response.data.accounting_bet);
                setWin(response.data.accounting_win);
                setLive(live);
                setHalfTime(halfTime);
                setWomen(women);
                setResult({value: response.data.accounting_result, label: resultList[response.data.accounting_result].label});

                if (response.data.accounting_closing_odds > 1)
                {
                    setClosingOdds(response.data.accounting_closing_odds);
                }
                else
                {
                    setClosingOdds(0);
                }

            })
            .catch(function (error) {
                console.log(error);
            })
    };

    return (
        <React.Fragment>
            <button className="btn btn-primary" onClick={handleEditBet}>Tallenna</button>
            <br/>
            <br/>
            <Select
                placeholder="Tulos"
                value={result}
                options={resultList}
                onChange={handleResultChange}
            />
            <br/>
            <label>{moment(currentBet.accounting_dateTime).format(C.DATE_FORMAT + ' ' + C.TIME_FORMAT)}</label>
            <br/>
            <label>{currentBet.accounting_sport + ' ' + currentBet.accounting_series}</label>
            <br/>
            <label>{currentBet.accounting_home + ' - ' + currentBet.accounting_visitor}</label>
            <br/>
            <input
                type="text"
                name="gameFormat"
                value={gameFormat}
                onChange={handleGameFormatChange}
            />
            <br/>
            <input
                type="text"
                name="selection"
                value={selection}
                onChange={handleSelectionChange}
            />
            <br/>
            <input
                type="text"
                name="booker"
                value={booker}
                onChange={handleBookerChange}
            />
            <br/>
            <BookerSpecialCase/>
            <input
                name="odds"
                type="number"
                step='0.1'
                placeholder="Kerroin"
                value={odds}
                onChange={handleOddsInputChange}
                onClick={handleInputClick}
            />
            <br/>
            <input
                name="closingOdds"
                type="number"
                step='0.1'
                placeholder="Päätöskerroin"
                value={closingOdds}
                onChange={handleClosingOddsInputChange}
                onClick={handleInputClick}
            />
            <br/>
            <input
                name="bet"
                type="number"
                step='10'
                placeholder="Panos"
                value={bet}
                onChange={handleBetInputChange}
                onClick={handleInputClick}
            />
            <br/>
            <input
                name="win"
                type="number"
                placeholder="Voitto"
                value={win}
                onChange={handleWinInputChange}
                onClick={calculateWin}
            />
            <br/>
            <button className="btn btn-primary" onClick={handleClearValues }>Tyhjennä</button>
            <br/>
            <input
                type="checkbox"
                onChange={(event) => setLive(event.currentTarget.checked)}
                checked={live}
            />
            <label>Live</label>
            <br/>
            <input
                type="checkbox"
                onChange={(event) => setHalfTime(event.currentTarget.checked)}
                checked={halfTime}
            />
            <label>Puoliaika</label>
            <br/>
            <input
                type="checkbox"
                onChange={(event) => setWomen(event.currentTarget.checked)}
                checked={women}
            />
            <label>Naiset</label>
            <br/>
            <button className="btn btn-primary" onClick={handleEditBet}>Tallenna</button>
        </React.Fragment>
    );
};

EditBet.propTypes = {
    names: PropTypes.object.isRequired
};

export default EditBet;