import React from 'react';

import classes from './NavigationItems.css'
import NavigationItem from './NavigationItem/NavigationItem'

const navigationItems = ( props ) => (
    <ul className={classes.NavigationItems}>
        {props.isAuthenticated ? <NavigationItem link="/" exact>Vihjeet</NavigationItem> : null}
        {props.isAuthenticated ? <NavigationItem link="/usersettings" exact>Vihjeasetukset</NavigationItem> : null}
        {(props.isAuthenticated && props.isAdvanced === 'true') ? <NavigationItem link="/veikkausebet" exact>Pitkäveto</NavigationItem> : null}
        {(props.isAuthenticated && props.isAdvanced === 'true') ? <NavigationItem link="/betlist" exact>Vetolista</NavigationItem> : null}
        {(props.isAuthenticated && props.isAdvanced === 'true') ? <NavigationItem link="/addbet" exact>Lisää veto</NavigationItem> : null}
        {(props.isAuthenticated && props.isAdvanced === 'true') ? <NavigationItem link="/results" exact>Tulokset</NavigationItem> : null}
        {(props.isAuthenticated && props.isAdvanced === 'true') ? <NavigationItem link="/moniveto" exact>Moniveto</NavigationItem> : null}
        {(props.isAuthenticated && props.isAdvanced === 'true') ? <NavigationItem link="/vakio" exact>Vakio</NavigationItem> : null}
        {(props.isAuthenticated && props.isAdvanced === 'true') ? <NavigationItem link="/betfair" exact>Betfair</NavigationItem> : null}
        {(props.isAuthenticated && props.isAdvanced === 'true') ? <NavigationItem link="/currentbets" exact>Nykyiset vedot</NavigationItem> : null}
        {(props.isAuthenticated && props.isAdvanced === 'true') ? <NavigationItem link="/odds" exact>Kertoimet</NavigationItem> : null}
        {!props.isAuthenticated
            ? <NavigationItem link="/auth">Kirjaudu</NavigationItem>
            : <NavigationItem link="/logout">Logout</NavigationItem>}
    </ul>
);

export default navigationItems