import React, { useState, useEffect  } from 'react';
import axios from 'axios';
import moment from 'moment';
import C from "../../constants";
import CreatableSelect from "react-select/creatable/dist/react-select.esm";
import * as Datetime from "react-datetime";
import Select from "react-select";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import MonivetoPercentagesForm from './MonivetoPercentagesForm'
import Button from "./Button";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Table from 'react-bootstrap/Table'

const MonivetoRows = (props) => {
    const [rows, setRows] = useState([]);
    const [fixtures, setFixtures] = useState(null);
    const [updateTime, setUpdateTime] = useState(0);
    const [showUpdateTime, setShowUpdateTime] = useState("Updating...");
    const [updatesTimeRunning, setUpdatesTimeRunning] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [ returnRate, setReturnRate ] = useState(0)
    const [ odds_1_1X2_1, setOdds_1_1X2_1 ] = useState(0)
    const [ odds_1_1X2_X, setOdds_1_1X2_X ] = useState(0)
    const [ odds_1_1X2_2, setOdds_1_1X2_2 ] = useState(0)
    const [ odds_1_12_1, setOdds_1_12_1 ] = useState(0)
    const [ odds_1_12_2, setOdds_1_12_2 ] = useState(0)
    const [ over_1_45, setOver_1_45 ] = useState(0)
    const [ under_1_45, setUnder_1_45 ] = useState(0)
    const [ over_1_55, setOver_1_55 ] = useState(0)
    const [ under_1_55, setUnder_1_55 ] = useState(0)
    const [ score_1_Odds, setScore_1_Odds ] = useState(null)
    const [ odds_2_1X2_1, setOdds_2_1X2_1 ] = useState(0)
    const [ odds_2_1X2_X, setOdds_2_1X2_X ] = useState(0)
    const [ odds_2_1X2_2, setOdds_2_1X2_2 ] = useState(0)
    const [ odds_2_12_1, setOdds_2_12_1 ] = useState(0)
    const [ odds_2_12_2, setOdds_2_12_2 ] = useState(0)
    const [ over_2_45, setOver_2_45 ] = useState(0)
    const [ under_2_45, setUnder_2_45 ] = useState(0)
    const [ over_2_55, setOver_2_55 ] = useState(0)
    const [ under_2_55, setUnder_2_55 ] = useState(0)
    const [ score_2_Odds, setScore_2_Odds ] = useState(null)
    const [ odds_3_1X2_1, setOdds_3_1X2_1 ] = useState(0)
    const [ odds_3_1X2_X, setOdds_3_1X2_X ] = useState(0)
    const [ odds_3_1X2_2, setOdds_3_1X2_2 ] = useState(0)
    const [ odds_3_12_1, setOdds_3_12_1 ] = useState(0)
    const [ odds_3_12_2, setOdds_3_12_2 ] = useState(0)
    const [ over_3_45, setOver_3_45 ] = useState(0)
    const [ under_3_45, setUnder_3_45 ] = useState(0)
    const [ over_3_55, setOver_3_55 ] = useState(0)
    const [ under_3_55, setUnder_3_55 ] = useState(0)
    const [ score_3_Odds, setScore_3_Odds ] = useState(null)
    const [ odds_4_1X2_1, setOdds_4_1X2_1 ] = useState(0)
    const [ odds_4_1X2_X, setOdds_4_1X2_X ] = useState(0)
    const [ odds_4_1X2_2, setOdds_4_1X2_2 ] = useState(0)
    const [ odds_4_12_1, setOdds_4_12_1 ] = useState(0)
    const [ odds_4_12_2, setOdds_4_12_2 ] = useState(0)
    const [ over_4_45, setOver_4_45 ] = useState(0)
    const [ under_4_45, setUnder_4_45 ] = useState(0)
    const [ over_4_55, setOver_4_55 ] = useState(0)
    const [ under_4_55, setUnder_4_55 ] = useState(0)
    const [ score_4_Odds, setScore_4_Odds ] = useState(null)
    const [ calculatedRows, setCalculatedRows ] = useState(null)
    const [eVlimit, seteVlimit] = useState(1.0)
    const [kellyLimit, setKellyLimit] = useState(0.00001)
    const [kellyDivider, setKellyDivider] = useState(1)
    const [oddsLimit, setOddsLimit] = useState(100)
    const [maxRows, setMaxRows] = useState(100)
    const [showRows, setShowRows] = useState(true)
    const [overallProbability, setOverallProbability] = useState(0)
    const [overallKelly, setOverallKelly] = useState(0)

    const handleOdds1X2_1Change = (event) => {
        event.preventDefault()
        switch (event.target.id) {
            case "1":
                console.log("EKA")
                setOdds_1_1X2_1(parseInt(event.target.value))
                if (event.target.value > 10 && event.target.value < 100){
                    if (odds_1_1X2_X < 1 && odds_1_1X2_2 > 0)
                    {
                        setOdds_1_1X2_X(100 - event.target.value - odds_1_1X2_2)
                    }
                    else if (odds_1_1X2_2 < 1 && odds_1_1X2_X > 0)
                    {
                        setOdds_1_1X2_2(100 - event.target.value - odds_1_1X2_X)
                    }
                }

                break
            case "2":
                console.log("TOKA")
                setOdds_2_1X2_1(parseInt(event.target.value))
                if (event.target.value > 10 && event.target.value < 100){
                    if (odds_2_1X2_X < 1 && odds_2_1X2_2 > 0)
                    {
                        setOdds_2_1X2_X(100 - event.target.value - odds_2_1X2_2)
                    }
                    else if (odds_2_1X2_2 < 1 && odds_2_1X2_X > 0)
                    {
                        setOdds_2_1X2_2(100 - event.target.value - odds_2_1X2_X)
                    }
                }

                break
            case "3":
                console.log("KOLMAS")
                setOdds_3_1X2_1(parseInt(event.target.value))
                if (event.target.value > 10 && event.target.value < 100){
                    if (odds_3_1X2_X < 1 && odds_3_1X2_2 > 0)
                    {
                        setOdds_3_1X2_X(100 - event.target.value - odds_3_1X2_2)
                    }
                    else if (odds_3_1X2_2 < 1 && odds_3_1X2_X > 0)
                    {
                        setOdds_3_1X2_2(100 - event.target.value - odds_3_1X2_X)
                    }
                }

                break
            case "4":
                console.log("NELJÄS")
                setOdds_4_1X2_1(parseInt(event.target.value))
                if (event.target.value > 10 && event.target.value < 100){
                    if (odds_4_1X2_X < 1 && odds_4_1X2_2 > 0)
                    {
                        setOdds_4_1X2_X(100 - event.target.value - odds_4_1X2_2)
                    }
                    else if (odds_4_1X2_2 < 1 && odds_4_1X2_X > 0)
                    {
                        setOdds_4_1X2_2(100 - event.target.value - odds_4_1X2_X)
                    }
                }

                break
        }
    }

    const handleOdds1X2_XChange = (event) => {
        event.preventDefault()
        switch (event.target.id) {
            case "1":
                console.log("EKA")
                setOdds_1_1X2_X(parseInt(event.target.value))
                if (event.target.value > 10 && event.target.value < 100){
                    if (odds_1_1X2_1 < 1 && odds_1_1X2_2 > 0)
                    {
                        setOdds_1_1X2_1(100 - event.target.value - odds_1_1X2_2)
                    }
                    else if (odds_1_1X2_2 < 1 && odds_1_1X2_1 > 0)
                    {
                        setOdds_1_1X2_2(100 - event.target.value - odds_1_1X2_1)
                    }
                }

                break
            case "2":
                console.log("TOKA")
                setOdds_2_1X2_X(parseInt(event.target.value))
                if (event.target.value > 10 && event.target.value < 100){
                    if (odds_2_1X2_1 < 1 && odds_2_1X2_2 > 0)
                    {
                        setOdds_2_1X2_1(100 - event.target.value - odds_2_1X2_2)
                    }
                    else if (odds_2_1X2_2 < 1 && odds_2_1X2_1 > 0)
                    {
                        setOdds_2_1X2_2(100 - event.target.value - odds_2_1X2_1)
                    }
                }

                break
            case "3":
                console.log("KOLMAS")
                setOdds_3_1X2_X(parseInt(event.target.value))
                if (event.target.value > 10 && event.target.value < 100){
                    if (odds_3_1X2_1 < 1 && odds_3_1X2_2 > 0)
                    {
                        setOdds_3_1X2_1(100 - event.target.value - odds_3_1X2_2)
                    }
                    else if (odds_3_1X2_2 < 1 && odds_3_1X2_1 > 0)
                    {
                        setOdds_3_1X2_2(100 - event.target.value - odds_3_1X2_1)
                    }
                }

                break
            case "4":
                console.log("NELJÄS")
                setOdds_4_1X2_X(parseInt(event.target.value))
                if (event.target.value > 10 && event.target.value < 100){
                    if (odds_4_1X2_1 < 1 && odds_4_1X2_2 > 0)
                    {
                        setOdds_4_1X2_1(100 - event.target.value - odds_4_1X2_2)
                    }
                    else if (odds_4_1X2_2 < 1 && odds_4_1X2_1 > 0)
                    {
                        setOdds_4_1X2_2(100 - event.target.value - odds_4_1X2_1)
                    }
                }

                break
        }
    }

    const handleOdds1X2_2Change = (event) => {
        event.preventDefault()
        switch (event.target.id) {
            case "1":
                console.log("EKA")
                setOdds_1_1X2_2(parseInt(event.target.value))
                if (event.target.value > 10 && event.target.value < 100){
                    if (odds_1_1X2_1 < 1 && odds_1_1X2_X > 0)
                    {
                        setOdds_1_1X2_1(100 - event.target.value - odds_1_1X2_X)
                    }
                    else if (odds_1_1X2_X < 1 && odds_1_1X2_1 > 0)
                    {
                        setOdds_1_1X2_X(100 - event.target.value - odds_1_1X2_1)
                    }
                }

                break
            case "2":
                console.log("TOKA")
                setOdds_2_1X2_2(parseInt(event.target.value))
                if (event.target.value > 10 && event.target.value < 100){
                    if (odds_2_1X2_1 < 1 && odds_2_1X2_X > 0)
                    {
                        setOdds_2_1X2_1(100 - event.target.value - odds_2_1X2_X)
                    }
                    else if (odds_2_1X2_X < 1 && odds_2_1X2_1 > 0)
                    {
                        setOdds_2_1X2_X(100 - event.target.value - odds_2_1X2_1)
                    }
                }

                break
            case "3":
                console.log("KOLMAS")
                setOdds_3_1X2_2(parseInt(event.target.value))
                if (event.target.value > 10 && event.target.value < 100){
                    if (odds_3_1X2_1 < 1 && odds_3_1X2_X > 0)
                    {
                        setOdds_3_1X2_1(100 - event.target.value - odds_3_1X2_X)
                    }
                    else if (odds_3_1X2_X < 1 && odds_3_1X2_1 > 0)
                    {
                        setOdds_3_1X2_X(100 - event.target.value - odds_3_1X2_1)
                    }
                }

                break
            case "4":
                console.log("NELJÄS")
                setOdds_4_1X2_2(parseInt(event.target.value))
                if (event.target.value > 10 && event.target.value < 100){
                    if (odds_4_1X2_1 < 1 && odds_4_1X2_X > 0)
                    {
                        setOdds_4_1X2_1(100 - event.target.value - odds_4_1X2_X)
                    }
                    else if (odds_4_1X2_X < 1 && odds_4_1X2_1 > 0)
                    {
                        setOdds_4_1X2_X(100 - event.target.value - odds_4_1X2_1)
                    }
                }

                break
        }
    }

    const handleOdds12_1Change = (event) => {
        event.preventDefault()
        switch (event.target.id) {
            case "1":
                console.log("EKA")
                setOdds_1_12_1(parseInt(event.target.value))
                if (event.target.value > 0 && event.target.value < 100){
                    setOdds_1_12_2(100 - event.target.value)
                }

                break
            case "2":
                console.log("TOKA")
                setOdds_2_12_1(parseInt(event.target.value))
                if (event.target.value > 0 && event.target.value < 100){
                    setOdds_2_12_2(100 - event.target.value)
                }

                break
            case "3":
                console.log("KOLMAS")
                setOdds_3_12_1(parseInt(event.target.value))
                if (event.target.value > 0 && event.target.value < 100){
                    setOdds_3_12_2(100 - event.target.value)
                }

                break
            case "4":
                console.log("NELJÄS")
                setOdds_4_12_1(parseInt(event.target.value))
                if (event.target.value > 0 && event.target.value < 100){
                    setOdds_4_12_2(100 - event.target.value)
                }

                break
        }
    }

    const handleOdds12_2Change = (event) => {
        event.preventDefault()
        switch (event.target.id) {
            case "1":
                console.log("EKA")
                setOdds_1_12_2(parseInt(event.target.value))
                if (event.target.value > 0 && event.target.value < 100){
                    setOdds_1_12_1(100 - event.target.value)
                }

                break
            case "2":
                console.log("TOKA")
                setOdds_2_12_2(parseInt(event.target.value))
                if (event.target.value > 0 && event.target.value < 100){
                    setOdds_2_12_1(100 - event.target.value)
                }

                break
            case "3":
                console.log("KOLMAS")
                setOdds_3_12_2(parseInt(event.target.value))
                if (event.target.value > 0 && event.target.value < 100){
                    setOdds_3_12_1(100 - event.target.value)
                }

                break
            case "4":
                console.log("NELJÄS")
                setOdds_4_12_2(parseInt(event.target.value))
                if (event.target.value > 0 && event.target.value < 100){
                    setOdds_4_12_1(100 - event.target.value)
                }

                break
        }
    }

    const handleOver45Change = (event) => {
        event.preventDefault()
        switch (event.target.id) {
            case "1":
                console.log("EKA")
                setOver_1_45(parseInt(event.target.value))
                if (event.target.value > 0 && event.target.value < 100){
                    setUnder_1_45(100 - event.target.value)
                }

                break
            case "2":
                console.log("TOKA")
                setOver_2_45(parseInt(event.target.value))
                if (event.target.value > 0 && event.target.value < 100){
                    setUnder_2_45(100 - event.target.value)
                }

                break
            case "3":
                console.log("KOLMAS")
                setOver_3_45(parseInt(event.target.value))
                if (event.target.value > 0 && event.target.value < 100){
                    setUnder_3_45(100 - event.target.value)
                }

                break
            case "4":
                console.log("NELJÄS")
                setOver_4_45(parseInt(event.target.value))
                if (event.target.value > 0 && event.target.value < 100){
                    setUnder_4_45(100 - event.target.value)
                }

                break
        }
    }

    const handleUnder45Change = (event) => {
        event.preventDefault()
        switch (event.target.id) {
            case "1":
                console.log("EKA")
                setUnder_1_45(parseInt(event.target.value))
                if (event.target.value > 0 && event.target.value < 100){
                    setOver_1_45(100 - event.target.value)
                }

                break
            case "2":
                console.log("TOKA")
                setUnder_2_45(parseInt(event.target.value))
                if (event.target.value > 0 && event.target.value < 100){
                    setOver_2_45(100 - event.target.value)
                }

                break
            case "3":
                console.log("KOLMAS")
                setUnder_3_45(parseInt(event.target.value))
                if (event.target.value > 0 && event.target.value < 100){
                    setOver_3_45(100 - event.target.value)
                }

                break
            case "4":
                console.log("NELJÄS")
                setUnder_4_45(parseInt(event.target.value))
                if (event.target.value > 0 && event.target.value < 100){
                    setOver_4_45(100 - event.target.value)
                }

                break
        }
    }

    const handleOver55Change = (event) => {
        event.preventDefault()
        switch (event.target.id) {
            case "1":
                console.log("EKA")
                setOver_1_55(parseInt(event.target.value))
                if (event.target.value > 0 && event.target.value < 100){
                    setUnder_1_55(100 - event.target.value)
                }

                break
            case "2":
                console.log("TOKA")
                setOver_2_55(parseInt(event.target.value))
                if (event.target.value > 0 && event.target.value < 100){
                    setUnder_2_55(100 - event.target.value)
                }

                break
            case "3":
                console.log("KOLMAS")
                setOver_3_55(parseInt(event.target.value))
                if (event.target.value > 0 && event.target.value < 100){
                    setUnder_3_55(100 - event.target.value)
                }

                break
            case "4":
                console.log("NELJÄS")
                setOver_4_55(parseInt(event.target.value))
                if (event.target.value > 0 && event.target.value < 100){
                    setUnder_4_55(100 - event.target.value)
                }

                break
        }
    }

    const handleUnder55Change = (event) => {
        event.preventDefault()
        switch (event.target.id) {
            case "1":
                console.log("EKA")
                setUnder_1_55(parseInt(event.target.value))
                if (event.target.value > 0 && event.target.value < 100){
                    setOver_1_55(100 - event.target.value)
                }

                break
            case "2":
                console.log("TOKA")
                setUnder_2_55(parseInt(event.target.value))
                if (event.target.value > 0 && event.target.value < 100){
                    setOver_2_55(100 - event.target.value)
                }

                break
            case "3":
                console.log("KOLMAS")
                setUnder_3_55(parseInt(event.target.value))
                if (event.target.value > 0 && event.target.value < 100){
                    setOver_3_55(100 - event.target.value)
                }

                break
            case "4":
                console.log("NELJÄS")
                setUnder_4_55(parseInt(event.target.value))
                if (event.target.value > 0 && event.target.value < 100){
                    setOver_4_55(100 - event.target.value)
                }

                break
        }
    }

    const handleSaveChanges = (event) => {
        event.preventDefault()

        let percentages = {}
        const id = event.target.id

        switch (id) {
            case "1":
                console.log("EKA")
                percentages = { percentages: [odds_1_1X2_1, odds_1_1X2_X, odds_1_1X2_2, over_1_55, under_1_55] }
                break
            case "2":
                console.log("TOKA")
                percentages = { percentages: [odds_2_1X2_1, odds_2_1X2_X, odds_2_1X2_2, over_2_55, under_2_55] }
                break
            case "3":
                console.log("KOLMAS")
                percentages = { percentages: [odds_3_1X2_1, odds_3_1X2_X, odds_3_1X2_2, over_3_55, under_3_55] }
                break
            case "4":
                console.log("NELJÄS")
                percentages = { percentages: [odds_4_1X2_1, odds_4_1X2_X, odds_4_1X2_2, over_4_55, under_4_55] }
                break
        }

        const headers = {
            'x-access-token': props.token
        };

        console.log('moniveto request', percentages)
        axios.post(C.BETFAIR_BASE_URL + "/moniveto", percentages,{headers: headers})
            .then(response => {
                console.log("moniveto response")
                console.log('response.data', JSON.parse(response.data))
                switch (id) {
                    case "1":
                        console.log("EKA")
                        setScore_1_Odds(JSON.parse(response.data))
                        break
                    case "2":
                        console.log("TOKA")
                        setScore_2_Odds(JSON.parse(response.data))
                        break
                    case "3":
                        console.log("KOLMAS")
                        setScore_3_Odds(JSON.parse(response.data))
                        break
                    case "4":
                        console.log("NELJÄS")
                        setScore_4_Odds(JSON.parse(response.data))
                        break
                }
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    const handleExitDetails = (event) => {
        event.preventDefault()
    }

    useEffect(() => {
        console.log('Get rows');
        getRows();
    }, []);

    const getRows = () => {
        let id = props.location.pathname;
        setIsLoading(true);
        setShowUpdateTime("Updating...");
        axios.get(C.BASE_URL +id, {headers: { 'x-access-token': props.token }})
            .then(response => {
                console.log('Moniveto rows response', response);
                setRows(response.data.rows);
                setFixtures(response.data.fixtures);
                if (!updatesTimeRunning)
                {
                    //getUpdateTime(response.data.fixtures.id);
                }

                setUpdatesTimeRunning(true);
                setIsLoading(false);
            })
            .catch((error) => {
                console.log(error);
            })
    };

    const monivetoMainList = () => {
        if (fixtures)
        {
            let percentages = [];
            if (fixtures.games.length > 0)
            {
                percentages.push(<td><MonivetoPercentagesForm
                    id = "1"
                    odds1X2_1 = {odds_1_1X2_1}
                    odds1X2_X = {odds_1_1X2_X}
                    odds1X2_2 = {odds_1_1X2_2}
                    odds12_1 = {odds_1_12_1}
                    odds12_2 = {odds_1_12_2}
                    over45 = {over_1_45}
                    under45 = {under_1_45}
                    over55 = {over_1_55}
                    under55 = {under_1_55}
                    returnRate = {returnRate}
                    scoreOdds = {score_1_Odds}
                    handleOdds1X2_1Change = {handleOdds1X2_1Change}
                    handleOdds1X2_XChange = {handleOdds1X2_XChange}
                    handleOdds1X2_2Change = {handleOdds1X2_2Change}
                    handleOdds12_1Change = {handleOdds12_1Change}
                    handleOdds12_2Change = {handleOdds12_2Change}
                    handleOver45Change = {handleOver45Change}
                    handleUnder45Change = {handleUnder45Change}
                    handleOver55Change = {handleOver55Change}
                    handleUnder55Change = {handleUnder55Change}
                    handleSaveChanges = {handleSaveChanges}
                /></td>);
            }

            if (fixtures.games.length > 1)
            {
                percentages.push(<td><MonivetoPercentagesForm
                    id = "2"
                    odds1X2_1 = {odds_2_1X2_1}
                    odds1X2_X = {odds_2_1X2_X}
                    odds1X2_2 = {odds_2_1X2_2}
                    odds12_1 = {odds_2_12_1}
                    odds12_2 = {odds_2_12_2}
                    over45 = {over_2_45}
                    under45 = {under_2_45}
                    over55 = {over_2_55}
                    under55 = {under_2_55}
                    returnRate = {returnRate}
                    scoreOdds = {score_2_Odds}
                    handleOdds1X2_1Change = {handleOdds1X2_1Change}
                    handleOdds1X2_XChange = {handleOdds1X2_XChange}
                    handleOdds1X2_2Change = {handleOdds1X2_2Change}
                    handleOdds12_1Change = {handleOdds12_1Change}
                    handleOdds12_2Change = {handleOdds12_2Change}
                    handleOver45Change = {handleOver45Change}
                    handleUnder45Change = {handleUnder45Change}
                    handleOver55Change = {handleOver55Change}
                    handleUnder55Change = {handleUnder55Change}
                    handleSaveChanges = {handleSaveChanges}
                /></td>);
            }

            if (fixtures.games.length > 2)
            {
                percentages.push(<td><MonivetoPercentagesForm
                    id = "3"
                    odds1X2_1 = {odds_3_1X2_1}
                    odds1X2_X = {odds_3_1X2_X}
                    odds1X2_2 = {odds_3_1X2_2}
                    odds12_1 = {odds_3_12_1}
                    odds12_2 = {odds_3_12_2}
                    over45 = {over_3_45}
                    under45 = {under_3_45}
                    over55 = {over_3_55}
                    under55 = {under_3_55}
                    returnRate = {returnRate}
                    scoreOdds = {score_3_Odds}
                    handleOdds1X2_1Change = {handleOdds1X2_1Change}
                    handleOdds1X2_XChange = {handleOdds1X2_XChange}
                    handleOdds1X2_2Change = {handleOdds1X2_2Change}
                    handleOdds12_1Change = {handleOdds12_1Change}
                    handleOdds12_2Change = {handleOdds12_2Change}
                    handleOver45Change = {handleOver45Change}
                    handleUnder45Change = {handleUnder45Change}
                    handleOver55Change = {handleOver55Change}
                    handleUnder55Change = {handleUnder55Change}
                    handleSaveChanges = {handleSaveChanges}
                /></td>);
            }

            if (fixtures.games.length > 3)
            {
                percentages.push(<td><MonivetoPercentagesForm
                    id = "4"
                    odds1X2_1 = {odds_4_1X2_1}
                    odds1X2_X = {odds_4_1X2_X}
                    odds1X2_2 = {odds_4_1X2_2}
                    odds12_1 = {odds_4_12_1}
                    odds12_2 = {odds_4_12_2}
                    over45 = {over_4_45}
                    under45 = {under_4_45}
                    over55 = {over_4_55}
                    under55 = {under_4_55}
                    returnRate = {returnRate}
                    scoreOdds = {score_4_Odds}
                    handleOdds1X2_1Change = {handleOdds1X2_1Change}
                    handleOdds1X2_XChange = {handleOdds1X2_XChange}
                    handleOdds1X2_2Change = {handleOdds1X2_2Change}
                    handleOdds12_1Change = {handleOdds12_1Change}
                    handleOdds12_2Change = {handleOdds12_2Change}
                    handleOver45Change = {handleOver45Change}
                    handleUnder45Change = {handleUnder45Change}
                    handleOver55Change = {handleOver55Change}
                    handleUnder55Change = {handleUnder55Change}
                    handleSaveChanges = {handleSaveChanges}
                /></td>);
            }

            return percentages;
        }
        else
        {
            return null;
        }
    };

    const monivetoMainTable = () => {
        return (<React.Fragment>
            <table className="table table-striped" style={{ marginTop: 20 }} >
                <thead>
                <tr>
                    <td>{fixtures && fixtures.games.length > 0 ? fixtures.games[0] : null}</td>
                    <td>{fixtures && fixtures.games.length > 1 ? fixtures.games[1] : null}</td>
                    <td>{fixtures && fixtures.games.length > 2 ? fixtures.games[2] : null}</td>
                    <td>{fixtures && fixtures.games.length > 3 ? fixtures.games[3] : null}</td>
                </tr>
                </thead>
                <tbody>
                 { monivetoMainList() }
                </tbody>
            </table>
        </React.Fragment>);
    };

    const handleCalculateRows = (event) => {
        event.preventDefault()
        if (rows){
            let evRows = []
            for (let i = 0; i < rows.length; i++){
                if (rows[0].hasOwnProperty("home4")){
                    try {
                        if (score_1_Odds){
                            const index1 = score_1_Odds.odds.findIndex((o => o.home === rows[i].home1 && o.away === rows[i].away1));
                            if (index1 >= 0){
                                if (score_2_Odds){
                                    const index2 = score_2_Odds.odds.findIndex((o => o.home === rows[i].home2 && o.away === rows[i].away2));
                                    if (index2 >= 0){
                                        if (score_3_Odds){
                                            const index3 = score_3_Odds.odds.findIndex((o => o.home === rows[i].home3 && o.away === rows[i].away3));
                                            if (index3 >= 0){
                                                if (score_4_Odds){
                                                    const index4 = score_4_Odds.odds.findIndex((o => o.home === rows[i].home4 && o.away === rows[i].away4));
                                                    if (index4 >= 0){
                                                        const odds1 = score_1_Odds.odds[index1].odds
                                                        const odds2 = score_2_Odds.odds[index2].odds
                                                        const odds3 = score_3_Odds.odds[index3].odds
                                                        const odds4 = score_4_Odds.odds[index4].odds
                                                        const odds = odds1 * odds2 * odds3 * odds4
                                                        if (rows[i].EUR > 0){
                                                            const ev = rows[i].WIN / odds
                                                            const kelly = ((ev - 1) / (odds - 1)) / kellyDivider
                                                            if (ev >= eVlimit && kelly >= kellyLimit && rows[i].WIN >= oddsLimit){
                                                                evRows.push(
                                                                {
                                                                    ev: ev.round(3),
                                                                    kelly: kelly.round(5),
                                                                    win: rows[i].WIN,
                                                                    odds: odds.round(0),
                                                                    game1: rows[i].home1 + " - " + rows[i].away1,
                                                                    game2: rows[i].home2 + " - " + rows[i].away2,
                                                                    game3: rows[i].home3 + " - " + rows[i].away3,
                                                                    game4: rows[i].home4 + " - " + rows[i].away4
                                                                })
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    catch (error){
                        console.log(error)
                    }
                }
                else if (rows[0].hasOwnProperty("home3")){
                    try {
                        if (score_1_Odds){
                            const index1 = score_1_Odds.odds.findIndex((o => o.home === rows[i].home1 && o.away === rows[i].away1));
                            if (index1 >= 0){
                                if (score_2_Odds){
                                    const index2 = score_2_Odds.odds.findIndex((o => o.home === rows[i].home2 && o.away === rows[i].away2))
                                    if (index2 >= 0){
                                        if (score_3_Odds){
                                            const index3 = score_3_Odds.odds.findIndex((o => o.home === rows[i].home3 && o.away === rows[i].away3));
                                            if (index3 >= 0){
                                                const odds1 = score_1_Odds.odds[index1].odds
                                                const odds2 = score_2_Odds.odds[index2].odds
                                                const odds3 = score_3_Odds.odds[index3].odds
                                                const odds = odds1 * odds2 * odds3
                                                if (rows[i].EUR > 0){
                                                    const ev = rows[i].WIN / odds
                                                    const kelly = ((ev - 1) / (odds - 1)) / kellyDivider
                                                    if (ev >= eVlimit && kelly >= kellyLimit && rows[i].WIN >= oddsLimit){
                                                        evRows.push(
                                                            {
                                                                ev: ev.round(3),
                                                                kelly: kelly.round(5),
                                                                win: rows[i].WIN,
                                                                odds: odds.round(0),
                                                                game1: rows[i].home1 + " - " + rows[i].away1,
                                                                game2: rows[i].home2 + " - " + rows[i].away2,
                                                                game3: rows[i].home3 + " - " + rows[i].away3,
                                                                game4: null
                                                            })
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    catch (error){
                        console.log(error)
                    }
                }
                else{
                    try {
                        if (score_1_Odds){
                            const index1 = score_1_Odds.odds.findIndex((o => o.home === rows[i].home1 && o.away === rows[i].away1));
                            if (index1 >= 0){
                                if (score_2_Odds){
                                    const index2 = score_2_Odds.odds.findIndex((o => o.home === rows[i].home2 && o.away === rows[i].away2));
                                    if (index2 >= 0){
                                        const odds1 = score_1_Odds.odds[index1].odds
                                        const odds2 = score_2_Odds.odds[index2].odds
                                        const odds = odds1 * odds2
                                        if (rows[i].EUR > 0){
                                            const ev = rows[i].WIN / odds
                                            const kelly = ((ev - 1) / (odds - 1)) / kellyDivider
                                            if (ev >= eVlimit && kelly >= kellyLimit && rows[i].WIN >= oddsLimit){
                                                evRows.push(
                                                    {
                                                        ev: ev.round(3),
                                                        kelly: kelly.round(5),
                                                        win: rows[i].WIN,
                                                        odds: odds.round(0),
                                                        game1: rows[i].home1 + " - " + rows[i].away1,
                                                        game2: rows[i].home2 + " - " + rows[i].away2,
                                                        game3: null,
                                                        game4: null
                                                    })
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    catch (error){
                        console.log(error)
                    }
                }
            }

            console.log("evRows", evRows)

            if (evRows.length > 0){
                let orderedRows = evRows.sort((a, b) => (b.ev - a.ev))
                console.log("orderedRows", orderedRows)
                let rowsToShow = []
                let overallProb = 0
                let overallKel = 0
                for (let i = 0; i < orderedRows.length && i < maxRows; i++){
                    overallProb += 1 / orderedRows[i].odds
                    overallKel += orderedRows[i].kelly
                    rowsToShow.push(
                        <tr>
                            <td>{orderedRows[i].ev.round(3)} </td>
                            <td>{orderedRows[i].kelly.round(5)} </td>
                            <td>{orderedRows[i].win}</td>
                            <td>{orderedRows[i].odds.round(0)}</td>
                            <td>{orderedRows[i].game1}</td>
                            <td>{orderedRows[i].game2}</td>
                            <td>{orderedRows[i].game3}</td>
                            <td>{orderedRows[i].game4}</td>
                        </tr>)
                }

                setOverallProbability(overallProb.round(2))
                setOverallKelly(overallKel.round(5))

                console.log("rowsToShow", rowsToShow)
                setCalculatedRows(rowsToShow)
            }
            else{
                setCalculatedRows(null)
            }
        }
    }

    const calculatedTable = () => {
        console.log("calculatedRows", calculatedRows)
        if (calculatedRows && calculatedRows.length > 0 && showRows){
            return (<React.Fragment>
                <table className="table table-striped" style={{ marginTop: 20 }} >
                    <thead>
                    <tr>
                        <td>EV</td>
                        <td>Kelly</td>
                        <td>Kerroin</td>
                        <td>Kerroinraja</td>
                        <td>{fixtures && fixtures.games.length > 0 ? fixtures.games[0] : null}</td>
                        <td>{fixtures && fixtures.games.length > 1 ? fixtures.games[1] : null}</td>
                        <td>{fixtures && fixtures.games.length > 2 ? fixtures.games[2] : null}</td>
                        <td>{fixtures && fixtures.games.length > 3 ? fixtures.games[3] : null}</td>
                    </tr>
                    </thead>
                    <tbody>
                    { calculatedRows }
                    </tbody>
                </table>
            </React.Fragment>);
        }
        else{
            return null
        }
    };

    const handleeVInputChange = (event) => {
        seteVlimit(event.target.value);
    };

    const handleKellyInputChange = (event) => {
        setKellyLimit(event.target.value);
    };

    const handleKellyDividerInputChange = (event) => {
        setKellyDivider(event.target.value);
    };

    const handleWinLimitInputChange = (event) => {
        setOddsLimit(event.target.value);
    }

    const handleMaxRowsInputChange = (event) => {
        setMaxRows(event.target.value);
    };

    const calculated = () => {
        if (score_1_Odds)
        {
            return (<React.Fragment>
                <Form inline>
                    <Form.Label>eV limit</Form.Label>
                    <Form.Control inline label="1"
                                  name="EV"
                                  type="number"
                                  step='0.1'
                                  value={eVlimit}
                                  onChange={handleeVInputChange} />
                    <Form.Label>Kelly limit</Form.Label>
                    <Form.Control inline label="2"
                                  name="Kelly"
                                  type="number"
                                  step='0.00001'
                                  value={kellyLimit}
                                  onChange={handleKellyInputChange} />

                    <Form.Label>Kelly jakaja</Form.Label>
                    <Form.Control inline label="3"
                                  name="kellyDivider"
                                  type="number"
                                  step='1'
                                  value={kellyDivider}
                                  onChange={handleKellyDividerInputChange} />

                    <Form.Label>Minimikerroin</Form.Label>
                    <Form.Control inline label="4"
                                  name="winLimit"
                                  type="number"
                                  step='100'
                                  value={oddsLimit}
                                  onChange={handleWinLimitInputChange} />
                    <Form.Label>Rivimäärä</Form.Label>
                    <Form.Control inline label="5"
                                  name="winLimit"
                                  type="number"
                                  step='100'
                                  value={maxRows}
                                  onChange={handleMaxRowsInputChange} />
                </Form>
                <br/>
                <Button onClick={handleCalculateRows} name={"calculate"} type="submit" text='Laske'></Button>
                { afterCalculation() }
                <br/>
                { calculatedTable() }
            </React.Fragment>);
        }
        else
        {
            return null
        }
    }

    const afterCalculation  = () => {

        if (calculatedRows && calculatedRows.length > 0){
            return (<React.Fragment>
                        <label>&nbsp;&nbsp;Rivejä yhteensä: {calculatedRows.length}&nbsp;&nbsp;</label>
                        <label>&nbsp;&nbsp;Kokonaistodennäköisyys: {100 * overallProbability}&nbsp; % &nbsp;&nbsp;</label>
                        <label>&nbsp;&nbsp;Kelly: {overallKelly}&nbsp;&nbsp;</label>
                        <input
                            type="checkbox"
                            onChange={(event) => setShowRows(event.currentTarget.checked)}
                            checked={showRows}
                        />
                        <label>&nbsp;&nbsp;Näytä rivit&nbsp;</label>
                    </React.Fragment>
            )
        }
        else{
            return null
        }
    }

    const getUpdateTime = (id) => {
        try {
            console.log('Get update time.');
            setInterval(async () => {
                if (!isLoading)
                {
                    axios.get(C.BASE_URL + "/monivetoupdatetime/" + id, {headers: { 'x-access-token': props.token }})
                        .then(response => {
                            const date = new Date(response.data.mtime).getTime();
                            setShowUpdateTime(new Date(response.data.mtime));

                            if (date > updateTime)
                            {
                                getRows();
                            }

                            setUpdateTime(date);
                            console.log('response.data', response.data);
                        })
                        .catch(function (error) {
                            console.log(error);
                        })
                }
            }, 10000);
        } catch(e) {
            console.log(e);
        }
    };

    return (
        <React.Fragment>
            { calculated() }
            { monivetoMainTable() }
        </React.Fragment>
    );
};

Number.prototype.round = function(places) {
    return +(Math.round(this + "e+" + places)  + "e-" + places);
};

export default MonivetoRows;