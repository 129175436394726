const betfairIds = {
  //BANDY_SPORT_ID: ,

  BASEBALL_SPORT_ID: 7511,
  MLB_LEAGUE_ID: 12273541,

  BASKETBALL_SPORT_ID: 7522,
  //FIBA_EUROBASKET_LEAGUE_ID: ,
  //FIBA_WC_LEAGUE_ID: ,
  //KORISLIIGA_LEAGUE_ID: ,
  NBA_LEAGUE_ID: 10547864,

  DARTS_SPORT_ID: 3503,
  ESPORTS_SPORT_ID: 27454571,

  //FLOORBALL_SPORT_ID: ,
  //SUOMEN_CUP_LEAGUE_ID: ,

  AMERICAN_FOOTBALL_SPORT_ID: 6423,
  NFL_LEAGUE_ID: 12282733,
  //NFL_ALTERNATE_LINES_LEAGUE_ID: ,
  //NFL_PRESEASON_LEAGUE_ID: ,
  //NFL_SUPER_BOWL_PROPS_LEAGUE_ID: ,

  //FUTSAL_SPORT_ID: ,
  GOLF_SPORT_ID: 3,
  HANDBALL_SPORT_ID: 468328,

  ICE_HOCKEY_SPORT_ID: 7524,
  CZECH_EXTRA_LIGA_LEAGUE_ID: 11475759,
  //METAL_LIGAEN_LEAGUE_ID: ,
  //CHL_LEAGUE_ID: ,
  SM_LIIGA_LEAGUE_ID: 10546028,
  DEL_LEAGUE_ID: 86315,
  NHL_LEAGUE_ID: 12300973,
  //OLYMPICS_HOCKEY_LEAGUE_ID: ,
  KHL_LEAGUE_ID: 10546104,
  //SLOVAKIA_EXTRA_LIGA_LEAGUE_ID: ,
  HOCKEY_ALLSVENSKAN_LEAGUE_ID: 10546796,
  SHL_LEAGUE_ID: 10546040,
  NLA_LEAGUE_ID: 11480943,
  //IHHF_U20_LEAGUE_ID: ,
  //IHHF_WC_LEAGUE_ID: ,
  //IHHF_WC_OT_LEAGUE_ID: ,
  //INTERNATIONAL_LEAGUE_ID: ,
  //MESTIS_LEAGUE_ID: ,
  //EURO_HOCKEY_TOUR_LEAGUE_ID: ,
  //VHL_LEAGUE_ID: ,
  //MHL_LEAGUE_ID: ,
  //SWISS_LEAGUE_ID: ,

  MIXED_MARTIAL_ARTS_SPORT_ID: 26420387,
  UCF_LEAGUE_ID: 10581356,

  SNOOKER_SPORT_ID: 6422,
  
  SOCCER_SPORT_ID: 1,
  SUOMEN_CUP_LEAGUE_ID: 20601,
  //SUOMEN_CUP_CORNERS_LEAGUE_ID: ,
  //KAKKONEN_LEAGUE_ID: ,
  //KAKKONEN_CORNERS_LEAGUE_ID: ,
  //KAKKOSEN_CUP_LEAGUE_ID: ,
  //KANSALLINEN_LIIGA_LEAGUE_ID: ,
  //KOLMONEN_LEAGUE_ID: ,
  //LIIGA_CUP_LEAGUE_ID: ,
  //LIIGA_CUP_CORNERS_LEAGUE_ID: ,
  //FINLAND_U20_LEAGUE_ID: ,
  //VEIKKAUSLIIGA_LEAGUE_ID: ,
  //VEIKKAUSLIIGA_CORNERS_LEAGUE_ID: ,
  //YKKONEN_LEAGUE_ID: ,
  //YKKONEN_CORNERS_LEAGUE_ID: ,
  ENGLAND_CHAMPIONSHIP_LEAGUE_ID: 7129730,
  ENGLAND_FA_CUP_LEAGUE_ID: 30558,
  ENGLAND_LEAGUE_1_LEAGUE_ID: 35,
  ENGLAND_LEAGUE_2_LEAGUE_ID: 37,
  ENGLAND_LEAGUE_CUP_LEAGUE_ID: 2134,
  ENGLAND_PREMIER_LEAGUE_ID: 10932509,
  LIGUE_1_LEAGUE_ID: 55,
  SERIE_A_LEAGUE_ID: 81,
  COPPA_ITALIA_LEAGUE_ID: 12214429,
  LA_LIGA_LEAGUE_ID: 117,
  SEQUNDA_DIVISION_LEAGUE_ID: 12204313,
  SPAIN_CUP_LEAGUE_ID: 12801,
  //ALLSVENSKAN_LEAGUE_ID: ,
  UEFA_CHAMPIONS_LEAGUE_ID: 228,
  UEFA_EUROPA_LEAGUE_ID: 2005,

  //AFC_LEAGUE_ID: ,
  IRAN_AZADEGAN_LEAGUE_ID: 11624804,
  //IRAN_CUP_LEAGUE_ID: ,
  //IRAN_PRO_LEAGUE_ID: ,
  QATAR_STARS_LEAGUE_ID: 4488516,
  //QATAR_STARS_CUP_LEAGUE_ID: ,
  SAUDI_ARABIA_PRO_LEAGUE_ID: 12209405,
  //UAE_LEAGUE_ID: ,
  //UAE_CUP_LEAGUE_ID: ,

  //TABLE_TENNIS_SPORT_ID: ,

  TENNIS_SPORT_ID: 2,
  /*ATP_AUSTRALIAN_OPEN_LEAGUE_ID: ,
  ATP_AUSTRALIAN_OPEN_QUALIFIERS_LEAGUE_ID: ,
  ATP_AUSTRALIAN_OPEN_R1_LEAGUE_ID: ,
  ATP_AUSTRALIAN_OPEN_R2_LEAGUE_ID: ,
  ATP_AUSTRALIAN_OPEN_R3_LEAGUE_ID: ,
  ATP_AUSTRALIAN_OPEN_R16_LEAGUE_ID: ,
  ATP_AUSTRALIAN_OPEN_QF_LEAGUE_ID: ,
  ATP_AUSTRALIAN_OPEN_SF_LEAGUE_ID: ,
  ATP_AUSTRALIAN_OPEN_FINAL_LEAGUE_ID: ,
  ATP_FRENCH_OPEN_LEAGUE_ID: ,
  ATP_FRENCH_OPEN_QUALIFIERS_LEAGUE_ID: ,
  ATP_FRENCH_OPEN_R1_LEAGUE_ID: ,
  ATP_FRENCH_OPEN_R2_LEAGUE_ID: ,
  ATP_FRENCH_OPEN_R3_LEAGUE_ID: ,
  ATP_FRENCH_OPEN_R16_LEAGUE_ID: ,
  ATP_FRENCH_OPEN_QF_LEAGUE_ID: ,
  ATP_FRENCH_OPEN_SF_LEAGUE_ID: ,
  ATP_FRENCH_OPEN_FINAL_LEAGUE_ID: ,
  ATP_WIMBLEDON_OPEN_LEAGUE_ID: ,
  ATP_WIMBLEDON_OPEN_QUALIFIERS_LEAGUE_ID: ,
  ATP_WIMBLEDON_OPEN_R1_LEAGUE_ID: ,
  ATP_WIMBLEDON_OPEN_R2_LEAGUE_ID: ,
  ATP_WIMBLEDON_OPEN_R3_LEAGUE_ID: ,
  ATP_WIMBLEDON_OPEN_R16_LEAGUE_ID: ,
  ATP_WIMBLEDON_OPEN_QF_LEAGUE_ID: ,
  ATP_WIMBLEDON_OPEN_SF_LEAGUE_ID: ,
  ATP_WIMBLEDON_OPEN_FINAL_LEAGUE_ID: ,
  ATP_US_OPEN_LEAGUE_ID: ,
  ATP_US_OPEN_QUALIFIERS_LEAGUE_ID: ,
  ATP_US_OPEN_R1_LEAGUE_ID: ,
  ATP_US_OPEN_R2_LEAGUE_ID: ,
  ATP_US_OPEN_R3_LEAGUE_ID: ,
  ATP_US_OPEN_R16_LEAGUE_ID: ,
  ATP_US_OPEN_QF_LEAGUE_ID: ,
  ATP_US_OPEN_SF_LEAGUE_ID: ,
  ATP_US_OPEN_FINAL_LEAGUE_ID: ,*/

  VOLLEYBALL_SPORT_ID: 998917,
  /*MESTARUUSLIIGA_MEN_LEAGUE_ID: ,
  MESTARUUSLIIGA_WOMEN_LEAGUE_ID: ,
  FIVB_WC_LEAGUE_ID: ,
  VOLLEYBALL_FINLAND_CUP_LEAGUE_ID: ,*/

  //BIATHLON_SPORT_ID: ,
  //SKI_JUMPING_SPORT_ID: ,
  //CROSS_COUNTRY_SPORT_ID: ,
  //FORMULA1_SPORT_ID: ,
  MOTOR_SPORT_ID: 8,
  //CYCLING_SPORT_ID: 11,
  //NORDIC_COMBINED_SPORT_ID: ,
  //OLYMPICS_SPORT_ID: ,
  WINTER_SPORT_ID: 451485,
  ATHLECTICS_SPORT_ID: 3988
}

export {
  betfairIds
}