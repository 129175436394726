import React, { useState, useEffect } from 'react';
import '../../../node_modules/react-datetime/css/react-datetime.css';
import axios from "axios";
import C from "../../constants";
import moment from "moment";
import usePushNotifications from "../../usePushNotifications";

const VeikkausEbet = (props) => {

    const [results, setResults] = useState({});

    const {
        userConsent,
        pushNotificationSupported,
        userSubscription,
        onClickAskUserPermission,
        onClickSusbribeToPushNotification,
        onClickSendSubscriptionToPushServer,
        pushServerSubscriptionId,
        onClickSendNotification,
        error,
        loading
    } = usePushNotifications({token: props.token});

    useEffect(() => {
        getOdds();
    }, []);

    const getOdds = async () => {
        console.log('getOdds');
        if (props.token !== undefined && props.token !== null)
        {
            axios.get(C.BASE_URL + '/veikkausebet', {headers: { 'x-access-token': props.token }})
                .then(response => {
                    console.log(response.data);
                    setResults(response.data);
                })
                .catch(function (error){
                    console.log(error);
                });
        }
    };

    const veikkausEbetGamesList = () => {
        if (results)
        {
            let veikkausEbetGames = [];
            for (let i = 0; i < results.length; i++)
            {
                let home = '';
                let away = '';
                let draw = '';
                let openTime = new Date();
                for (let j = 0; j < results[i].veikkausEbet_rows[0].competitors.length; j++)
                {
                    if (results[i].veikkausEbet_rows[0].competitors[j].id.toString() === '1')
                    {
                        if (home.length > 0)
                        {
                            home += ' => ';
                        }

                        home += results[i].veikkausEbet_rows[0].competitors[j].odds / 100;
                    }
                    else if (results[i].veikkausEbet_rows[0].competitors[j].id.toString() === '2')
                    {
                        if (away.length > 0)
                        {
                            away += ' => ';
                        }

                        away += results[i].veikkausEbet_rows[0].competitors[j].odds / 100;
                    }
                    else if(results[i].veikkausEbet_rows[0].competitors[j].id.toString() === '3')
                    {
                        if (draw.length > 0)
                        {
                            draw += ' => ';
                        }

                        draw += results[i].veikkausEbet_rows[0].competitors[j].odds / 100;
                    }
                    else
                    {
                        console.log('results[i].veikkausEbet_rows[0].competitors', results[i].veikkausEbet_rows[0].competitors);
                    }

                    try
                    {
                        if (new Date(results[i].veikkausEbet_rows[0].competitors[j].updateTime).getTime() < openTime.getTime())
                        {
                            openTime = new Date(results[i].veikkausEbet_rows[0].competitors[j].updateTime);
                        }
                    }
                    catch
                    {
                        console.log('results[i].veikkausEbet_rows[0].competitors[j].updateTime', results[i].veikkausEbet_rows[0].competitors[j].updateTime);
                    }
                }
                veikkausEbetGames.push(<VeikkausEbetGame openTime = {openTime} drawTime = {results[i].veikkausEbet_drawTime} eventName={results[i].veikkausEbet_rows[0].eventName} home={home}  away={away} draw={draw}/>);
                //veikkausEbetGames.push(<VeikkausEbetGame eventName={results[i].rows[0].eventName} percentage={percentages[i]} id={i}/>);
            }

            return veikkausEbetGames;
        }
        else
        {
            return null;
        }
    };

    const VeikkausEbetGame = props => (
        <tr>
            <td>{moment(props.openTime).format(C.DATE_FORMAT + ' ' + C.TIME_FORMAT)}</td>
            <td>{moment(props.drawTime).format(C.DATE_FORMAT + ' ' + C.TIME_FORMAT)}</td>
            <td><label>{props.eventName}</label></td>
            <td><label>{props.home}</label></td>
            <td><label>{props.draw}</label></td>
            <td><label>{props.away}</label></td>
        </tr>
    );

    const veikkausEbetTable = () => {
        return (<React.Fragment>
            <table className="table table-hover" style={{ marginTop: 20 }} >
                <thead>
                <tr>
                    <th>Julkaistu</th>
                    <th>Alkamisaika</th>
                    <th>Joukkueet</th>
                    <th>1</th>
                    <th>X</th>
                    <th>2</th>
                </tr>
                </thead>
                <tbody>
                { veikkausEbetGamesList() }
                </tbody>
            </table>
        </React.Fragment>);
    };

    const Loading = ({ loading }) => (loading ? <div className="app-loader">Please wait, we are loading something...</div> : null);
    const Error = ({ error }) =>
        error ? (
            <section className="app-error">
                <h2>{error.name}</h2>
                <p>Error message : {error.message}</p>
                <p>Error code : {error.code}</p>
            </section>
        ) : null;

    const isConsentGranted = userConsent === "granted";

    return (
        <main>
            <Loading loading={loading} />

            <p>Push notification are {!pushNotificationSupported && "NOT"} supported by your device.</p>

            <p>
                User consent to recevie push notificaitons is <strong>{userConsent}</strong>.
            </p>

            <Error error={error} />

            <button disabled={!pushNotificationSupported || isConsentGranted} onClick={onClickAskUserPermission}>
                {isConsentGranted ? "Consent granted" : " Ask user permission"}
            </button>

            <button disabled={!pushNotificationSupported || !isConsentGranted || userSubscription} onClick={onClickSusbribeToPushNotification}>
                {userSubscription ? "Push subscription created" : "Create Notification subscription"}
            </button>

            <button disabled={!userSubscription || pushServerSubscriptionId} onClick={onClickSendSubscriptionToPushServer}>
                {pushServerSubscriptionId ? "Subscrption sent to the server" : "Send subscription to push server"}
            </button>

            {pushServerSubscriptionId && (
                <div>
                    <p>The server accepted the push subscrption!</p>
                    <button onClick={onClickSendNotification}>Send a notification</button>
                </div>
            )}

            <section>
                <h4>Your notification subscription details</h4>
                <pre>
                    <code>{JSON.stringify(userSubscription, null, " ")}</code>
                </pre>
            </section>
        </main>
    );
/*
    return (
        <React.Fragment>
            { veikkausEbetTable() }
        </React.Fragment>
    );*/
};

export default VeikkausEbet;