import C from "../../constants";

export const convertIdToLabel = (id) => {
    switch(id) {
        case 0:
            return C.PENDING;
        case 1:
            return C.WIN;
        case 2:
            return C.LOSE;
        case 3:
            return C.HALFWIN;
        case 4:
            return C.HALFLOSE;
        case 5:
            return C.PUSH;
        case 6:
            return C.CANCELLED;
        default:
            return '';
    }
};

export const convertLabelToId = (label) => {
    switch(label) {
        case C.PENDING:
            return 0;
        case C.WIN:
            return 1;
        case C.LOSE:
            return 2;
        case C.HALFWIN:
            return 3;
        case C.HALFLOSE:
            return 4;
        case C.PUSH:
            return 5;
        case C.CANCELLED:
            return 6;
        default:
            return 0;
    }
};