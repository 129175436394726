import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import  * as Datetime from 'react-datetime';
import moment from 'moment';
import PropTypes from 'prop-types';
import C from "../../constants";
import '../../../node_modules/react-datetime/css/react-datetime.css';
import axios from "axios";

const AddBet = (props) => {
    const dateNow = new Date().getTime();
    const m = moment();
    const roundTimeUp = m.minute() || m.second() || m.millisecond() ? m.add(2, 'hour').startOf('hour') : m.startOf('hour');
    const [sport, setSport] = useState('');
    const [series, setSeries] = useState('');
    const [seriesList, setSeriesList] = useState(props.names.series);
    const [home, setHome] = useState('');
    const [visitor, setVisitor] = useState('');
    const [teamsList, setTeamsList] = useState(props.names.footballTeams);
    const [dateTime, setDateTime] = useState(roundTimeUp);
    const [gameFormat, setGameFormat] = useState(props.names.gameFormats[0]);
    const [selection, setSelection] = useState(props.names.sides[0]);
    const [selectionList, setSelectionList] = useState(props.names.sides);
    const [booker, setBooker] = useState(props.names.bookers[0]);
    const [odds, setOdds] = useState('');
    const [closingOdds, setClosingOdds] = useState('');
    const [originalOdds, setOriginalOdds] = useState('');
    const [bet, setBet] = useState('');
    const [win, setWin] = useState('');
    const [live, setLive] = useState(false);
    const [halfTime, setHalfTime] = useState(false);
    const [women, setWomen] = useState(false);
    const [result, setResult] = useState(props.names.results[0]);
    const [useMatchbookWithCommission, setUseMatchbookWithCommission] = useState(true);
    const [matchbookCommission, setMatchbookCommission] = useState(4.0);
    const [useBetfairWithCommission, setUseBetfairWithCommission] = useState(true);
    const [betfairCommission, setBetfairCommission] = useState(2.0);
    const [response, setResponse] = useState(null);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [eventListNext, setEventListNext] = useState(['']);
    const [eventListPrevious, setEventListPrevious] = useState(['']);
    const [eventListShow, setEventListShow] = useState(['']);
    const [showNext, setShowNext] = useState(false);
    const [currentFixtureId, setCurrentFixtureId] = useState('');
    const [isPending, setIsPending] = useState(true);
    const [previousFixtures, setPreviousFixtures] = useState(dateNow);

    useEffect(() => {
        if (isPending)
        {
            if (props.token !== undefined && props.token !== null)
            {
                axios.get(C.BASE_URL + '/fixtures', {headers: { 'x-access-token': props.token }})
                    .then(response => {

                        let nextEvents = [];
                        let previousEvents = [];
                        let events = [];
                        //let temp2 = [];
                        //let counter = 888888;
                        for (let i = 0; i < response.data.length; i++)
                        {
                            //counter++;
                            if (new Date(response.data[i].fixtures_dateTime).getTime() > new Date().getTime() - 7200)
                            {
                                if (response.data[i].fixtures_sport === 'Esports')
                                {
                                    let temp = response.data[i];
                                    //temp2.push(temp);
                                    //console.log(temp.accounting_fixtureId);
                                    //temp.fixtures_id = counter;
                                    nextEvents.push(temp);
                                }
                                else{
                                    nextEvents.push(response.data[i]);
                                }

                                const event = {
                                    value: response.data[i].fixtures_id,
                                    label: response.data[i].fixtures_sport + ' - ' +
                                        response.data[i].fixtures_series +  ' - ' +
                                        response.data[i].fixtures_home +  ' - ' +
                                        response.data[i].fixtures_visitor +  ' - ' +
                                        moment(response.data[i].fixtures_dateTime).format(C.DATE_FORMAT + ' ' + C.TIME_FORMAT)
                                };

                                events.push(event);
                            }
                            else
                            {
                                //previousEvents.push(response.data[i]);

                                if (response.data[i].fixtures_sport === 'Esports')
                                {
                                    let temp = response.data[i];
                                    //temp2.push(temp);
                                    //console.log(temp.accounting_fixtureId);
                                    //temp.fixtures_id = counter;
                                    previousEvents.push(temp);
                                }
                                else{
                                    previousEvents.push(response.data[i]);
                                }
                            }
                        }

                        //console.log(temp2);
                        events.push({ value: 0, label: ' ' });
                        setEventListNext(nextEvents);
                        setEventListPrevious(previousEvents.reverse());
                        setEventListShow(events);
                        setIsPending(false);

                    })
                    .catch(function (error){
                        console.log(error);
                    });
            }
        }
    }, [isPending]);

    useEffect(() => {
        if (props.token !== undefined && props.token !== null)
        {
            updateEventList();
        }
    }, [showNext, sport, series]);

    const handleEventChange = (selected) => {
        if (selected.label.length > 1)
        {
            const splittedLabel = selected.label.split(' - ');
            setSport({value: splittedLabel[0], label: splittedLabel[0]});
            setSeries({value: splittedLabel[1], label: splittedLabel[1]});
            setHome({value: splittedLabel[2], label: splittedLabel[2]});
            setVisitor({value: splittedLabel[3], label: splittedLabel[3]});
            setDateTime(splittedLabel[4]);
            setCurrentFixtureId(selected.value);
            setSelectedEvent(selected);
        }
    };

    const handleSportChange = (selectedOption) => {
        setSport(selectedOption);
        setSeries({value: '', label: ''});
        setCurrentFixtureId(0);
        let seriesFromAPI = [];
        switch(selectedOption.label) {
            case 'Jalkapallo':
                setSeriesList(props.names.footballSeries);
                setTeamsList(props.names.footballTeams);
                seriesFromAPI.push('England Premier League');
                seriesFromAPI.push('Spain LaLiga Santander');
                seriesFromAPI.push('Italy Serie A');
                break;
            case 'Jääkiekko':
                setSeriesList(props.names.iceHockeySeries);
                setTeamsList(props.names.iceHockeyTeams);
                seriesFromAPI.push('NHL Regular season');
                seriesFromAPI.push('KHL Regular season');
                seriesFromAPI.push('Finland Liiga');
                seriesFromAPI.push('Finland Mestis Liiga');
                seriesFromAPI.push('Sweden SHL');
                break;
            case 'Tennis':
                setSeriesList('');
                setTeamsList(props.names.tennisPlayers);
                break;
            case 'Golf':
                setSeriesList(props.names.golfCompetitions);
                setTeamsList(props.names.golfPlayers);
                break;
            case 'Baseball':
                setSeriesList(props.names.baseballSeries);
                setTeamsList(props.names.baseBallTeams);
                seriesFromAPI.push('MLB Regular season');
                break;
            case 'Koripallo':
                setSeriesList(props.names.basketBallSeries);
                setTeamsList(props.names.basketBallTeams);
                seriesFromAPI.push('NBA Regular Season');
                break;
            case 'Lentopallo':
                setSeriesList(props.names.volleyBallSeries);
                setTeamsList(props.names.volleyBallTeams);
                break;
            case 'Amerikkalainen jalkapallo':
                setSeriesList(props.names.americanFootballSeries);
                setTeamsList(props.names.americanFootballTeams);
                break;
            case 'Esports':
                setSeriesList(props.names.esportsSeries);
                setTeamsList('');
                break;
            case 'Ravit':
                setSeriesList(props.names.trottingSeries);
                setTeamsList('');
                break;
            default:
                setSeriesList('');
                setTeamsList('');
                break;
        }

        //let temp = [];
        let events = [];
        const eventList = showNext ? eventListNext : eventListPrevious;

        for (let i = 0; i < eventList.length; i++)
        {
            if (selectedOption.label === eventList[i].fixtures_sport)
            {
                if (eventList[i].fixtures_sport === 'Ravit')
                {
                    console.log(eventList[i]);
                }

                const event = {
                    value: eventList[i].fixtures_id,
                    label: eventList[i].fixtures_sport + ' - ' +
                        eventList[i].fixtures_series +  ' - ' +
                        eventList[i].fixtures_home +  ' - ' +
                        eventList[i].fixtures_visitor +  ' - ' +
                        moment(eventList[i].fixtures_dateTime).format(C.DATE_FORMAT + ' ' + C.TIME_FORMAT)
                };

                //temp.push(eventList[i]);
                events.push(event);
                if (seriesFromAPI.indexOf(eventList[i].fixtures_series) === -1)
                {
                    seriesFromAPI.push(eventList[i].fixtures_series);
                }
            }
        }
        //console.log(temp);
        console.log(sport.label);
        console.log(series.label);
        console.log(events);

        events.push({ value: 0, label: ' ' });
        setEventListShow(events);

        if (seriesFromAPI.length > 0)
        {
            setSeriesList(convertToList(seriesFromAPI));
        }

        setCurrentFixtureId(0);
        setSelectedEvent({ value: 0, label: ' ' });
    };

    const convertToList = (list) => {
        let finalList = [];
        for (let i = 0; i < list.length; i++) {
            finalList.push({ value: list[i], label: list[i] });
        }

        return finalList;
    };

    const handleSportCreate = (inputValue) => {
        const newOption = {value: inputValue, label: inputValue};
        setSport(newOption);
        setCurrentFixtureId(0);
        setSelectedEvent({ value: 0, label: ' ' });
};

    const handleSeriesChange = (selectedOption) => {
        setSeries(selectedOption);
        setCurrentFixtureId(0);
    };

    const updateEventList = () => {
        const eventList = showNext ? eventListPrevious : eventListNext;

        //let temp = [];
        let events = [];
        for (let i = 0; i < eventList.length; i++)
        {

            if ((sport.label === eventList[i].fixtures_sport || !sport.label) && (series.label === eventList[i].fixtures_series || !series.label))
            {
                const event = {
                    value: eventList[i].fixtures_id,
                    label: eventList[i].fixtures_sport + ' - ' +
                        eventList[i].fixtures_series +  ' - ' +
                        eventList[i].fixtures_home +  ' - ' +
                        eventList[i].fixtures_visitor +  ' - ' +
                        moment(eventList[i].fixtures_dateTime).format(C.DATE_FORMAT + ' ' + C.TIME_FORMAT)
                };

                events.push(event);
                //temp.push(eventList[i]);
            }
        }

        //console.log(temp);
        console.log(sport.label);
        console.log(series.label);
        console.log(events);
        events.push({ value: 0, label: ' ' });
        setEventListShow(events);
        setSelectedEvent({ value: 0, label: ' ' });
    };

    const handleSeriesCreate = (inputValue) => {
        const newOption = {value: inputValue, label: inputValue};
        setSeries(newOption);
        setCurrentFixtureId(0);
        setSelectedEvent({ value: 0, label: ' ' });
    };

    const handleHomeChange = (selectedOption) => {
        setHome(selectedOption);
        setCurrentFixtureId(0);
        setSelectedEvent({ value: 0, label: ' ' });
    };

    const handleHomeCreate = (inputValue) => {
        const newOption = {value: inputValue, label: inputValue};
        setHome(newOption);
        setCurrentFixtureId(0);
        setSelectedEvent({ value: 0, label: ' ' });
    };

    const handleVisitorChange = (selectedOption) => {
        setVisitor(selectedOption);
        setCurrentFixtureId(0);
        setSelectedEvent({ value: 0, label: ' ' });
    };

    const handleVisitorCreate = (inputValue) => {
        const newOption = {value: inputValue, label: inputValue};
        setVisitor(newOption);
        setCurrentFixtureId(0);
        setSelectedEvent({ value: 0, label: ' ' });
    };

    const handleDateTimeChange = (moment) => {
        setDateTime(moment);
        setCurrentFixtureId(0);
        setSelectedEvent({ value: 0, label: ' ' });
    };

    const handleGameFormatChange = (selectedOption) => {
        setGameFormat(selectedOption);
        switch(selectedOption.label) {
            case C.SIDE:
                setSelectionList(props.names.sides);
                setSelection(props.names.sides[0]);
                break;
            case C.TOTAL:
                setSelectionList(props.names.totals);
                setSelection(props.names.totals[0]);
                break;
            case C.OUTRIGHT:
                setSelectionList(['']);
                setSelection({value: '', label: ''});
                break;
            case C.CORRECT_SCORE:
                setSelectionList(props.names.scores);
                setSelection(props.names.scores[0]);
                break;
            case C.H2H:
                setSelectionList(props.names.h2hs);
                setSelection(props.names.h2hs[0]);
                break;
            case C.CORNERS:
                setSelectionList(props.names.corners);
                setSelection(props.names.corners[0]);
                break;
            default:
                setSelectionList(['']);
                setSelection({value: '', label: ''});
                break;
        }
    };

    const handleGameFormatCreate = (inputValue) => {
        const newOption = {value: inputValue, label: inputValue};
        setGameFormat(newOption);
        setResponse(null);
    };

    const handleSelectionChange = (selectedOption) => {
        setSelection(selectedOption);
        setResponse(null);
    };

    const handleSelectionCreate = (inputValue) => {
        const newOption = {value: inputValue, label: inputValue};
        setSelectionList([...selectionList, newOption]);
        setSelection(newOption);
        setResponse(null);
    };

    const handleBookerChange = (selectedOption) => {
        setBooker(selectedOption);
        setResponse(null);
        setCurrentFixtureId(0);
    };

    const handleBookerCreate = (inputValue) => {
        const newOption = {value: inputValue, label: inputValue};
        setBooker(newOption);
        setResponse(null);
        setCurrentFixtureId(0);
    };

    const handleOddsInputChange = (event) => {
        setOdds(event.target.value);
        setOriginalOdds(event.target.value);
        setResponse(null);
    };

    const handleClosingOddsInputChange = (event) => {
        setClosingOdds(event.target.value);
        setResponse(null);
    };

    const handleInputClick = (event) => {
        setResponse(null);
        if (event.target.value === '')
        {
            switch(event.target.name) {
                case 'odds':
                    setOdds(2.0);
                    setOriginalOdds(2.0);
                    break;
                case 'closingOdds':
                    if (odds > 1)
                    {
                        setClosingOdds(odds);
                    }
                    break;
                case 'bet':
                    setBet(100);
                    break;
                case 'win':
                    break;
                default:
                    break;
            }
        }
    };

    const calculateWin = () => {
        setResponse(null);
        if (odds > 1 && bet > 0)
        {
            if (booker.label === 'Matchbook' && useMatchbookWithCommission)
            {
                const netOdds = (originalOdds - 1) * (1 - (matchbookCommission / 100)) + 1;
                const netWin = (netOdds * bet) - bet;
                setOdds(netOdds);
                setWin(netWin);
            }
            else if(booker.label === 'Betfair Exchange' && useBetfairWithCommission)
            {
                const netOdds = (originalOdds - 1) * (1 - (betfairCommission / 100)) + 1;
                const netWin = (netOdds * bet) - bet;
                setOdds(Math.round(netOdds * 1000) / 1000);
                setWin(Math.round(netWin * 1000) / 1000);
            }
            else
            {
                setWin(Math.round(((originalOdds * bet) - bet) * 1000) / 1000);
            }
        }
    };

    const handleBetInputChange = (event) => {
        setBet(event.target.value);
        setResponse(null);
    };

    const handleWinInputChange = (event) => {
        setWin(event.target.value);
        setResponse(null);
    };

    const handleClearValues = () => {
        setOdds('');
        setOriginalOdds('');
        setBet('');
        setWin('');
        setResponse(null);
    };

    const handleResultChange = (selectedOption) => {
        setResult(selectedOption);
        setResponse(null);
    };

    const handleAddBet = () => {
        const datetimeISO = moment(dateTime, C.DATE_FORMAT + C.TIME_FORMAT).toISOString();

        const data = {
            accounting_home: home.label,
            accounting_visitor: visitor.label,
            accounting_sport: sport.label,
            accounting_series: series.label,
            accounting_dateTime: datetimeISO,
            accounting_gameFormat: gameFormat.label,
            accounting_selection: selection.label,
            accounting_booker: booker.label,
            accounting_odds: odds,
            accounting_closing_odds: closingOdds,
            accounting_bet: bet,
            accounting_win: win,
            accounting_live: live,
            accounting_halfTime: halfTime,
            accounting_women: women,
            accounting_result: result.value
        };

        const allEvents = [eventListNext, eventListPrevious];
        const currentFixture = allEvents.find(x => x.fixtures_id === currentFixtureId);

        if (currentFixture)
        {
            data.accounting_home = currentFixture.fixtures_home;
            data.accounting_home_id = currentFixture.fixtures_home_id;
            data.accounting_visitor = currentFixture.fixtures_visitor;
            data.accounting_visitor_id = currentFixture.fixtures_visitor_id;
            data.accounting_sport = currentFixture.fixtures_sport;
            data.accounting_sport_id = currentFixture.fixtures_sport_id;
            data.accounting_series = currentFixture.fixtures_series;
            data.accounting_fixtureId = currentFixture.fixtures_id;
        }

        const headers = {
            'x-access-token': props.token
        };

        axios.post(C.BASE_URL + '/add', data, {headers: headers})
            .then(res => setResponse(res.data));

        if (previousFixtures + 2000000 < new Date().getTime())
        {
            console.log(previousFixtures + 2000000);
            console.log(new Date().getTime());
            setPreviousFixtures(new Date().getTime());
            setIsPending(true);
        }
    };

    const BookerSpecialCase = () => {
        if (booker.label === 'Matchbook')
        {
            return(
                <React.Fragment>
                    <br/>
                    <input
                        type="checkbox"
                        onChange={(event) => setUseMatchbookWithCommission(event.currentTarget.checked)}
                        checked={useMatchbookWithCommission}
                    />
                    <label>Huomioi Matchbookin </label>
                    <input
                        type="number"
                        step='0.1'
                        onChange={(event) => { setMatchbookCommission(event.target.value)}}
                        value={matchbookCommission}
                    />
                    <label> % komissio nettovoitosta</label>
                    <br/>
                </React.Fragment>);
        }
        else if(booker.label === 'Betfair Exchange')
        {
            return(
                <React.Fragment>
                    <br/>
                    <input
                        type="checkbox"
                        onChange={(event) => setUseBetfairWithCommission(event.currentTarget.checked)}
                        checked={useBetfairWithCommission}
                    />
                    <label>Huomioi Betfairin </label>
                    <input
                        type="number"
                        step='0.1'
                        onChange={(event) => { setBetfairCommission(event.target.value)}}
                        value={betfairCommission}
                    />
                    <label> % komissio nettovoitosta</label>
                    <br/>
                </React.Fragment>);
        }
        else
        {
            return null;
        }
    };

    const Response = () =>{
        if (response !== null)
        {
            return(
                <React.Fragment>
                    <br/>
                    <br/>
                    <label>Tallennettu:</label>
                    <br/>
                    <label>{response.accounting_home + ' - ' + response.accounting_visitor}</label>
                    <br/>
                    <label>{response.accounting_selection + ' - ' + response.accounting_odds + ' - ' + response.accounting_booker}</label>
                </React.Fragment>);
        }
        else{
            return null;
        }
    };

    useEffect(() => {
        calculateWin();
    }, [useMatchbookWithCommission, matchbookCommission, useBetfairWithCommission, betfairCommission]);

    return (
        <React.Fragment>
            <CreatableSelect
                isSearchable
                autoFocus
                name="sport"
                options={props.names.sports}
                placeholder="Laji"
                value={sport}
                onChange={handleSportChange}
                onCreateOption={handleSportCreate}
                isDisabled ={isPending}
            />
            <CreatableSelect
                isSearchable
                autoFocus
                name="series"
                options={seriesList}
                placeholder="Sarja"
                value={series}
                onChange={handleSeriesChange}
                onCreateOption={handleSeriesCreate}
                isDisabled ={isPending}
            />
            <Select
                options={eventListShow}
                isSearchable
                autoFocus
                placeholder="Etsi tapahtumia"
                value={selectedEvent}
                onChange={handleEventChange}
                isDisabled ={isPending}
            />
            <input
                type="checkbox"
                onChange={(event) => setShowNext(event.currentTarget.checked)}
                checked={showNext}
            />
            <label>Näytä edelliset</label>
            <br/>
            <CreatableSelect
                isSearchable
                autoFocus
                name="home"
                options={teamsList}
                placeholder="Koti"
                value={home}
                onChange={handleHomeChange}
                onCreateOption={handleHomeCreate}
            />
            <CreatableSelect
                isSearchable
                autoFocus
                name="visitor"
                options={teamsList}
                placeholder="Vieras"
                value={visitor}
                onChange={handleVisitorChange}
                onCreateOption={handleVisitorCreate}
            />
            <Datetime
                dateFormat = {C.DATE_FORMAT}
                timeFormat = {C.TIME_FORMAT}
                closeOnSelect = {true}
                viewMode = 'time'
                value={dateTime}
                onChange={handleDateTimeChange}
            />
            <CreatableSelect
                isSearchable
                autoFocus
                name="gameFormat"
                value={gameFormat}
                options={props.names.gameFormats}
                onChange={handleGameFormatChange}
                onCreateOption={handleGameFormatCreate}
            />
            <CreatableSelect
                isSearchable
                autoFocus
                name="selection"
                value={selection}
                options={selectionList}
                onChange={handleSelectionChange}
                onCreateOption={handleSelectionCreate}
            />
            <CreatableSelect
                isSearchable
                autoFocus
                name="booker"
                value={booker}
                options={props.names.bookers}
                onChange={handleBookerChange}
                onCreateOption={handleBookerCreate}
            />
            <BookerSpecialCase/>
            <input
                name="odds"
                type="number"
                step='0.1'
                placeholder="Kerroin"
                value={odds}
                onChange={handleOddsInputChange}
                onClick={handleInputClick}
            />
            <input
                name="closingOdds"
                type="number"
                step='0.1'
                placeholder="Päätöskerroin"
                value={closingOdds}
                onChange={handleClosingOddsInputChange}
                onClick={handleInputClick}
            />
            <input
                name="bet"
                type="number"
                step='10'
                placeholder="Panos"
                value={bet}
                onChange={handleBetInputChange}
                onClick={handleInputClick}
            />
            <input
                name="win"
                type="number"
                placeholder="Voitto"
                value={win}
                onChange={handleWinInputChange}
                onClick={calculateWin}
            />
            <button className="btn btn-primary" onClick={handleClearValues } >Tyhjennä</button>
            <input
                type="checkbox"
                onChange={(event) => setLive(event.currentTarget.checked)}
                checked={live}
            />
            <label>Live</label>
            <input
                type="checkbox"
                onChange={(event) => setHalfTime(event.currentTarget.checked)}
                checked={halfTime}
            />
            <label>Puoliaika</label>
            <input
                type="checkbox"
                onChange={(event) => setWomen(event.currentTarget.checked)}
                checked={women}
            />
            <label>Naiset</label>
            <Select
                isSearchable
                autoFocus
                placeholder="Tulos"
                value={result}
                options={props.names.results}
                onChange={handleResultChange}
            />
            <br/>
            <button className="btn btn-primary" onClick={handleAddBet}>Tallenna</button>
            <Response/>
        </React.Fragment>
    );
};

AddBet.propTypes = {
    names: PropTypes.object.isRequired
};

export default AddBet;