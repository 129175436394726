import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import axios from 'axios';
import moment from 'moment';
import C from "../../constants";
import { convertIdToLabel } from './resultConverter';

const choices = [
    {value: '0', label: C.CURRENT_BETS_WITHOUT_LONG_SIGHT},
    {value: '1', label: C.CURRENT_BETS},
    {value: '2', label: C.LAST_DAY},
    {value: '3', label: C.LAST_WEEK},
    {value: '4', label: C.LAST_MONTH},
    {value: '5', label: C.LAST_YEAR}
];

const BetList = (props) => {
    const sports = [{value: 'Kaikki', label: 'Kaikki'}, ...props.names.sports];
    const [sport, setSport] = useState(sports[0]);
    const [bet, setBet] = useState([]);
    const [betSum, setBetSum] = useState(0);
    const [choice, setChoice] = useState(choices[0]);

    const betList = () => {
        return bet.map(function(currentBet, i){
            return <Bet bet={currentBet} key={i} />;
        })
    };

    useEffect(() => {
        let params = {
            choice: choice.value,
            sport: sport.label
        };
        getResults(params);
    }, [choice.value, sport.label]);

    const handleChoiceChange = (selectedOption) => {
        setChoice(selectedOption);
    };

    const handleSportChange = (selectedOption) => {
        setSport(selectedOption);
    };

    const sleep = (waitTimeInMs) => new Promise(resolve => setTimeout(resolve, waitTimeInMs));

    const getResults = async (params) => {
        if (props.token !== undefined && props.token !== null)
        {
            await sleep(100);
            axios.get(C.BASE_URL, {params, headers: { 'x-access-token': props.token }})
                .then(response => {
                    let betCounter = 0;
                    for (let i = 0; i < response.data.length; i++)
                    {
                        betCounter += response.data[i].accounting_bet;
                    }

                    setBet(response.data);
                    setBetSum(betCounter);
                })
                .catch(function (error){
                    console.log(error);
                });
        }
    };

    const Bet = props => (
        <tr>
            <td><Team home = {true} team = {props.bet.accounting_home} gameFormat = {props.bet.accounting_gameFormat} selection = {props.bet.accounting_selection} /></td>
            <td><Team home = {false} team = {props.bet.accounting_visitor} gameFormat = {props.bet.accounting_gameFormat} selection = {props.bet.accounting_selection} /></td>
            <td>{props.bet.accounting_sport}</td>
            <td>{props.bet.accounting_series}</td>
            <td>{moment(props.bet.accounting_dateTime).format(C.DATE_FORMAT + ' ' + C.TIME_FORMAT)}</td>
            <td>{props.bet.accounting_gameFormat}</td>
            <td>{props.bet.accounting_selection}</td>
            <td>{props.bet.accounting_booker}</td>
            <td>{parseFloat(Math.round(props.bet.accounting_odds * 1000) / 1000).toFixed(3)}</td>
            <td>{parseFloat(Math.round(props.bet.accounting_bet * 100) / 100).toFixed(2)}</td>
            <td>{parseFloat(Math.round(props.bet.accounting_win * 100) / 100).toFixed(2)}</td>
            <td><Result result = {props.bet.accounting_result} /></td>
            <td>
                <Link to={"/edit/"+props.bet._id}>Muokkaa</Link>
            </td>
            <td>
                <Link to={"/delete/"+props.bet._id}>Poista</Link>
            </td>
        </tr>
    );

    const Result = props => {
        /*
                let style={};
                switch(result) {
                    case 0:
                        break;
                    case 1:
                        style={'backgroundColor': 'Lime'};
                        break;
                    case 2:
                        style={'backgroundColor': 'Red'};
                        break;
                    case 3:
                        style={'backgroundColor': 'Green'};
                        break;
                    case 4:
                        style={'backgroundColor': 'DarkRed'};
                        break;
                    case 5:
                        style={'backgroundColor': 'Grey'};
                        break;
                    case 6:
                        style={'backgroundColor': 'Grey'};
                        break;
                    default:
                        break;
                }

                return (
                    <Select
                        style = {style}
                        isSearchable
                        value={result}
                        options={props.names.results}
                        onChange={handleResultChange}
                        />);
        */
                switch(props.result) {
                    case 0:
                        return (<label>{convertIdToLabel(props.result)}</label>);
                    case 1:
                        return (<label style={{'backgroundColor': 'Lime'}}>{convertIdToLabel(props.result)}</label>);
                    case 2:
                        return (<label style={{'backgroundColor': 'Red'}}>{convertIdToLabel(props.result)}</label>);
                    case 3:
                        return (<label style={{'backgroundColor': 'Green '}}>{convertIdToLabel(props.result)}</label>);
                    case 4:
                        return (<label style={{'backgroundColor': 'DarkRed'}}>{convertIdToLabel(props.result)}</label>);
                    case 5:
                        return (<label style={{'backgroundColor': 'Grey'}}>{convertIdToLabel(props.result)}</label>);
                    case 6:
                        return (<label style={{'backgroundColor': 'Grey'}}>{convertIdToLabel(props.result)}</label>);
                    default:
                        return null;
                }
    };

    const Team = (teamInfo)  => {
        if (teamInfo.home && ((teamInfo.gameFormat === C.SIDE || teamInfo.gameFormat === C.H2H) && teamInfo.selection.charAt(0) === '1') || teamInfo.gameFormat === C.OUTRIGHT)
        {
            const style={'fontWeight': 'bold'};
            return (<label style={style}>{teamInfo.team}</label>);
        }

        if (!teamInfo.home && (teamInfo.gameFormat === C.SIDE || teamInfo.gameFormat === C.H2H) && teamInfo.selection.charAt(0) === '2')
        {
            const style={'fontWeight': 'bold'};
            return (<label style={style}>{teamInfo.team}</label>);
        }

        return (<label>{teamInfo.team}</label>);
    };

    const handleResultChange = (selectedOption) => {
        //setResult(selectedOption);
        console.log('selectedOption', selectedOption);
    };

    return (
        <div>
            <h3>Vetolista</h3>
            <label>Yhteensä: {bet.length} vetoa {parseFloat(Math.round(betSum * 100) / 100).toFixed(2)} euroa.</label>
            <Select
                isSearchable
                value={choice}
                options={choices}
                onChange={handleChoiceChange}
            />
            <Select
                isSearchable
                value={sport}
                options={sports}
                onChange={handleSportChange}
            />
            <table className="table table-hover" style={{ marginTop: 20 }} >
                <thead>
                <tr>
                    <th>Koti</th>
                    <th>Vieras</th>
                    <th>Laji</th>
                    <th>Sarja</th>
                    <th>Aika</th>
                    <th>Pelimuoto</th>
                    <th>Valinta</th>
                    <th>Booker</th>
                    <th>Kerroin</th>
                    <th>Panos</th>
                    <th>Voitto</th>
                    <th>Tulos</th>
                </tr>
                </thead>
                <tbody>
                { betList() }
                </tbody>
            </table>
        </div>
    );
};

export default BetList;